import api from 'legacy/util/api';
import router from 'uav-router';
import helpers from 'legacy/util/api/helpers';
import {NUDGE_KEYS} from 'constants/flows/position-layer-constants';
import createLayerFlow from 'flows/create-layer/create-layer-flow';
import lngLatsToBounds from 'util/geo/lnglats-to-bounds';
import maplibregl from 'maplibre-gl';
import element from 'util/dom/element';
import { getNormalizedKey } from 'util/events/get-normalized-key';
import siteModel from 'models/site-model';
import debounce from 'util/events/debounce';
import {valueIsValid, calculateAngle, determineOrientation, radiansToDegrees, lngLatsAreEqual, translatePolygonCoordinates, rotatePolygonCoordinates, scalePolygonCoordinates, getNewLngLatFromPixelChange} from 'flows/create-layer/position-layer/utils/geometry-calculations';
import layerColorModel from 'models/layer-color-model';
import publish from 'legacy/util/api/publish';
import round from 'util/numbers/round';
import store from 'util/data/store';
import featureListManager from 'managers/feature-list-manager';
import latLngsToLngLats from 'util/geo/latlngs-to-lnglats';
import dialogModel from 'models/dialog-model';
import appModel from 'models/app-model';
import message from 'views/toast-message/toast-message';
import MapModel from 'models/map-model';
import layerModel from 'models/layer-model';
import formatDate from 'legacy/util/date/format-date';
import panelModel from 'models/panel-model';
import modalModel from 'models/modal-model';

const LNG_INDEX = 0;
const LAT_INDEX = 1;

const RANGE_BY_LAT_LNG_INDEX = {
    [LNG_INDEX]: 180,
    [LAT_INDEX]: 90
};

const OFFSCREEN_CANVAS_ID = 'offscreen-canvas';
const CENTER_TILESET_PADDING = {top: 20, bottom: 20, left: 380, right: 20};
class PositionLayerFlow {
    constructor() {
        this.translateLayerId = 'translate-polygon-layer';
        this.handleKeyDown = debounce(this._handleKeyDown.bind(this));
        this.handleWindowResize = this._handleWindowResize.bind(this);
        this.handleMapMove = this._handleMapMove.bind(this);
        this.reset();

        this._debouncedSave = debounce(async () => this._saveToApi(), 3000);
    }

    onClose() {
        if (Object.values(this.changeQueue).length) { // They have unsaved changes we'll send in now
            message.show('Please note that changes may not be immediately visible while we generate the updated Map Layer.', 'success');
        } else {
            message.hide();
        }
        this._saveToApi();
        
        const plan = Object.assign({}, this.plan);
        clearTimeout(this.refreshTimeout);
        if (this.centerVertex) {
            this.centerVertex.remove();
        }
        this.cornerVertices.forEach(vertex => vertex.remove());
        this.safeRemoveTranslateSource();
        if (this.isShowingMapLayersOnly) {
            this.toggleMappedContent();
        }

        siteModel.map.safeRemoveSource(OFFSCREEN_CANVAS_ID);
        const shadowElement = document.getElementById('maplibre-shadow');
        if (shadowElement) {
            shadowElement.remove();
        }
        if (this.offScreenCanvas) {
            this.offScreenCanvas.remove();
        }
    
        layerModel.resetAwaitLayerChanges();
        this.removeEventListeners();

        if (this.isNew) {
            layerModel.showPlan(this.plan);
        }
        this.reset();


        if (plan && layerModel.state.planTilesetIds.has(plan.tilesetId)) {
            const tileset = store.tilesets[plan.tilesetId];
            layerModel.showTileset(tileset, undefined, tileset.bounds);
        }
    }

    reset() {
        this.isActive = false;
        this.isInitted = false;
        this.refreshTimeout = undefined;
    
        this.plan = undefined;
        this.mediaId = undefined;
        this.tileset = undefined;
        this.cropTileset = undefined;
        
        this.shadowMap = undefined;
        this.shadowMapCanvas = undefined;
        this.offScreenCanvas = undefined;
        this.offScreenCtx = undefined;
        this.shadowCenter = undefined;

        this.defaultLayerColor = '#ffffff';
        this.layerColor = '#ffffff';
        this.layerOpacity = 1;

        this.coordinatesOriginal = [];

        this.centerPointOriginal = {lng: '', lat: ''};
        this.centerPointSet = {lng: '', lat: ''};

        this.rotationDegreesOriginal = 0;
        this.rotationDegreesSet = 0;

        this.scalePercentageOriginal = 100;
        this.scalePercentageSet = 100;
        this.scalePercentageMultiplier = 1;

        this.centerVertex = undefined;
        this.cornerVertices = [];

        this.state = {
            isOutOfBounds: false,
            hasTranslated: false,
            nudgeIsPaused: false,
            isAwaitingData: false,
            colorTilesetLoading: false,
            cropTilesetLoading: false
        };

        this.isShowingFrame = true;
        this.isShowingMapLayersOnly = false; // false on reset so we can toggle them on at start
    
        this.changeQueue = {};
        this.saveRpc = undefined;
        this.lastSave = undefined;

        this.footerStatusMessage = '';
    }    

    _handleWindowResize() {
        clearTimeout(this.windowResizeTimeout);
        this.windowResizeTimeout = setTimeout(() => {
            if (this.offScreenCanvas && this.shadowMapCanvas) {
                this.offScreenCanvas.width = this.shadowMapCanvas.width;
                this.offScreenCanvas.height = this.shadowMapCanvas.height;
                this.updateShadow({andCopyCanvas: true});
            }
        }, 100);
    }
    
    async start(opts = {}) {
        this.reset();
        window.addEventListener('resize', this.handleWindowResize);
        publish.clearCallbacks('modified', 'tileset');
        publish.clearCallbacks('modified', 'plan');
    
        this.freezeMapInteraction();

        this.isNew = opts.isNew || false;
        this.plan = createLayerFlow.plan;
        this.isActive = true;
        this.toggleMappedContent(true);

        const tilesetId = this.plan.tilesetId;
        this.tileset = await api.get.tileset(tilesetId);
        store.tilesets[tilesetId] = this.tileset;
        if (this.tileset && this.tileset.tilesetId) {
            const sectionId = helpers.list(this.plan && this.plan.sectionIds ? this.plan.sectionIds : [])[0];
            const section = await api.get.planSection(sectionId);
            const [[page]] = await api.rpc.requests([['listPages', {pageId: section.pageId, limit: 1}]]);
            this.mediaId = page.thumbnailImageId;
            this.cropTileset = await api.get.tileset(section.cropBaseTilesetId);
        }
        siteModel.map.centerOnTileset(this.tileset, CENTER_TILESET_PADDING);

        this.initMediaInfo();
        this.initColorInfo();
        
        this.addEventListeners();
        
        await this.createShadowMap();
        
        this.addFrameAndVertices();
        this.initTransformations();
        
        this.transformPolygon({updateVertexPosition: true, isInitializing: true});
        this.awaitTilesetChanges();
        
        this.shadowMap.setZoom(siteModel.map.getZoom(), {animate: false});
        this.shadowMap.setCenter(siteModel.map.getCenter(), {animate: false});
        this.updateShadow({andCopyCanvas: true});
        
        // Resume the map interactions now that we're fully initialized.
        this.unfreezeMapInteraction();
        this.isInitted = true;
        m.redraw();
    }

    transformPolygon(opts = {}) {
        const translateCoordinates = translatePolygonCoordinates(this.coordinatesOriginal, this.centerPointOriginal, this.centerPointSet);
        const translatedAndRotatedCoordinates = rotatePolygonCoordinates(translateCoordinates, this.rotationDegreesSet, this.centerPointSet);
        const translatedRotatedAndScaledCoordinates = scalePolygonCoordinates(translatedAndRotatedCoordinates, this.scalePercentageMultiplier, this.centerPointSet);

        const source = siteModel.map.getSource(this.translateLayerId);
        source._data.geometry.coordinates = translatedRotatedAndScaledCoordinates;
        source.setData(source._data);
        
        this.updateShadow();

        this.cornerVertices.forEach((vertex, index) => {
            vertex.setLngLat(translatedRotatedAndScaledCoordinates[0][index]);
            vertex.originalLngLat = vertex._lngLat;
        });

        if (opts.updateVertexPosition) {
            this.centerVertex.setLngLat(this.centerPointSet);
        }

        if (opts.isInitializing) {
            const bounds = lngLatsToBounds(source._data.geometry.coordinates[0]);
            siteModel.map.fitBounds(bounds, {
                animate: true,
                padding: CENTER_TILESET_PADDING
            });
        } else {
            this.save();
        }

        m.redraw(); // So the most up to date values display in the ui
    }

    async updateShadow(opts = {}) {      
        const canvasSource = siteModel.map.getSource(OFFSCREEN_CANVAS_ID);
        if (!canvasSource) {
            return;
        }
        
        if (opts.andCopyCanvas) {
            await this.copyCanvasTo2d({changeOpacity: true});
        }
        return this.transformShadowCanvas();
    }

    async copyCanvasTo2d(opts = {}) {
        return new Promise(resolve => {
            if (!this.shadowMap) {
                return resolve();
            }
            this.shadowMap.once('render', () => {
                this.offScreenCtx.clearRect(0, 0, this.offScreenCanvas.width, this.offScreenCanvas.height);
                this.offScreenCtx.drawImage(this.shadowMap.getCanvas(), 0, 0);
                resolve();
            });
            if (opts.changeOpacity) {
                siteModel.map.setPaintProperty(OFFSCREEN_CANVAS_ID, 'raster-opacity', 0);
            }
            this.shadowMap.triggerRepaint();
        });
    }

    transformShadowCanvas() {
        const originalBounds = this.shadowMap.getBounds();
        
        const boundsPolygon = siteModel.map.getBoundsPolygon(originalBounds);
        const canvasSource = siteModel.map.getSource(OFFSCREEN_CANVAS_ID);
        const shadowRotation = this.rotationDegreesSet - this.rotationDegreesOriginal;
        const shadowScale = this.scalePercentageSet / this.scalePercentageOriginal;

        const translated = translatePolygonCoordinates(boundsPolygon.coordinates, this.shadowCenter, this.centerPointSet);
        const rotated = rotatePolygonCoordinates(translated, shadowRotation, this.centerPointSet);
        const scaled = scalePolygonCoordinates(rotated, shadowScale, this.centerPointSet);
    
        canvasSource.setCoordinates([
            scaled[0][0],
            scaled[0][1],
            scaled[0][2],
            scaled[0][3]
        ]);
        siteModel.map.setPaintProperty(OFFSCREEN_CANVAS_ID, 'raster-opacity', 1);
        
    }

    handleTranslateDrag() {
        if (!this.state.hasTranslated) {
            message.show(<span>Use the keyboard arrow keys to nudge the Map Layer.</span>, 'info');
            this.state.hasTranslated = true;
        }
        if (!lngLatsAreEqual(this.centerPointSet, this.centerVertex._lngLat)) {
            this.centerPointSet = this.centerVertex._lngLat;
            m.redraw();
            
            this.transformPolygon();
        }
    }

    pauseNudgeKeys() {
        this.state.nudgeIsPaused = true;
    }

    resumeNudgeKeys() {
        this.state.nudgeIsPaused = false;
    }

    handleLngLatInput(coord, lngLatIndex) {
        const lngLat = {lng: this.centerPointSet.lng, lat: this.centerPointSet.lat};
        if (lngLatIndex === LNG_INDEX) {
            lngLat.lng = coord;
        } else if (lngLatIndex === LAT_INDEX) {
            lngLat.lat = coord;
        }

        this.setCenterLngLat(lngLat);
        siteModel.map.setCenter(lngLat, {animate: true});
    }

    handleNudge(direction) {
        let pxDelta = undefined;
        switch (direction) {
        case 'ArrowUp':
            pxDelta = {x: 0, y: -1};
            break;
        case 'ArrowDown':
            pxDelta = {x: 0, y: 1};
            break;
        case 'ArrowLeft':
            pxDelta = {x: -1, y: 0};
            break;
        case 'ArrowRight':
            pxDelta = {x: 1, y: 0};
            break;
        default:
            pxDelta = undefined;
        }
        if (!pxDelta) {
            return;
        }

        this.centerPointSet = getNewLngLatFromPixelChange(this.centerPointSet, pxDelta, siteModel.map);
        this.transformPolygon({updateVertexPosition: true});
    }

    /**
     * Checks the current tileset bounds against the viewport, and sets the
     * out of bounds state and redraws as needed.
     * If out of bounds, we'll display a button so they can recenter the tileset
     */
    checkIfTilesetInBounds() {
        const originalState = positionLayerFlow.state.isOutOfBounds;
        if (siteModel.map.getBounds().contains(positionLayerFlow.centerVertex._lngLat)) {
            positionLayerFlow.state.isOutOfBounds = false;
        } else {
            positionLayerFlow.state.isOutOfBounds = true;
        }
        // Only redraw if there was actually a state change.
        originalState !== positionLayerFlow.state.isOutOfBounds ? m.redraw() : null;
    }

    handleRotateDrag(e) {
        const vertex = e.target;

        const pointA = this.centerVertex._pos;
        const pointB = siteModel.map.project(vertex.originalLngLat);
        const pointC = vertex._pos;
        
        vertex.setLngLat(vertex.originalLngLat); // Temporarily put it back to where it was. We'll update all vertices together in transformPolygon (this will help prevent the vertex from "jumping");
        
        const orientation = determineOrientation(pointA, pointB, pointC);

        let angle;
        try {
            angle = radiansToDegrees(calculateAngle(pointB, pointA, pointC));
        } catch {
            angle = 0;
        }
        if (isNaN(angle)) {
            angle = 0;
        }

        const difference = orientation === 'clock' ? angle : -angle;

        let newRotation = this.rotationDegreesSet + difference;
        if (newRotation > 360) {
            newRotation = 360 - newRotation;
        } else if (newRotation < 0) {
            newRotation = 360 + newRotation;
        }
        
        this.setRotateInput(newRotation);
         
    }

    handleScaleDrag(e) {
        const vertex = e.target;

        const originalDistanceToCenter = this.centerVertex._lngLat.distanceTo(vertex.originalLngLat);
        const newDistanceToCenter = this.centerVertex._lngLat.distanceTo(vertex._lngLat);

        const change = Math.abs(newDistanceToCenter - originalDistanceToCenter);
        const changeAsPercent = change ? change / originalDistanceToCenter : 0;

        let newScaleInput = this.scalePercentageSet;

        if (change && newDistanceToCenter > originalDistanceToCenter) {
            newScaleInput = this.scalePercentageSet + this.scalePercentageSet * changeAsPercent;
        } else if (change && newDistanceToCenter < originalDistanceToCenter) {
            newScaleInput = this.scalePercentageSet - this.scalePercentageSet * changeAsPercent;
        }

        vertex.setLngLat(vertex.originalLngLat); // Temporarily put it back to where it was. We'll update all vertices together in transformPolygon (this will help prevent the vertex from "jumping");
       
        this.setScaleInput(newScaleInput);
    }

    updateVertexRotations(degrees) {
        this.cornerVertices.forEach(vertex => vertex.setRotation(degrees));
    }

    setRotateInput(degrees) {
        try {
            degrees = Number(degrees);
        } catch {
            console.warn('couldn\'t convert to number');
            return;
        }

        const delta = this.rotationDegreesSet - degrees;
        this.rotationDegreesSet = round(degrees, 100);
        this.updateVertexRotations(degrees);

        m.redraw();

        if (delta) {
            this.transformPolygon();
        }
    }

    setScaleInput(percentage) {
        try {
            percentage = Number(percentage);
        } catch {
            console.warn('couldn\'t convert to number');
            return;
        }

        if (percentage < 0.001) {
            this.scalePercentageSet = percentage;
            m.redraw();
            setTimeout(() => {
                if (this.scalePercentageSet === percentage) {
                    this.setScaleInput(0.001);
                }
            }, 1000);
            return;
        }
        
        const delta = this.scalePercentageSet - percentage;
        this.scalePercentageSet = round(percentage, 1000) || 0.001;

        m.redraw();

        if (delta) {
            this.scalePercentageMultiplier = percentage / 100;
            this.transformPolygon();
        }
    }

    setCenterLngLat(lngLat) {
        this.centerPointSet = Object.assign({}, lngLat);
        m.redraw();

        this.transformPolygon({updateVertexPosition: true});
    }

    setColor(hex, opacity) {
        if (hex !== 'None' && hex.length < 5) {
            return;
        }
        this.state.colorTilesetLoading = true;
        layerColorModel.setColor(hex, opacity, this.tileset.tilesetId);
        this.layerColor = hex;
        this.layerOpacity = opacity;
        this.tileset.color = this.tileset.defaultColor = hex;
        this.tileset.opacity = this.tileset.defaultOpacity = opacity;

        if (hex !== 'None') {
            this.defaultLayerColor = hex;
        }
        m.redraw();
    }

    toggleColor() {
        if (this.layerColor !== 'None') {
            this.setColor('None', 1);
        } else {
            this.setColor(this.defaultLayerColor, this.layerOpacity);
        }
    }

    showErrorMessageLngLat(lngLatIndex) {
        const errorKey = lngLatIndex === LNG_INDEX ? 'showLngLatErrorLng' : 'showLngLatErrorLat';
        if (!this[errorKey]) {
            this[errorKey] = true;
            m.redraw();
            setTimeout(() => {
                this[errorKey] = false;
                m.redraw();
            }, 4000);
        } 
    }

    validateLngLatInput(coord, lngLatIndex) {
        const value = Number(coord);
        if (valueIsValid(value, RANGE_BY_LAT_LNG_INDEX[lngLatIndex])) {
            this.handleLngLatInput(value, lngLatIndex);
        } else {
            this.showErrorMessageLngLat(lngLatIndex);
            m.redraw();
        }
    }

    _handleKeyDown(e) {
        if (this.state.nudgeIsPaused) {
            return;
        }
        const key = getNormalizedKey(e.key);
        if (NUDGE_KEYS.includes(key)) {
            e.stopPropagation();
            return this.handleNudge(key);
        }
    }

    toggleMappedContent() {
        if (this.isShowingMapLayersOnly) {
            this.isShowingMapLayersOnly = false;
            featureListManager.showFeatures();
        } else {
            this.isShowingMapLayersOnly = true;
            featureListManager.hideFeatures();
        }
        m.redraw();
    }

    toggleFrame() {
        if (this.isShowingFrame) {
            this.isShowingFrame = false;
            siteModel.map.setPaintProperty(this.translateLayerId, 'line-opacity', 0);
        } else {
            this.isShowingFrame = true;
            siteModel.map.setPaintProperty(this.translateLayerId, 'line-opacity', 1);
        }
        m.redraw();
    }

    enterWarpingFlow(plan = positionLayerFlow.plan) {
        if (appModel.user.permissions.canEditRecord(plan)) {
            const project = store.project;
            const site = project.sites[0];
            panelModel.close();
            router.set({projectId: project.projectId, siteId: site.siteId, view: 'layer', planId: plan.planId});
        }
    }

    handleAddMatchPoint() {
        dialogModel.open({
            headline: 'Warp Map Layer with Match Points? ',
            text: 'Please note that once a Map Layer has been modified with Match Points it cannot be repositioned using Transform controls.',
            cssClass: 'large',
            yesText: 'Continue',
            onYes: () => positionLayerFlow.enterWarpingFlow(),
            yesClass: 'btn btn-pill btn-primary UFtracker-layer-editor-continue-to-warp',
            noText: 'Cancel',
            noClass: 'btn btn-pill btn-secondary UFtracker-layer-editor-cancel-warp'
        });
    }

    _handleMapMove() {  
        // Make sure our editing tileset is still in bounds
        this.checkIfTilesetInBounds();
    }

    updateShadowOnSourceLoad(changeQueueKey, tileset = this.tileset) {
        this.shadowMap.safeRemoveSource(tileset.tilesetId);
        this.shadowMap.setUpEditingLayer(tileset);
        const newBounds = lngLatsToBounds(latLngsToLngLats(tileset.bounds));
        this.shadowMap.fitBounds(newBounds, {animate: false, padding: CENTER_TILESET_PADDING});
  
        this.shadowMap.once('sourcedataloading', () => {
            if (!this.state.isAwaitingData) {
                this.state.isAwaitingData = true;
                this.shadowMap.once('idle', () => {
                    this.state.isAwaitingData = false;
                    const changeSavedAt = this.changeQueue[changeQueueKey] ? this.changeQueue[changeQueueKey].getTime() : undefined;
                    const lastSavedAt = new Date(this.lastSave).getTime();
                    if (changeSavedAt && changeSavedAt === lastSavedAt
                        || this.state.colorTilesetLoading
                        || this.state.cropTilesetLoading) {
                        this.tileset = tileset;
                        if (changeQueueKey) {
                            const transformData = this.getTransformDataFromKey(changeQueueKey);
                            this.rotationDegreesOriginal = transformData.rotation;
                            this.scalePercentageOriginal = round(transformData.scale * 100, 1000);
                            this.shadowCenter = {lng: transformData.centerLon, lat: transformData.centerLat};
                        }
                        this.updateShadow({andCopyCanvas: true});
                        m.redraw();
                    }
                    this.state.colorTilesetLoading = false;
                    this.state.cropTilesetLoading = false;
                    this.deleteFromChangeQueue(changeQueueKey);
                });
            }
        });

    }

    /** ----------------------------
     *  
     *   Autosaving
     * 
     * ----------------------------*/

    async save() {
        const savedAt = this.lastSave = new Date();
        const centerLatLng = this.centerVertex._lngLat;
        const changeQueueData = {
            timestamp: savedAt,
            centerLat: centerLatLng.lat,
            centerLon: centerLatLng.lng,
            scale: this.scalePercentageMultiplier,
            rotation: this.rotationDegreesSet
        };
        this.changeQueue = {};
        this.changeQueue[this.getChangeQueueKey(changeQueueData)] = savedAt;
        try {
            const response = await this.debouncedSave(['stakePlan', {
                planId: this.plan.planId,
                centerLat: changeQueueData.centerLat,
                centerLon: changeQueueData.centerLon,
                scale: changeQueueData.scale,
                rotation: changeQueueData.rotation
            }]); 
            if (response.error) {
                console.error('error', response.error);
            }
        } catch (e) {
            console.error('error', e);
        }
    }

    async _saveToApi() {
        this.isSaving = true;
        m.redraw();
        if (this.saveRpc) {
            const response = await api.rpc.requests([this.saveRpc.rpc]);
            if (this.saveRpc) { // Make sure it still exists at this point, in case user quit early
                this.saveRpc.resolve(response);
            }
        } else {
            this.isSaving = false;
            m.redraw();
        }

        this.saveRpc = undefined;
        m.redraw();
    }

    debouncedSave(rpc) {
        const promise = new Promise(resolve => {
            this.saveRpc = {
                rpc,
                resolve
            };
        });
        this._debouncedSave();
        return promise;
    }

    getChangeQueueKey(changeQueueData) {
        return JSON.stringify({
            centerLat: changeQueueData.centerLat,
            centerLon: changeQueueData.centerLon,
            rotation: changeQueueData.rotation,
            scale: changeQueueData.scale
        });
    }

    getTransformDataFromKey(changeQueueKey) {
        return JSON.parse(changeQueueKey);
    }

    deleteFromChangeQueue(key) {
        delete this.changeQueue[key];
    }

    /** ----------------------------
     *  
     *   One time set up functions
     * 
     * ----------------------------*/

    async initMediaInfo() {
        if (!this.plan.document) {
            const [document] = await api.rpc.request([['listDocuments', {documentId: this.plan.documentId, limit: 1}]]);
            if (!document) {
                this.plan.document = {};
                console.error('document not found', this.plan.documentId);
            } else {
                this.plan.document = document;
            }
        } 

        m.redraw();
    }

    initColorInfo() {
        this.layerColor = this.tileset.color || this.tileset.defaultColor;
        if (this.layerColor !== 'None') {
            this.defaultLayerColor = this.layerColor;
        }
        this.layerOpacity = this.tileset.opacity || this.tileset.defaultOpacity;
    }

    initTransformations() {
        if (this.plan.centerLat !== undefined && this.plan.centerLon !== undefined) {
            this.centerPointSet = {lat: this.plan.centerLat, lng: this.plan.centerLon};
        }
        this.shadowCenter = Object.assign({}, this.centerPointSet);

        if (this.plan.rotation) {
            this.rotationDegreesSet = round(this.plan.rotation, 100);
            this.updateVertexRotations(this.rotationDegreesSet);
        } else if (createLayerFlow.rotationDegreesSet) {
            this.rotationDegreesSet = round(createLayerFlow.rotationDegreesSet, 100);
            this.updateVertexRotations(this.rotationDegreesSet);
        }
        this.rotationDegreesOriginal = this.rotationDegreesSet;

        if (this.plan.scale) {
            this.scalePercentageMultiplier = this.plan.scale;
            this.scalePercentageSet = round(this.plan.scale * 100, 1000);
            this.scalePercentageOriginal = this.scalePercentageSet;
        }
    }


    addShadowMapSource() {
        const shadowMapCanvas = this.shadowMap.getCanvas();
        const mainCanvas = siteModel.map.getCanvas();

        shadowMapCanvas.width = mainCanvas.width;
        shadowMapCanvas.height = mainCanvas.height;
        
        if (siteModel.map.getLayer(OFFSCREEN_CANVAS_ID)) {
            siteModel.map.removeLayer(OFFSCREEN_CANVAS_ID);
        } 
        if (siteModel.map.getSource(OFFSCREEN_CANVAS_ID)) {
            siteModel.map.removeSource(OFFSCREEN_CANVAS_ID);
        }

        const bounds = siteModel.map.getBounds();

        const nw = bounds.getNorthWest();
        const ne = bounds.getNorthEast();
        const se = bounds.getSouthEast();
        const sw = bounds.getSouthWest();
        const coords = [
            [nw.lng, nw.lat],
            [ne.lng, ne.lat],
            [se.lng, se.lat],
            [sw.lng, sw.lat]
        ];

        siteModel.map.addSource(OFFSCREEN_CANVAS_ID, {
            'type': 'canvas',
            canvas: OFFSCREEN_CANVAS_ID,
            animate: true,
            'coordinates': coords
        });

        siteModel.map.addLayer({
            id: OFFSCREEN_CANVAS_ID,
            type: 'raster',
            source: OFFSCREEN_CANVAS_ID,
            paint: {
                'raster-fade-duration': 0
            }
        });

        // Remove original source (only show the shadow while editing)
        siteModel.map.safeRemoveSource(this.tileset.tilesetId);
    }

    addFrameAndVertices() {
        const id = this.translateLayerId;

        const bounds = lngLatsToBounds(latLngsToLngLats(this.cropTileset.bounds));

        const nw = bounds.getNorthWest();
        const ne = bounds.getNorthEast();
        const se = bounds.getSouthEast();
        const sw = bounds.getSouthWest();
        const coords = [[
            [nw.lng, nw.lat],
            [ne.lng, ne.lat],
            [se.lng, se.lat],
            [sw.lng, sw.lat],
            [nw.lng, nw.lat]
        ]];

        this.cornerVertices =  [
            new maplibregl.Marker({
                element: element(['<div class="corner-vertex scale"></div>']),
                anchor: 'center'}).setLngLat([nw.lng, nw.lat]).setDraggable(true).addTo(siteModel.map).on('drag', (e) => this.handleScaleDrag(e)),
            new maplibregl.Marker({
                element: element(['<div class="corner-vertex scale"></div>']),
                anchor: 'center'}).setLngLat([ne.lng, ne.lat]).setDraggable(true).addTo(siteModel.map).on('drag', (e) => this.handleScaleDrag(e)),
            new maplibregl.Marker({
                element: element(['<div class="corner-vertex scale"></div>']),
                anchor: 'center'}).setLngLat([se.lng, se.lat]).setDraggable(true).addTo(siteModel.map).on('drag', (e) => this.handleScaleDrag(e)),
            new maplibregl.Marker({
                element: element(['<div class="corner-vertex rotate"></div>']),
                anchor: 'center'}).setLngLat([sw.lng, sw.lat]).setDraggable(true).addTo(siteModel.map).on('drag', (e) => this.handleRotateDrag(e))
        ];
        
        this.cornerVertices.forEach(vertex => {
            vertex.originalLngLat = vertex._lngLat;
        });
        this.coordinatesOriginal = [...coords];

        siteModel.map.addSource(id, {
            'type': 'geojson',
            'data': {
                'type': 'Feature',
                'geometry': {
                    'type': 'Polygon',
                    'coordinates': coords
                }
            }
        });
    
        siteModel.map.addLayer({
            'id': this.translateLayerId,
            'type': 'line',
            'source': this.translateLayerId,
            'layout': {},
            'paint': {
                'line-color': '#FF5ADB',
                'line-width': 2
            }
        });
    
        this.addTranslateHandleVertex();

        m.redraw();
    }

    addTranslateHandleVertex() {
        const polygon = siteModel.map.getSource(this.translateLayerId)._data;        
        const bounds = lngLatsToBounds(polygon.geometry.coordinates[0]);
        this.centerPointSet = bounds.getCenter();
        this.centerPointOriginal = Object.assign({}, this.centerPointSet);
        this.centerVertex = new maplibregl.Marker({
            element: element(['<div class="translate-vertex"><div class="spinner"></div></div>']),
            anchor: 'center'
        });
        this.centerVertex.setLngLat(this.centerPointSet).setDraggable(true).addTo(siteModel.map);
        this.centerVertex.on('drag', () => this.handleTranslateDrag());
        this.centerVertex.setPopup(new maplibregl.Popup({anchor: 'bottom', closeOnClick: false, offset: {'bottom': [0, 60]}, closeButton: false, className: 'loading-layers-text-popup'}).setHTML('<div>Updating Map Layer</div>'));
    }

    safeRemoveTranslateSource() {
        if (siteModel.map.getLayer(this.translateLayerId + '_fill')) {
            siteModel.map.removeLayer(this.translateLayerId + '_fill');
        }
        siteModel.map.safeRemoveSource(this.translateLayerId);
    }


    addEventListeners() {
        window.addEventListener('keydown', this.handleKeyDown);
    }

    removeEventListeners() {
        siteModel.map.keyboard.enable(); // Reenable now that we're removing our keydown listener
        window.removeEventListener('keydown', this.handleKeyDown);
        window.removeEventListener('resize', this.handleWindowResize);
        clearTimeout(this.handleWindowResizeTimeout);
        delete this.handleWindowResizeTimeout;
        siteModel.map.off('moveend', this.handleMapMove);
    }

    async createShadowMap() {
        const shadowElement = document.createElement('div');
        shadowElement.setAttribute('id', 'maplibre-shadow');
        const app = document.getElementById('app');
        document.body.insertBefore(shadowElement, app);

        this.shadowMap = new MapModel({container: 'maplibre-shadow'}, {
            layercontrolOff: true,
            basemapOff: true,
            geolocateOff: true,
            scaleOff: true,
            hideCompass: true
        });
 
        const offScreenCanvas = this.offScreenCanvas = document.createElement('canvas');
        document.body.insertBefore(offScreenCanvas, app);
        offScreenCanvas.setAttribute('id', OFFSCREEN_CANVAS_ID);

        this.shadowMapCanvas = this.shadowMap.getCanvas();
        offScreenCanvas.width = this.shadowMapCanvas.width;
        offScreenCanvas.height = this.shadowMapCanvas.height;
        this.offScreenCtx = offScreenCanvas.getContext('2d');

        await this.shadowMap.mapLoaded();
    
        this.shadowMap.safeRemoveSource(this.tileset.tilesetId);
        this.shadowMap.setUpEditingLayer(this.tileset);
        const bounds = lngLatsToBounds(latLngsToLngLats(this.tileset.bounds));
        this.shadowMap.fitBounds(bounds, {animate: false, padding: CENTER_TILESET_PADDING});

        this.shadowMap.once('load', async () => {
            this.addShadowMapSource();
            this.updateShadow({andCopyCanvas: true});
        });

        // If the main map moves, copy the zoom & center point to the shadow map.
        siteModel.map.on('moveend', this.handleMapMove);
        m.redraw();
    }

    /**
    * Makes sure no interactions on map occur before we've initialized everything. 
    * Otherwise, the tileset "shadow" will not match up with the polygon.
    */
    freezeMapInteraction() {
        siteModel.map.boxZoom.disable();
        siteModel.map.doubleClickZoom.disable();
        siteModel.map.dragPan.disable();
        siteModel.map.dragRotate.disable();
        siteModel.map.keyboard.disable();
        siteModel.map.scrollZoom.disable();
        siteModel.map.boxZoom.disable();
    }

    /**
     * Unfreeze all interactions EXCEPT the keyboard handler.
     * The keyboard events will be intercepted by us for "nudging"
     */
    unfreezeMapInteraction() {
        siteModel.map.boxZoom.enable();
        siteModel.map.doubleClickZoom.enable();
        siteModel.map.dragPan.enable();
        siteModel.map.dragRotate.enable();
        siteModel.map.scrollZoom.enable();
        siteModel.map.boxZoom.enable();
    }
    

    /** ----------------------------
     *  
     *   Responding to publish notifications
     * 
     * ----------------------------*/

    async awaitCropChanges() {
        this.state.cropTilesetLoading = true;
        const onDone = async () => {
            this.tileset = await api.get.tileset(this.tileset.tilesetId);
            store.tilesets[this.tileset.tilesetId] = this.tileset;
            if (this.tileset && this.tileset.tilesetId) {
                const sectionId = helpers.list(this.plan && this.plan.sectionIds ? this.plan.sectionIds : [])[0];
                const section = await api.get.planSection(sectionId);
                this.cropTileset = await api.get.tileset(section.cropBaseTilesetId);
            }
            siteModel.map.centerOnTileset(this.tileset, CENTER_TILESET_PADDING);
            this.updateShadowOnSourceLoad();
            message.hide();
            modalModel.close();
        };
        return new Promise(resolve => {
            publish.await({
                changeType: 'modified',
                recordType: 'tileset',
                persist: false,
                test: change => (!change.status || change.status === 'complete') && change.planId === this.plan.planId,
                callback: async () => {
                    onDone();
                    resolve();
                }
            });
        });
    }

    planMatchesCurrentSettings(plan) {
        const lat = this.centerPointSet.lat;
        const lng = this.centerPointSet.lng;
        return Math.abs(plan.rotation - this.rotationDegreesSet) < 1
            && plan.centerLat === lat
            && plan.centerLon === lng
            && Math.abs(plan.scale - this.scalePercentageMultiplier) < 1;
    }

    awaitTilesetChanges() {
        publish.await({
            changeType: 'modified',
            recordType: 'tileset',
            persist: true,
            test: tileset => this.tileset && tileset.baseTilesetId === this.tileset.baseTilesetId && this.layerColor === tileset.color,
            callback: tileset => {
                const changeQueueKey = this.getChangeQueueKey(tileset.plan);
                const timestamp = this.changeQueue[changeQueueKey];
                if (this.state.colorTilesetLoading || Object.values(this.changeQueue).length && this.planMatchesCurrentSettings(tileset.plan)) {
                    this.updateShadowOnSourceLoad(changeQueueKey, tileset);
                    this.isSaving = false;
                    if (timestamp) {
                        positionLayerFlow.footerStatusMessage = `Last Saved at ${formatDate.formatAMPM(timestamp)}`;
                    }
                    m.redraw();
                } else {
                    this.deleteFromChangeQueue(changeQueueKey);
                }
            }
        });
    }

    savePlanName(title, resolve) {
        const plan = Object.assign({}, createLayerFlow.plan);
        plan.title = title;
        createLayerFlow.plan = positionLayerFlow.plan = plan;
        store.setContainerValue(store.plans, this.plan.planId, plan);
        m.redraw();
        return api.rpc.requests([['modifyPlan', {
            planId: positionLayerFlow.plan.planId,
            title
        }]]).then(resolve);
    }

    get planName() {
        return createLayerFlow.planName;
    }

    get mediaLabel() {
        return createLayerFlow.mediaLabel;
    }

    get countOfMatchPoints() {
        return positionLayerFlow.plan && positionLayerFlow.plan.corners ? Object.values(positionLayerFlow.plan.corners).length : 0;
    }

    get matchPoints() {
        return positionLayerFlow.plan && positionLayerFlow.plan.corners ? positionLayerFlow.plan.corners.map(corner => ({lng: corner[1], lat: corner[0]})) : [];
    }

    get currentCoords() {
        const source = siteModel.map.getSource(this.translateLayerId);
        return source ? source._data.geometry.coordinates : undefined;
    }

    centerOnMap() {
        const source = siteModel.map.getSource(this.translateLayerId);
        const bounds = lngLatsToBounds(source._data.geometry.coordinates[0]);
        siteModel.map.fitBounds(bounds, {
            animate: true,
            padding: CENTER_TILESET_PADDING
        });
    }

}


const positionLayerFlow = new PositionLayerFlow();

export default positionLayerFlow;

import Point from './point';
import {Marker} from 'maplibre-gl';
import getTextElement from './util/get-text-element';

class Text extends Point {

    constructor(opts) {
        super(opts);

        this.textbox = undefined;
        this.resetTextboxSize = undefined;
    }

    editFeature() {
        const element = document.createElement('div'),
            wrap = getTextElement(this.feature.properties._textField || 'text', this.feature, this.map),
            input = wrap.firstElementChild,
            vertex = document.createElement('i');

        element.classList.add('textbox');
        vertex.classList.add('icon-vertex');

        element.appendChild(wrap);
        wrap.appendChild(vertex);

        input.onkeydown = e => e.stopPropagation();
        input.onmousedown = e => e.stopPropagation();
        input.onclick = e => e.stopPropagation();

        input.oninput = () => {
            this.feature.properties._textField = input.innerText;
            this.render();
            if (this.onVertexChanged) {
                this.onVertexChanged(this.feature);
            }
        };

        this.resetTextboxSize = () => {
            getTextElement.setSizeAndColor(input, this.feature, this.map);
            input.innerText = this.feature.properties._textField;
        };

        this.textbox = new Marker(Object.assign({
            draggable: true,
            element,
            anchor: 'center'
        })).setLngLat(this.feature.geometry.coordinates)
            .addTo(this.map)
            .on('drag', () => {

                const lngLat = this.textbox.getLngLat().toArray();
                this.feature.geometry.coordinates = lngLat;
                this.feature.properties._latitude = lngLat[1] * Math.PI / 180; // to radians

                this.resetTextboxSize();
                this.render();

            }).on('dragend', () => {
                if (this.onVertexChanged) {
                    this.onVertexChanged(this.feature);
                }
            });

        input.focus();
        document.execCommand('selectAll', false, null);

        this.setCursor('');
        this.map.on('zoom', this.resetTextboxSize);
        this.map.on('rotate', this.resetTextboxSize);

        return this;
    }

    reset() {
        if (this.textbox) {
            const div = this.textbox._element.querySelector('.textbox-content');
            if (div) {
                const textField = this.feature.properties._textField;
                if (textField !== div.textContent) {
                    div.textContent = textField;
                    if (this.onVertexChanged) {
                        this.onVertexChanged(this.feature);
                    }
                }
            }
        }

        this.render();

        // We can't reset the size unless the feature has been added to the map.
        if (this.resetTextboxSize) {
            this.resetTextboxSize();
        }

    }

    removeEventListeners() {
        this.map.off('zoom', this.resetTextboxSize);
        this.map.off('rotate', this.resetTextboxSize);

        super.removeEventListeners();
    }

    stop() {
        if (this.textbox) {
            this.textbox.remove();
        }
        super.stop();
    }

}

export default Text;

import randomId from 'util/numbers/random-id';
import featureListManager from 'managers/feature-list-manager';
import {wsUrl} from 'util/data/env';
import constants from 'util/data/constants';
import layerModel from 'models/layer-model';
import authManager from 'managers/auth-manager';
import logManager from 'managers/log-manager';

const PAGE_SIZE = 100;

class FeatureStreamModel {

    constructor(args, opts = {}) {
        this.streamId = randomId();

        this.args = Object.assign({}, args);
        this.args.pageSize = PAGE_SIZE;
        
        this.restrictedByZoom = opts.restrictedByZoom || false;

        this.state = {
            isActive: false,
            isComplete: false
        };
        
        this.expectedCount = 0;
        this.actualCount = 0;
        
        this.onReconnectMessage = this._onReconnectMessage.bind(this);
        this.onOpen = this._onOpen.bind(this);
        this.onClose = this._onClose.bind(this);
        this.onMessage = this._onMessage.bind(this);

        this.connectionMsgId = undefined;
        this.countMsgId = undefined;

        return this;
    }

    get isActive() {
        return this.state.isActive;
    }

    get isComplete() {
        return this.state.isComplete;
    }

    _onOpen() {
        const token = authManager.getToken();
        const msgId = this.connectionMsgId = randomId();

        const data = JSON.stringify({
            type: 'connect',
            msgId,
            token
        });
        this.socket.send(data);
    }

    _onClose(e) {
        if (this.state.isActive) {
            console.error('ws closed', e);
        } else {
            this.socket.removeEventListener('close', this.onClose);
            this.socket = undefined;
        }
    }

    _onReconnectMessage(e) {
        const messageData = JSON.parse(e.data);
        // It's possible for a change notification to
        // arrive before the connection
        if (!messageData.payload || messageData.payload.changes || messageData.payload.tokens || messageData.requestId !== this.connectionMsgId) {
            return;
        }
        this.socket.sessionId = messageData.payload.sessionId;

        this.socket.removeEventListener('message', this.onReconnectMessage);
        this.socket.removeEventListener('open', this.onOpen);
        this.socket.addEventListener('message', this.onMessage);

        this.sendCountRequest();
       
    }

    sendCountRequest() {
        const args = Object.assign({}, this.args);

        delete args.order;
        delete args.pageSize;
        this.countMsgId = randomId();

        const data =  JSON.stringify({
            type: 'request',
            msgId: this.countMsgId,
            sessionId: this.socket.sessionId,
            payload: {
                version: constants.apiVersion,
                platform: 'web',
                rpc: [['countContent', args]]
            }
        });
        this.socket.send(data);
    }

    sendStreamRequest() {
        const args = this.args;
        args.includeLinked = layerModel.state.doShowLinks || undefined;

        const data =  JSON.stringify({
            type: 'request',
            msgId: this.streamId,
            sessionId: this.socket.sessionId,
            payload: {
                version: constants.apiVersion,
                platform: 'web',
                rpc: [['streamContentFeaturesGeoJson', args]]
            }
        });
        this.socket.send(data);
    }

    _onMessage(e) {
        const data = JSON.parse(e.data);
        if (data.payload.type === 'stream' && data.payload.streamId === `${this.streamId}: 1`) {
            featureListManager.addFeatures(data.payload.geojson.features);
            this.actualCount += data.payload.geojson.features.length;
            if (!this.timeFirstTime) {
                const timeEnd = performance.now();
                this.timeFirstTime = timeEnd;
                logManager.trackStreamTimeToFirstFeature(timeEnd - this.timeStart);
            }
        } else if (data.payload.type === 'rpc-results' && data.payload.requestId === this.streamId && data.payload.results.length && !!data.payload.results[0].count) {
            this.markStreamAsComplete();
        } else if (data.payload.type === 'rpc-results' && data.payload.requestId === this.countMsgId) {
            this.expectedCount = data.payload.results[0].count;
            if (this.expectedCount) {
                featureListManager.expectedFeatureCount = this.expectedCount;
                this.sendStreamRequest();
            } else {
                this.markStreamAsComplete();
            }
        }
    }

    start() {
        try {
            this.timeStart = performance.now();
            this.timeFirstTime = undefined;
            const socket = this.socket = new WebSocket(wsUrl);
            socket.addEventListener('message', this.onReconnectMessage);
            socket.addEventListener('open', this.onOpen);
            socket.addEventListener('close', this.onClose);
        } catch (e) {
            return;
        }
    }

    cancelStream() {
        this.state.isActive = false;
        if (this.socket) {
            this.socket.removeEventListener('message', this.onReconnectMessage);
            this.socket.removeEventListener('open', this.onOpen);
            this.socket.close();
        }
    }

    markStreamAsComplete() {
        this.cancelStream();
        this.state.isComplete = true;
        featureListManager.onStreamComplete(this.streamId);
        const timeEnd = performance.now();
        logManager.trackStreamDuration(timeEnd - this.timeStart, this.expectedCount);
    }

}

export default FeatureStreamModel;

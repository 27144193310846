import CropModel from 'models/crop-model';
import CropHeader from './crop-header';

/**
 * Plan cropping view.
 */
const Crop = {

    oncreate: ({attrs: {tileset, hideCropControls}}) => {
        CropModel.init(tileset, hideCropControls);
    },

    onremove: () => {
        CropModel.onRemove();
    },

    view: ({attrs: {hideCropControls}}) => {
        return <>
            <CropHeader hideCropControls={hideCropControls} />
            <div id="crop-view" class={`crop-view ${CropModel.animateClass}${hideCropControls ? 'hide-controls' : ''}`}>
                <div id="maplibre-crop" class="maplibre"></div>
            </div>
        </>;
    }

};

export default Crop;

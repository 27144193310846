import siteModel from 'models/site-model';
import PDFExporter from 'views/pdf-exporter/pdf-exporter';
import exportPDFModel from 'models/export-pdf-model';
import router from 'uav-router';
import PDFExporterContentCard from 'views/pdf-exporter/pdf-exporter-content-card';
import assetListManager from 'managers/asset-list-manager';
import api from 'legacy/util/api/api';

const PDF = {
    oninit: ({state}) => {
        state.isContentPdf = router.params.view === 'pdf' && router.params.type === 'contentCard';
    },
    
    oncreate: ({state}) => {
        siteModel.onFeaturesLoaded(() => PDF.ready(state));
    },

    ready: (state) => {
        if (state.isContentPdf) {
            return api.rpc.requests([['listContent', {contentId: router.params.contentId, limit: 1}]]).then(([[asset]]) => {
                assetListManager.addToStore(asset, true);
                exportPDFModel.isExportingContentCardFor = router.params.contentId;
                exportPDFModel.initPDF();
                state.initialized = true;
                m.redraw();
            });
        } 
        exportPDFModel.initPDF();
        state.initialized = true;
        m.redraw();
        
    },

    view: ({state}) => {
        if (state.isContentPdf) {
            return <div class="content-pdf-wrap">
                <div class="pdf">
                    <div id="maplibre"/>
                    {state.initialized && <PDFExporterContentCard/>}
                    {state.initialized && <PDFExporter/>}
                </div>
            </div>;
        }
        return <div class="pdf">
            <div id="maplibre"/>
            {state.initialized && <PDFExporter/>}
        </div>;
        
    }
};

export default PDF;

import siteModel from 'models/site-model';
import screenHelper from 'legacy/util/device/screen-helper';
import pointInPoly from '@turf/boolean-point-in-polygon';
import { getTextRect } from 'util/geo';
import appModel from 'models/app-model';
import tableModel from 'models/table/table-model';
import featureListManager from 'managers/feature-list-manager';

const radius = screenHelper.ios ? 6 : 3;

const grabFeatureId = (feature) => feature.properties._id;

function queryFeatures({ x, y }, map = siteModel.map, getFeatureId = grabFeatureId) {

    let coordinates,
        point;

    const featureIds = {};

    const searchAreaFeatureId = tableModel.projectView.common.searchArea && tableModel.projectView.common.searchArea.id;

    const features = map.queryRenderedFeatures([[x - radius, y + radius], [x + radius, y - radius]], {
        validate: false
    }).filter(feature => {

        const featureId = getFeatureId(feature);

        feature.id = featureId;

        // queryRenderedFeatures returns the same feature multiple times
        if (featureIds[featureId] || featureId === searchAreaFeatureId) {
            return false;
        }

        featureIds[featureId] = true;

        // Mapbox includes a huge amount of padding around text features when deciding
        // whether they intersect with the queried geometry. This code filters out text
        // features that were returned by queryRenderedFeatures that shouldn't have been.
        if (feature.properties._textSizeMeters && feature.geometry.type === 'Point') {

            const featureStyles = appModel.toolbox.featureTypes[feature.source].featureStyles;
            const isLabel = featureStyles && Object.values(featureStyles).length > 1;

            if (!isLabel) {

                coordinates = coordinates || map.unproject({ x, y }).toArray();

                point = point || {
                    type: 'Feature',
                    geometry: {
                        type: 'Point',
                        coordinates
                    }
                };

                // Uncomment to preview the rectangle
                // const source = map.getSource(Object.values(window.appModel.toolbox.featureTypes).find(f => f.name === 'Shape').featureTypeId);
                // source._data.features.push(getTextRect(feature));
                // source.setData(source._data);

                if (!pointInPoly(point, getTextRect(feature))) {

                    return false;

                }

            }

        }

        return true;

        // Maplibre transforms some of the feature properties,
        // and removes the ID. Let's work around that:
    }).map(feature => featureListManager.getById(feature.properties._id) || feature);

    return features;

}

export default queryFeatures;

import api from 'legacy/util/api';
import router from 'uav-router';
import appModel from 'models/app-model';
import accountManager from 'managers/account-manager';
import initializer from 'util/initializer';
import store from 'util/data/store';
import hasElevationData from 'legacy/util/data/has-elevation-data';
import FeatureMenu from 'views/feature-menu';
import assetListManager from 'managers/asset-list-manager';
import popup from 'util/popup';
import debounce from 'util/events/debounce';
import {latLngsToLngLats, lngLatsToBounds, queryFeatures} from 'util/geo';
import styleModel from 'models/style-model';
import formModel from 'models/form-model';
import AssetForm from 'views/asset-form';
import layerModel from 'models/layer-model';
import loaderModel from 'models/loader-model';
import MapModel from './map-model';
import {mapLongPress} from 'util/dom/maplibre-events';
import featureListManager from 'managers/feature-list-manager';
import Table from 'views/table/table';
import publish from 'legacy/util/api/publish';
import notificationsModel from 'models/notifications-model';
import placeModel from 'models/place-model';
import userflow from 'util/userflow/userflow';
import mapConstants from 'constants/util/map-constants';
import supersetModel from 'models/superset-model';
import tableModel from 'models/table/table-model';
import exportPDFModel from 'models/export-pdf-model';
import batchSelectModel from './batch-select-model';
import maplibregl, {LngLatBounds} from 'maplibre-gl';
import MapPopup from 'views/popups/map-popup';
import App from 'views/app';

const SITE_BOUNDS_ID = mapConstants.SITE_BOUNDS_ID;

initializer.add(() => siteModel.cleanup(), 'siteModel');
initializer.addSiteCallback(() => {
    siteModel.siteMapClick();
    siteModel.setLastVisitedSite();
    layerModel.awaitLayerChanges();
    featureListManager.awaitChanges();
    tableModel.awaitChanges();
});

const siteModel = {

    cleanup() {
        Object.assign(siteModel, {
            name: '',
            hasElevationData: false,
            inspector: null,
            sidebar: Table,
            view: null,
            hoveringMediaId: null,
            hoveringFeatureIds: [],
            bounds: null,
            canBatchModify: false,
            featuresLoadedCallbacks: [],
            mapLoadedCallbacks: [],
            expandAssetMeta: false,
            isMapReady: false,
            timedCallbacks: {},
            dismissedClass: ''
        });

    },

    // If the map isnt ready yet, add to array of callbacks to run when ready. If it is, just run the fn.
    handleLoadedMapImage(id, img) {
        if (siteModel.isMapReady) {
            siteModel.map.safeAddImage(id, img);
        } else {
            siteModel.mapLoadedCallbacks.push(() => siteModel.map.safeAddImage(id, img));
        }
    },

    leftClick(features, lngLat, placeIds = []) {
        if (appModel.isDrawing || features.length === 0 && placeIds.length === 0) {
            popup.remove();
            return Promise.resolve();
        }

        let assetIds = {};

        features.forEach(feature => {
            feature = featureListManager.all[feature.id] || feature;
            if (feature.properties.assetId) {
                assetIds[feature.properties.assetId] = true;
            }

        });

        assetIds = Object.keys(assetIds);

        if (assetIds.length || placeIds.length > 1) {

            MapPopup.add({
                className: 'feature-menu-popup',
                content: FeatureMenu,
                attrs: {assetIds, placeIds, features, lngLat, key: Date.now()},
                lngLat,
                maxWidth: 'none'
            });

        } else if (placeIds.length === 1) {
            placeModel.showPlacePopups(placeIds, lngLat);
        }

        return Promise.resolve();

    },

    rightClick(lngLat) {
        if (popup.isOpen()) {
            popup.remove();
            return;
        }
        if (this.map.searchPopup) {
            this.map.searchPopup.remove(); // Remove left over search popup and circle if it exists
        }
        if (layerModel.state.surveyId
            && store.surveys[layerModel.state.surveyId]
            && store.surveys[layerModel.state.surveyId].hasElevationData) {
            api.rpc.request([['getSurveyElevation', {
                'surveyId': layerModel.state.surveyId,
                'geometry': {
                    'type': 'Point',
                    'coordinates': lngLat.toArray()
                }
            }]]).then((elevationData) => {
                const elevation = elevationData.values ? elevationData.values[0] : undefined;
                popup.add({
                    className: 'feature-menu-popup',
                    content: <FeatureMenu lngLat={lngLat} elevation={elevation} />,
                    lngLat,
                    maxWidth: 'none'
                });
            });
            return;
        }
        popup.add({
            className: 'feature-menu-popup',
            content: <FeatureMenu lngLat={lngLat} />,
            lngLat,
            maxWidth: 'none'
        });
    },

    isInfoPanelOpen() {
        return siteModel.sidebar === AssetForm;
    },

    isTableActive() {
        return siteModel.sidebar === Table;
    },

    isTableActiveAndVisible() {
        return siteModel.isTableActive() && !tableModel.isDismissed();
    },

    _removeLoadingClass: debounce(() => {
        m.redraw();
        setTimeout(() => document.body.classList.remove('loading'), 2000);
    }, 1000),

    removeLoadingClass: () => {
        if (siteModel.map.loaded()) {
            if (siteModel.featuresAreLoaded() && exportPDFModel.isDoneLoading()) {
                return siteModel._removeLoadingClass();
            }             
        } 
        setTimeout(() => siteModel.removeLoadingClass(), 500); 
    },
    
    featuresAreLoaded() {
        if (siteModel.map.loaded()
            && !Object.values(featureListManager.streams).length
            && (appModel.project.isMetaProject || featureListManager.loadedBoundary)
            && featureListManager.expectedFeatureCount !== undefined) {
            siteModel.featuresLoadedCallbacks.forEach(cb => cb());
            siteModel.featuresLoadedCallbacks = [];
            return true;
        }                 
    },

    onFeaturesLoaded(callback) {
        siteModel.featuresLoadedCallbacks.push(callback);
    },

    init: async () => {

        if (siteModel.mapModel) {
            siteModel.mapModel.remove();
        }

        // UE-6504 - In case the user navigates away from the page before view is created,
        // the logic should pause & retry on next render
        try {
            siteModel.mapModel = new MapModel({container: 'maplibre'}, {basemapOff: true});
        } catch {
            m.redraw();
            const promise = new Promise(resolve => {
                App.onNextRender(() => {
                    siteModel.mapModel = new MapModel({container: 'maplibre'}, {basemapOff: true});
                    resolve();
                });
            });
            await promise;
        }

        const map = siteModel.map = siteModel.mapModel;
        siteModel.setSiteBounds();

        mapLongPress(map, ({lngLat}) => siteModel.rightClick(lngLat));
        map.on('contextmenu', ({lngLat}) => siteModel.rightClick(lngLat));
        map.on('rotate', () => {
            map.rotation = map.getBearing();
        });
        map.on('pitch', () => {
            const pitch = map.getPitch();
            const scale = document.getElementsByClassName('maplibregl-ctrl-scale')[0];
            if (pitch && !scale.classList.contains('hiding')) {
                scale.classList.add('hiding');
                scale.setAttribute('title', 'Scale is not accurate if map is angled. Press shift + down to reset angle.');
            } else if (!pitch && scale.classList.contains('hiding')) {
                scale.classList.remove('hiding');
            }
        });

        return new Promise(resolve => {
            if (map.isStyleLoaded()) {
                resolve();
            } else {
                map.once('styledata', () => {
                    resolve();
                });
            }
        }).then(() => {

            appModel.toolbox.geometryFeatures.forEach(featureType => layerModel.createGeoJSONSource(featureType.featureTypeId, featureType.lineMetrics));
            placeModel.addPlaceControls();
            siteModel.isWaitingForIdle = false;
            siteModel.canBatchModify = appModel.user.isAccountAdmin && appModel.toolbox.toolboxFeatures && appModel.toolbox.toolboxFeatures['Batch modify'];
            siteModel.shouldShowDash = appModel.user.isAccountAdmin && appModel.toolbox.toolboxFeatures && appModel.toolbox.toolboxFeatures['Progress Dashboard'];
            map.on('dataloading', () => document.body.classList.add('loading'))
                .on('data', e => e.isSourceLoaded && siteModel.removeLoadingClass())
                .on('move', () => featureListManager.onMove());
            layerModel.initFromURL();
            const assetId = router.params.assetId || router.params.contentId;
            if (assetId) {
                assetListManager.fetch(assetId)
                    .then(asset => {
                        if (asset && asset.isVisible) {
                            formModel.viewAsset(assetId);
                        } else {
                            router.url.remove('assetId', 'contentId');
                        }
                    });
            }

            const planName = appModel.user.getViewAs() || (store.account ? store.account.planName : undefined);
            userflow.identifyUser(appModel.user.userId, planName);
            // Meta project-specific project adjustments
            if (appModel.project.isMetaProject) {
                siteModel.setMetaProjectOverrides();
                userflow.updateView('portfolio');
            } else {
                userflow.updateView('project');
            }

            if (siteModel.shouldShowDash) {
                supersetModel.login();
            }

            siteModel.camera = siteModel.map.getCamera();
            siteModel.awaitChanges();
            siteModel.map.on('moveend', () => siteModel.onceIdle());

            siteModel.isMapReady = true;
            siteModel.mapLoadedCallbacks.forEach(cb => cb());
            siteModel.mapLoadedCallbacks = [];

            // Check for deep link to notification preferences modal
            const manage = router.params.manage;
            if (manage && manage === 'notifications') {
                notificationsModel.open();
            }

            if (router.params.bearing) {// in pdf view when bearing is applied
                siteModel.mapModel.setBearing(router.params.bearing);
            }

            if (router.params.view === 'pdf' && router.params.pitch && parseFloat(router.params.pitch, 10)) {// in pdf view when pitch is applied
                siteModel.mapModel.setPitch(router.params.pitch);
                const scale = document.getElementsByClassName('maplibregl-ctrl-scale')[0];
                scale.classList.add('hidden');
            }

        }).finally(() => {
            loaderModel.hide();
            initializer.siteInitialized();
        });
    },

    setSiteBoundsFromAsset() {
        return api.rpc.request([['listContent', {limit: 1, order: 'updatedDateTime desc', projectId: appModel.project.projectId}]]).then(asset => {
            const result = asset;
            const type = result && result[0] && result[0].geometry ? result[0].geometry.type : undefined;
            switch (type) {
            case 'Point' :
                const latLngs = result[0].geometry.coordinates;
                const latlong = new maplibregl.LngLat(latLngs[0], latLngs[1]);
                const pointCoords = LngLatBounds.fromLngLat(latlong, 50).toArray();
                siteModel.map.safeFitBounds(pointCoords, {
                    animate: false
                });
                siteModel.map.zoomTo(16);
                return;
            case 'Polygon':
            case 'Line':
                const polyCoords = result[0].geometry.coordinates[0];
                const bounds = new maplibregl.LngLatBounds(
                    polyCoords[0],
                    polyCoords[0]
                );
                for (const coord of polyCoords) {
                    bounds.extend(coord);
                }
                siteModel.map.safeFitBounds(bounds, {
                    animate: false,
                    padding: 40
                });
                return;
            case 'LineString':
                const lineCoords = result[0].geometry.coordinates;
                const lineBounds = new maplibregl.LngLatBounds(
                    lineCoords[0],
                    lineCoords[0]
                );
                for (const coord of lineCoords) {
                    lineBounds.extend(coord);
                }
                siteModel.map.safeFitBounds(lineBounds, {
                    animate: false,
                    padding: 40
                });
                return;
            default:
                if (!appModel.project.isMetaProject) {
                    const center = siteModel.bounds.getCenter();
                    const coords = LngLatBounds.fromLngLat(center, 40).toArray();
                    siteModel.map.safeFitBounds(coords, {
                        animate: false
                    });
                    siteModel.map.zoomTo(16);
                }
                return;
            }
        });
    },
    // Overrides to update meta project-specific data after fetch and project init
    setMetaProjectOverrides() {
        siteModel.name = store.account.name;
        const sitePreferences = appModel.user.getPreference('sitePreferences');
        if (!sitePreferences || !sitePreferences[siteModel.siteId]) {
            // Fit to North America if user has no preferences for this project
            siteModel.map.safeFitBounds(mapConstants.BOUNDARIES.NORTH_AMERICA, {
                animate: false
            });
        }
    },

    setElevationData(surveys) {
        const elevationTilesetsExist = !!surveys.find(s => s.hasElevationData);
        siteModel.hasElevationData = feature => {
            if (feature) {
                return Object.values(store.surveys).find(survey => hasElevationData(survey, feature));
            }
            return elevationTilesetsExist;
        };
    },

    awaitChanges() {
        publish.await({
            changeType: 'modified',
            recordType: 'project',
            test: change => {
                return change.projectId === router.params.projectId;
            },
            callback: change => {
                store.setContainerValue(store.project, change.projectId, change);
            },
            persist: true
        });
    },

    changeMapClick(mapClick) {

        formModel.stopEdit();

        siteModel.removeSiteMapClick();

        siteModel.map.on('click', mapClick);

        siteModel.mapClick = mapClick;

    },

    removeSiteMapClick() {

        const mapClick = siteModel.mapClick || siteModel._siteMapClick;

        siteModel.map.off('click', mapClick);

        delete siteModel.mapClick;

    },

    siteMapClick() {
        siteModel.map.on('click', siteModel._siteMapClick);
    },

    _siteMapClick(arg) {

        MapPopup.remove();

        const {point, lngLat} = arg;
        const allFeatures = queryFeatures(point, undefined, (feature) => feature.properties._id
            || feature.properties._placeId);

        const features = [];
        const placeIds = [];

        assetListManager.reset();

        allFeatures.forEach((feature) => {
            const {properties} = feature;
            if (properties._id) {
                features.push(feature);
                assetListManager.addFromFeature(feature);
            } else if (properties._placeId) {
                placeIds.push(properties._placeId);
            }
        });

        if (arg.originalEvent.metaKey || arg.originalEvent.ctrlKey) {
            //currently handling if there is one feature and selecting that otherwise return feature card as we do now to allow them to see all the assets at that point
            if (features.length === 1) {
                if (batchSelectModel.selectedAssets[features[0].properties.assetId]) {
                    batchSelectModel.deselectSingleAsset(features[0].properties.assetId);
                } else {
                    batchSelectModel.selectSingleAsset(features[0].properties.assetId);
                }
                return;
            }
        }

        if (siteModel.sidebar === AssetForm) {
            formModel.mapClick(features, lngLat);
            // If the user is trying to add something to the map (like a vertex for a line), we dont want to open the map popup
            // If they're uploading something via one up, (filepicker interface), we DO want to open the map popup.
        } else if (!appModel.toolbox.toolInterface || appModel.toolbox.toolInterface.type === 'filepicker') {
            siteModel.leftClick(features, lngLat, placeIds);
        }
    },

    /**
     * Captures the current camera data of the site map and saves it to the projectView.
     */
    saveMapCamera: () => {
        siteModel.camera = siteModel.map.getCamera();
        appModel.user.mergePreferences({
            sitePreferences: {
                [siteModel.siteId]: {camera: siteModel.camera}
            }
        });
    },

    /**
     * Returns true if site map changed enough to warrant save.
     */
    mapCameraChanged: () => {
        return !siteModel.map.getBounds().contains(siteModel.camera.center)
            || Math.abs(siteModel.map.getZoom() - siteModel.camera.zoom) > 3;
    },

    /**
     * After the map has moved and is idle, check if we've moved far enough to warrant a save of our map camera, but only save once per moveend+idle (rather than per moveend).
     */
    onceIdle: () => {
        if (!siteModel.isWaitingForIdle) {
            siteModel.isWaitingForIdle = true;
            siteModel.map.once('idle', () => {
                siteModel.isWaitingForIdle = false;
                if (siteModel.mapCameraChanged()) {
                    siteModel.saveMapCamera();
                }
                featureListManager.onceIdle();
            });
        }
    },

    setSiteBounds: () => {
        const bounds = store.project.sites[0].bounds;
        siteModel.bounds = latLngsToLngLats(bounds);
        if (router.params.nw && router.params.se) {
            siteModel.bounds = new LngLatBounds(
                router.params.nw.split(',').map(Number),
                router.params.se.split(',').map(Number)
            );
        } else {
            siteModel.bounds = lngLatsToBounds(siteModel.bounds);
        }
        if (!siteModel.boundary) {
            siteModel.boundary = {
                type: 'Polygon',
                coordinates: [[
                    siteModel.bounds.getNorthWest().toArray(),
                    siteModel.bounds.getNorthEast().toArray(),
                    siteModel.bounds.getSouthEast().toArray(),
                    siteModel.bounds.getSouthWest().toArray(),
                    siteModel.bounds.getNorthWest().toArray()
                ]]
            };
        }
        store.setObject(SITE_BOUNDS_ID, {
            type: 'Feature',
            id: SITE_BOUNDS_ID,
            geometry: siteModel.boundary
        });
        styleModel.stylesheet.center = siteModel.bounds.getCenter().toArray();
        siteModel.center = siteModel.bounds.getCenter();
    },

    setLastVisitedSite: () => {
        appModel.user.setLastVisitedSite();
        // These can get out of sync if site is visited directly via url.
        if (store.account.accountId !== store.project.accountId) {
            accountManager.setAccount(store.accounts[store.project.accountId]);
        }
    },

    // Update the default view for asset meta data or "common properties" (retained just for this session)
    setExpandAssetMeta(isExpanded) {
        siteModel.expandAssetMeta = isExpanded;
        m.redraw();
    },


    // Visually hides the sidebar (keeping all data in state, simply hiding from sight)
    dismissSidebar() {
        this.dismissedClass = ' sidebar-dismissed ';
    },

    // Brings back sidebar from dismissSidebar call
    recallSidebar() {
        this.dismissedClass = '';
    }

};


export default siteModel;

import {Popup} from 'maplibre-gl';
import convertMithril from 'util/dom/convert-mithril';
import siteModel from 'models/site-model';
import screenHelper from 'legacy/util/device/screen-helper';
import MapPopup from 'views/popups/map-popup';

let _popups;

function remove(className) {
    MapPopup.remove();
    if (!_popups) {
        return;
    }
    _popups.forEach((popup) => {
        if (!className || popup.options.className === className) {
            popup.remove();
        }
    });
}

function _pushPopup(opts) {
    const map = opts.map ? opts.map : siteModel.map;
    _popups.push(new Popup({
        closeButton: false,
        className: opts.className,
        maxWidth: opts.maxWidth
    }).setDOMContent(convertMithril.toDom(opts.content))
        .setLngLat(opts.lngLat)
        .addTo(map));
       
    return _popups[_popups.length - 1];
}

function add(opts) {
    remove();
    _popups = [];
    const addedPopup = _pushPopup(opts);

    addedPopup.on('close', () => {
        _popups = null;
    });

    if (screenHelper.small()) {
        siteModel.map.panTo(opts.lngLat);
    }

    return addedPopup;
}

/**
 * Add multiliple popups to the screen in a popup group.
 * This treats all the popups in a group like a single popup.
 */
function addAll(optsList) {
    remove();
    _popups = [];
    let count = optsList.length;
    for (let i = 0; i < optsList.length; i++) {
        const popup = _pushPopup(optsList[i]);
        popup.on('close', () => {
            count--;
            if (count === 0) {
                _popups = null;
            }
        });
    }
}

function isOpen() {
    return _popups;
}


export default {
    add,
    remove,
    isOpen,
    addAll
};

import siteModel from 'models/site-model';
import onBodyClick from 'legacy/util/dom/on-body-click';
import pointMenu from 'legacy/components/point-menu';
import constants from 'util/data/constants';
import router from 'uav-router';
import api from 'legacy/util/api';
import dialogModel from 'models/dialog-model';
import message from 'views/toast-message/toast-message';
import publish from 'legacy/util/api/publish';
import debounce from 'util/events/debounce';
import appModel from 'models/app-model';
import store from 'util/data/store';
import GeometryDetail from 'views/geometry-detail';
import initializer from 'util/initializer';
import stakeableModel from 'models/stakeable-model';
import layerColorModel from 'models/layer-color-model';
import tableModel from 'models/table/table-model';
import helpers from 'legacy/util/api/helpers';
import placesTabModel from 'models/places-tab-model';
import popup from 'util/popup';
import featureListManager from 'managers/feature-list-manager';
import accountManager from 'managers/account-manager';
import RootFolderModel from 'models/folder/root-folder-model';
import FolderItemModel from 'models/folder/folder-item-model';
import graphql, {gqlResponse} from 'util/graphql/graphql';
import {deUrnify, urnify} from 'util/network/urnify';
import { normalizeUtcTimestamp } from 'util/data/helpers';
import { date1IsNewer } from 'util/data/asset-1-is-newer';
import mapConstants from 'constants/util/map-constants';

const SITE_BOUNDS_ID = mapConstants.SITE_BOUNDS_ID;

initializer.add(() => layerModel.init(), 'layerModel');

const _saveState = debounce(() => {
    
    if (placesTabModel.showChildProjectPlansAndPlaces()) {
        // Don't save state if we're currently viewing a child project's plans & places, they aren't relevant to the main project state.
        return;
    }

    const jsonSafeState = {};

    Object.keys(layerModel.state).forEach(key => {

        let stateValue = layerModel.state[key];

        if (stateValue instanceof Set) {

            stateValue = Array.from(stateValue);

        }

        jsonSafeState[key] = stateValue;
    });
    tableModel.projectView.setLayerAttributes(jsonSafeState);
    if (tableModel.projectView.projectViewId) {
        tableModel.projectView.autosave();
    }
    layerModel.safeState = jsonSafeState;

    appModel.user.mergePreferences({
        sitePreferences: {
            [siteModel.siteId]: jsonSafeState
        }
    });

    router.url.mergeReplace({userId: appModel.user.userId});
}, 1000);

class LayerModel {

    init() {
        this.state = {};
        this.safeState = {};

        Object.assign(this.state, {
            basemapId: Object.keys(constants.basemaps)[0],
            surveyId: undefined,
            doShowPlaces: false,
            doShowLinks: false,
            doShowSiteBounds: false,
            planTilesetIds: new Set(),
            placeIds: new Set()
        });

        this.isOpen = false;
        this.awaitingLayers = {}; // These layers should be turned "on" by default when published
        this.expanded = {};
        this.folders = new RootFolderModel();
        this.onFolderInitCallbacks = [];
    }

    get showPlansLayerPicker() {
        return !appModel.project.isMetaProject || placesTabModel.showChildProjectPlansAndPlaces() && layerModel.folders.childPlans.length;
    }

    get showPlansSpinner() {
        return appModel.project.isMetaProject && placesTabModel.plansAreLoading;
    }

    get planZOrder() {
        return this.folders.planZOrder;
    }

    applySavedViewLayers(layerState = {}) {
        const preferences = layerState;
        layerModel.initialized = false;
        if (Object.keys(preferences).length) {
            const defaultMap = accountManager.isFieldTrial ? Object.keys(constants.basemaps)[3] : Object.keys(constants.basemaps)[0];
            if (preferences.basemapId !== layerModel.state.basemapId) {
                layerModel.setBasemap(preferences && preferences.basemapId || defaultMap);
            }

            if (preferences.surveyId) {
                layerModel.setSurvey(preferences.surveyId);
            }
            if (preferences.planTilesetIds.length) {
                preferences.planTilesetIds.forEach(planTilesetId => {
                    const plan = Object.values(store.plans).find(p => p.tilesetId === planTilesetId);
                    if (plan && !layerModel.state.planTilesetIds.has(planTilesetId)) {
                        layerModel.showPlan(plan);
                    }
                });
            }
            if (!preferences.planTilesetIds.length) {
                layerModel.state.planTilesetIds.forEach(planTilesetId => {
                    const plan = Object.values(store.plans).find(p => p.tilesetId === planTilesetId);
                    if (plan) {
                        layerModel.hidePlan(plan);
                    }
                });
            }
            if (preferences.doShowPlaces) {
                if (preferences.placeIds.length) {
                    preferences.placeIds.forEach(placeId => {
                        const place = store.places[placeId];
                        if (!layerModel.state.placeIds.has(placeId)) {
                            if (place) {
                                layerModel.turnPlaceOn(place);
                            } else if (placeId === SITE_BOUNDS_ID) {
                                // Backfill for older sitePreferences that put siteBounds within the places list
                                preferences.placeIds.filter(id => id === SITE_BOUNDS_ID);
                                layerModel.toggleSiteBounds(true);
                            } else {
                                console.error('placeId not found:', placeId);
                            }
                        }
                    });
                    if (!layerModel.state.doShowPlaces) {
                        layerModel.showPlaces();
                    }
                }
            }
            if (preferences.doShowSiteBounds) {
                layerModel.toggleSiteBounds(true);
            }

            if (!preferences.placeIds.length && layerModel.state.placeIds) {
                layerModel.state.placeIds.forEach(placeId => {
                    const place = Object.values(store.places).find(p => p.placeId === placeId);
                    layerModel.turnPlaceOff(place);
                });
            }
        }
        layerModel.initialized = true;
    }

    initFromPreferences(sitePreferences = {}) {
        const _state = sitePreferences[siteModel.siteId];
        const defaultMap = accountManager.isFieldTrial ? Object.keys(constants.basemaps)[3] : Object.keys(constants.basemaps)[0];

        layerModel.setBasemap(_state && _state.basemapId || defaultMap);
        layerModel.addSiteBoundsGeojson();

        if (!_state) {
            layerModel.initialized = true;
            siteModel.setSiteBoundsFromAsset(); // adding check here, on first load _state can be undefined
            return;
        }

        if (router.params.view === 'pdf') {
            siteModel.map.fitBounds(siteModel.bounds, {animate: false});
        } else if (_state.camera) {
            siteModel.map.setCamera(_state.camera);
        } else {
            siteModel.setSiteBoundsFromAsset();
        }

        if (_state.surveyId) {
            layerModel.setSurvey(_state.surveyId);
        }

        if (_state.planTilesetIds) {
            _state.planTilesetIds.forEach(planTilesetId => {
                const plan = Object.values(store.plans).find(p => p.tilesetId === planTilesetId);
                if (!plan) {
                    console.error('planTilesetId not found:', planTilesetId);
                    const index = _state.planTilesetIds.find(id => id === planTilesetId);
                    if (index > -1) {
                        _state.planTilesetIds.splice(index, 1);
                    }
                }
            });
        }

        if (_state.doShowPlaces) {
            if (_state.placeIds) {
                _state.placeIds.forEach(placeId => {
                    const place = store.places[placeId];
                    if (place) {
                        layerModel.togglePlace(place);
                    } else if (placeId === SITE_BOUNDS_ID) { 
                        // Backfill for older sitePreferences that put siteBounds within the places list
                        _state.placeIds.filter(id => id === SITE_BOUNDS_ID);
                        layerModel.toggleSiteBounds(true);
                    } else {
                        console.error('placeId not found:', placeId);
                    }
                });
            }
            layerModel.showPlaces();
        }
        if (_state.doShowLinks) {
            layerModel.toggleLinks();
        }
        if (_state.doShowSiteBounds) {
            layerModel.toggleSiteBounds(true);
        }

        layerModel.initialized = true;

    }

    async initFromURL() {
        layerModel.initialized = false;
        const style = siteModel.map.getStyle();
        // zOrder is from bottom to top
        Object.values(appModel.toolbox.tools)
            .sort((a, b) => a.zOrder - b.zOrder)
            .forEach(tool => tool.featureStyles
                .sort((a, b) => a.zOrder - b.zOrder)
                .forEach(featureStyle =>
                    featureStyle.style && style.layers.push(featureStyle.style)
                )
            );
        siteModel.map.setStyle(style);
        const userId = router.params.userId;
         
        if (userId && userId !== appModel.user.userId) {
            api.rpc.get('User', userId).then(({preferences}) => {
                layerModel.initFromPreferences(preferences.sitePreferences);
            });
        } else {
            layerModel.initFromPreferences(appModel.user.getPreference('sitePreferences'));
        }

        // Handle links generated by the back end containing mapIds
        const surveyVisibleTilesetId = router.params.mapId;
        if (surveyVisibleTilesetId) {
            router.url.removeReplace('mapId');
            const survey = Object.values(store.surveys).find(s => s.visibleTilesetId === surveyVisibleTilesetId);
            if (survey) {
                layerModel.setSurvey(surveyVisibleTilesetId);
            }
        }
    }


    saveState() {
        if (this.initialized) {
            _saveState();
        }
    }

    removeSource(id) {

        if (siteModel.map.getSource(id)) {

            if (siteModel.map.getLayer(id)) {

                siteModel.map.removeLayer(id);

            }

            siteModel.map.removeSource(id);

        }

    }

    closeLayerPanel() {
        if (!layerModel.isOpen) {
            return;
        }

        const el = document.querySelector('.layer-control.maplibregl-ctrl.maplibregl-ctrl-group');
        if (el) {
            el.click();
        }
    }

    createSource(id, source) {

        layerModel.removeSource(id);

        return siteModel.map.addSource(id, source);

    }

    flattenBounds(bounds) {

        const n = bounds[0][0],
            w = bounds[0][1],
            s = bounds[1][0],
            e = bounds[1][1];
        return [w, s, e, n];

    }

    createRasterSource(id, url, opts = {}) {

        const bounds = opts.bounds;

        if (bounds) {
            opts.bounds = this.flattenBounds(bounds);
        } else {
            delete opts.bounds;
        }

        layerModel.createSource(id, Object.assign({
            type: 'raster',
            tiles: [url],
            maxzoom: 24,
            tileSize: 256
        }, opts));

    }

    createGeoJSONSource(id, lineMetrics = false) {

        return layerModel.createSource(id, {
            type: 'geojson',
            lineMetrics,
            data: {
                type: 'FeatureCollection',
                features: []
            }
        });

    }

    showSurveyTileset(survey, tileset) {

        const state = layerModel.state,
            surveyLayerIndex = state.basemapId === '0' ? 0 : 1,
            beforeLayer = siteModel.map.getStyle().layers[surveyLayerIndex];

        const urlTemplate = helpers.URLTemplateFMTToPNG(tileset.urlTemplate);

        layerModel.createRasterSource('survey', urlTemplate, {
            bounds: tileset.bounds
        });

        siteModel.map.addLayer({
            id: 'survey',
            source: 'survey',
            type: 'raster'
        }, beforeLayer && beforeLayer.id);

        state.surveyId = survey.surveyId;

        if (survey.hasElevationData) {

            GeometryDetail.update();

        }

    }

    setSurvey(surveyId) {

        if (siteModel.map.getLayer('survey')) {

            siteModel.map.removeLayer('survey');

        }

        const state = layerModel.state;

        if (surveyId && store.surveys[surveyId]) {

            const survey = store.surveys[surveyId];

            let tileset = store.tilesets[survey.visibleTilesetId];

            if (tileset) {

                this.showSurveyTileset(survey, tileset);

            } else {

                api.rpc.get('Tileset', survey.visibleTilesetId).then(_tileset => {

                    tileset = _tileset;

                    if (_tileset) {

                        store.tilesets[survey.visibleTilesetId] = _tileset;

                        this.showSurveyTileset(survey, _tileset);

                    }

                });

            }

        } else {

            state.surveyId = undefined;

        }

        layerModel.saveState();

    }

    setBasemap(basemapId, shouldSave = true) {

        if (basemapId === '0') {

            layerModel.removeSource('basemap');

        } else {

            const basemap = constants.basemaps[basemapId];

            layerModel.createSource('basemap', basemap);

            if (!siteModel.map.getLayer('basemap')) {

                const beforeLayer = siteModel.map.getStyle().layers[0];

                siteModel.map.addLayer({
                    id: 'basemap',
                    source: 'basemap',
                    type: basemap.type
                }, beforeLayer && beforeLayer.id);

            }

        }

        layerModel.state.basemapId = basemapId;

        if (shouldSave) {

            layerModel.saveState();

        }

    }

    toggleLinks() {

        if (tableModel.loadingTable) {
            return;
        }

        layerModel.state.doShowLinks = !layerModel.state.doShowLinks;

        featureListManager.search(layerModel.state.doShowLinks);

        layerModel.saveState();

    }

    togglePicker() {

        layerModel.isOpen = !layerModel.isOpen;


        if (layerModel.isOpen) {

            onBodyClick.once(() => {

                if (!dialogModel.isOpen) {

                    layerModel.isOpen = false;

                    layerModel.layerControl._container.classList.remove('active');

                    m.redraw();
                } else {

                    // Dialog was open, so reset the body click toggle event:
                    onBodyClick.once(() => {
                        layerModel.isOpen = false;
                        layerModel.togglePicker();
                    });

                }

            });

        }

        m.redraw();

    }

    showTileset(tileset, beforeLayer, bounds) {

        const tilesetId = tileset.tilesetId;
        const opacityFromTileset = tileset.defaultOpacity || tileset.opacity;

        const urlTemplate = helpers.URLTemplateFMTToPNG(tileset.urlTemplate);

        layerModel.createRasterSource(tilesetId, urlTemplate, {
            maxzoom: 24,
            bounds
        });

        if (!beforeLayer) {

            const firstNonRasterLayer = siteModel.map.getStyle().layers.find(layer => layer.type !== 'raster');

            if (firstNonRasterLayer) {

                beforeLayer = firstNonRasterLayer.id;

            }

        }

        const opacity = opacityFromTileset === undefined ? 1 : opacityFromTileset;
        siteModel.map.addLayer({
            id: tilesetId,
            source: tilesetId,
            type: 'raster',
            paint: {
                'raster-opacity': opacity
            }
        }, beforeLayer);

    }

    getPlanIcon(plan) {
        if (plan.status === 'complete') {
            return layerModel.state.planTilesetIds.has(plan.tilesetId) ? 'toggle-switch toggle-is-on' : 'toggle-switch toggle-is-off';
        }
        return '';
    }

    addPlanToParentFolder(plan) {
        const urn = urnify('plan', plan.planId);
        // If it's not there yet, add it to the designated parent, or if none, the root:
        const parentId = plan.folderId || layerModel.folders.root.id;
        const parentUrn = urnify('folder', parentId);
        const parent = layerModel.folders.getByUrn(parentUrn);
        if (!parent) {
            return;
        }
        const planFolderItem = new FolderItemModel({id: plan.planId, parentId, name: plan.title}, layerModel.folders.root, 'plan');
        parent.add(urn, planFolderItem);
        if (layerModel.state.planTilesetIds.has(plan.tilesetId)) {
            planFolderItem.turnVisibilityOnFromChild(true);
            layerModel.showPlan(plan);
        }
        return planFolderItem;
    }

    updatePlanFolderItem(plan) {
        const urn = urnify('plan', plan.planId);
        const planFolderItem = layerModel.folders.getByUrn(urn);
        if (planFolderItem) {
            const originalParent = planFolderItem.parent;
            const originalState = Object.assign({}, planFolderItem.state);
            const newItem = new FolderItemModel({id: plan.planId, parentId: plan.folderId, name: plan.title}, layerModel.folders.root, 'plan');
            const parentChanged = originalParent && originalParent.id !== newItem.parentId;
            originalParent.remove(newItem.urn, parentChanged);
            newItem.parent.add(newItem.urn, newItem);
            newItem.state = originalState;
            m.redraw();
        }
        return planFolderItem;
    }

    addOrUpdatePlanInFolders(plan) {
        const urn = urnify('plan', plan.planId);
        // Check if plan already exists in folders as an item:
        let planFolderItem = layerModel.folders.getByUrn(urn);
        if (!planFolderItem) {
            planFolderItem = layerModel.addPlanToParentFolder(plan);
        } else {
            const planUpdatedDateTime = normalizeUtcTimestamp(plan.updatedDateTime);
            planFolderItem.updatedDateTime = normalizeUtcTimestamp(planFolderItem.updatedDateTime);
            const date1 = new Date(planUpdatedDateTime);
            const date2 = new Date(planFolderItem.updatedDateTime);

            if (date1IsNewer(date1, date2)) {
                layerModel.updatePlanFolderItem(plan);
            }
        }
        m.redraw();
        return planFolderItem;
    }

    showPlan(plan) {
        // If it's not visible yet
        const state = layerModel.state;
        if (!plan || !plan.tilesetId) {
            return;
        }
        if (!state.planTilesetIds.has(plan.tilesetId)) {
            // Find where plan is in our folder structure:
            const planFolderItem = layerModel.addOrUpdatePlanInFolders(plan);
            if (planFolderItem) {
                let beforeLayer;
                // Make sure item and parent folder in layer menu displays as toggled on 
                planFolderItem.state.isToggledOn = true;
                planFolderItem.turnVisibilityOnFromChild(true);
            
                const nextVisiblePlanLayer = planFolderItem ? planFolderItem.getNextVisiblePlan() : undefined;
                if (nextVisiblePlanLayer) {
                    beforeLayer = nextVisiblePlanLayer && nextVisiblePlanLayer.tilesetId;
                }

                const tileset = store.tilesets[plan.tilesetId];
                if (!tileset) { // This is the case when the plan has not been processed yet
                    return;
                }

                layerModel.showTileset(tileset, beforeLayer, tileset.bounds);
                state.planTilesetIds.add(plan.tilesetId);
                layerModel.saveState();
            }
        }

    }

    hidePlan(plan) {
        if (!plan || !plan.tilesetId) {
            return;
        }
        const state = layerModel.state;

        if (state.planTilesetIds.has(plan.tilesetId)) {

            if (siteModel.map.getLayer(plan.tilesetId)) {
                siteModel.map.removeLayer(plan.tilesetId);
            }

            state.planTilesetIds.delete(plan.tilesetId);

            layerModel.saveState();

        }

    }

    togglePlan(plan, e) {
        if (e) {
            e.stopPropagation();
        }

        if (plan.status === 'complete') {

            const doShow = !layerModel.state.planTilesetIds.has(plan.tilesetId);

            const layer = siteModel.map.getLayer(plan.tilesetId);

            if (doShow && !layer) {

                layerModel.showPlan(plan);

            } else if (!doShow && layer) {

                layerModel.hidePlan(plan);

            }

        }

    }

    isSavingPlan(planId) {
        return layerModel.savingPlanId === planId;
    }

    hideAllLayers() {

        const state = layerModel.state,
            plans = Object.values(store.plans);

        state.doShowPlans = false;

        plans.forEach(layerModel.hidePlan);

        if (state.doShowLinks) {
            layerModel.toggleLinks();
        }

        layerModel.hidePlaces();

        layerModel.setSurvey();

    }

    turnAllPlacesOn() {
        const source = siteModel.map.getSource('places'),
            features = source._data.features,
            state = layerModel.state;

        Object.values(store.places).forEach((place) => {

            if (!state.placeIds.has(place.placeId)) {
                features.push(featureListManager.getPlaceFeature(place));
                state.placeIds.add(place.placeId);
            }
        });

        source.setData(source._data);
        layerModel.showPlaces();
    }

    turnOnPlace(place) {
        if (!layerModel.state.placeIds.has(place.placeId)) {
            layerModel.togglePlace(place);
        }
    }
    
    turnPlaceOn(place) {
        const source = siteModel.map.getSource('places'),
            features = source._data.features,
            placeId = place.placeId,
            state = layerModel.state;
        
        if (!state.placeIds.has(placeId)) {
            features.push(featureListManager.getPlaceFeature(place));
            state.placeIds.add(placeId);
        }
        source.setData(source._data);
        layerModel.saveState();
    }
    
    turnPlaceOff(place) {
        const source = siteModel.map.getSource('places'),
            features = source._data.features,
            placeId = place.placeId,
            state = layerModel.state;
        if (state.placeIds.has(placeId)) {
            const index = features.findIndex(feature => feature.id === placeId);
            features.splice(index, 1);
            state.placeIds.delete(placeId);
            popup.remove();
        }
        if (!state.placeIds.length) {
            layerModel.state.doShowPlaces = false;
        }
        source.setData(source._data);

        layerModel.saveState();
    }
    
    addSiteBoundsGeojson() {
        if (!siteModel.map.getSource(SITE_BOUNDS_ID)) {
            siteModel.map.createSource(SITE_BOUNDS_ID, {
                type: 'geojson',
                lineMetrics: false,
                data: {
                    type: 'FeatureCollection',
                    features: [store.siteBounds]
                }
            });
        }
    }

    showSiteBounds() {
        if (!siteModel.map.getLayer(SITE_BOUNDS_ID)) {
            const beforeLayer = siteModel.map.getStyle().layers.find(layer => layer.type !== 'raster');
            siteModel.map.addLayer({
                'id': SITE_BOUNDS_ID,
                'source': SITE_BOUNDS_ID,
                'type': 'line',
                'paint': {
                    'line-color': '#f2bf66',
                    'line-width': 3,
                    'line-dasharray': [
                        4,
                        1
                    ]
                }
            }, beforeLayer && beforeLayer.id);
        }
    }

    hideSiteBounds() {
        if (siteModel.map.getLayer(SITE_BOUNDS_ID)) {
            siteModel.map.removeLayer(SITE_BOUNDS_ID);
        }
    }

    toggleSiteBounds(toOn) {
        if (toOn) {
            layerModel.state.doShowSiteBounds = true;
        } else {
            layerModel.state.doShowSiteBounds = !layerModel.state.doShowSiteBounds;
        }

        if (layerModel.state.doShowSiteBounds) {
            layerModel.showSiteBounds();
        } else {
            layerModel.hideSiteBounds();
        }

        layerModel.saveState();
    }
    
    isSiteBoundsToggledOn() {
        return layerModel.state.doShowSiteBounds;
    }

    togglePlace(place) {
        const source = siteModel.map.getSource('places'),
            features = source._data.features,
            placeId = place.placeId,
            state = layerModel.state;
        if (state.placeIds.has(placeId)) {

            const index = features.findIndex(feature => feature.id === placeId);

            features.splice(index, 1);

            state.placeIds.delete(placeId);

            popup.remove();

        } else {

            features.push(featureListManager.getPlaceFeature(place));

            state.placeIds.add(placeId);

            if (!state.doShowPlaces) {

                layerModel.showPlaces();

            }

        }

        source.setData(source._data);

        layerModel.saveState();

    }

    showPlaces() {

        layerModel.state.doShowPlaces = true;

        if (!siteModel.map.getLayer('places')) {

            const beforeLayer = siteModel.map.getStyle().layers.find(layer => layer.type !== 'raster');

            siteModel.map.addLayer({
                'id': 'places',
                'source': 'places',
                type: 'fill',
                paint: {
                    'fill-color': '#70bfbf',
                    'fill-opacity': 0
                }
            }, beforeLayer && beforeLayer.id);

            siteModel.map.addLayer({
                'id': 'places-border',
                'source': 'places',
                'type': 'line',
                'paint': {
                    'line-color': '#70bfbf',
                    'line-width': 3,
                    'line-dasharray': [
                        2,
                        1
                    ]
                }
            }, 'places');

        }

    }

    hidePlaces() {

        layerModel.state.doShowPlaces = false;

        if (siteModel.map.getLayer('places')) {

            siteModel.map.removeLayer('places');

            siteModel.map.removeLayer('places-border');

        }

    }

    togglePlaces() {

        const source = siteModel.map.getSource('places'),
            data = source._data,
            state = layerModel.state;

        state.placeIds.clear();

        if (state.doShowPlaces) {

            data.features = [];

            layerModel.hidePlaces();

            popup.remove();

        } else {

            data.features = Object.values(store.places).map(place => {

                state.placeIds.add(place.placeId);

                return featureListManager.getPlaceFeature(place);

            });

            layerModel.showPlaces();

        }

        source.setData(data);

        layerModel.saveState();

    }

    expandContract(e, key) {

        if (e) {
            e.stopPropagation();
        }
        layerModel.expanded[key] = !layerModel.expanded[key];

    }

    deleteSurveyDialog(survey) {

        pointMenu.close();

        dialogModel.open({
            headline: 'Delete this survey?',
            text: 'Please note that this operation cannot be undone.',
            yesClass: 'btn btn-pill btn-red',
            noText: 'Cancel',
            noClass: 'btn btn-pill btn-secondary',
            onYes: () => {

                layerModel.state.surveyId = null;

                api.rpc.requests([
                    ['deleteSurvey', {surveyId: survey.surveyId}],
                    ['deleteTileset', {tilesetId: survey.baseTilesetId}],
                    ['deleteTileset', {tilesetId: survey.elevationTilesetId}],
                    ['deleteTileset', {tilesetId: survey.visibleTilesetId}]
                ]);

                message.show('Survey deleted.');

            }
        });

    } 

    onUpdatedPlansOrder(planUrns) {
        planUrns.forEach(urn => {
            const {nss} = deUrnify(urn);
            layerModel.onUpdatedPlanOrder(nss);
            
        });
    }

    onUpdatedPlanOrder(planId) {
        const plan = store.plans[planId];

        if (plan && layerModel.state.planTilesetIds.has(plan.tilesetId)) {
            layerModel.hidePlan(plan);
            layerModel.showPlan(plan);
        }

    }

    resetAwaitLayerChanges() {
        publish.clearCallbacks('new', 'survey');
        publish.clearCallbacks('modified', 'survey');
        publish.clearCallbacks('deleted', 'survey');
        publish.clearCallbacks('new', 'plan');
        publish.clearCallbacks('modified', 'plan');
        publish.clearCallbacks('deleted', 'plan');
        publish.clearCallbacks('new', 'tileset');
        publish.clearCallbacks('modified', 'tileset');
        layerModel.awaitLayerChanges();
    }

    awaitLayerChanges() {

        publish.await({
            changeType: 'new',
            recordType: 'survey',
            callback: survey => {

                store.surveys[survey.surveyId] = survey;

                m.redraw();

            },
            persist: true
        });

        publish.await({
            changeType: 'modified',
            recordType: 'survey',
            callback: survey => {

                store.surveys[survey.surveyId] = survey;

                const surveyId = survey.surveyId;

                if (siteModel.processingSurvey === surveyId) {

                    // Check that we're 1) On survey view and 2) on the right survey
                    if (router.params.view === 'survey' && router.params.survey === surveyId) {
                        stakeableModel.handleUpdatedAsset();
                    }

                    siteModel.processingSurvey = null;
                }

                layerModel.removeSource(survey.visibleTilesetId);

                requestAnimationFrame(() => {

                    if (layerModel.state.surveyId === surveyId) {

                        layerModel.setSurvey(surveyId);

                    }

                });

                m.redraw();


            },
            persist: true
        });

        publish.await({
            changeType: 'deleted',
            recordType: 'survey',
            test: survey => store.surveys[survey.surveyId],
            callback: survey => {

                delete store.surveys[survey.surveyId];

                m.redraw();

                if (layerModel.state.surveyId === survey.surveyId) {

                    layerModel.setSurvey();

                }

            },
            persist: true
        });

        publish.await({
            changeType: 'new',
            recordType: 'layerFolder',
            test: (folder) => folder.projectId === appModel.project.projectId,
            callback: folder => {
                if (layerModel.folders) {
                    const urn = urnify('folder', folder.folderId);
                    layerModel.folders.handleModifyFolderItemFromApi(urn, folder);
                    if (layerModel.awaitingLayers[folder.folderId]) {
                        const item = layerModel.folders.getByUrn(urnify('folder', folder.folderId));
                        item.turnVisibilityOnFromChild(true);
                        delete layerModel.awaitingLayers[folder.folderId];
                    }
                }
            },
            persist: true
        });

        publish.await({
            changeType: 'modified',
            recordType: 'layerFolder',
            test: (folder) => folder.projectId === appModel.project.projectId,
            callback: folder => {
                if (layerModel.folders) {
                    const urn = urnify('folder', folder.folderId);
                    layerModel.folders.handleModifyFolderItemFromApi(urn, folder);
                    if (layerModel.awaitingLayers[folder.folderId]) {
                        const item = layerModel.folders.getByUrn(urnify('folder', folder.folderId));
                        item.turnVisibilityOnFromChild(true);
                        delete layerModel.awaitingLayers[folder.folderId];
                    }
                }
            },
            persist: true
        });

        publish.await({
            changeType: 'deleted',
            recordType: 'layerFolder',
            callback: folder => {
                if (layerModel.folders) {
                    layerModel.folders.handleDeleteFolderFromApi(folder);
                }
            },
            persist: true
        });

        publish.await({
            changeType: 'new',
            recordType: 'plan',
            callback: plan => {
                store.plans[plan.planId] = plan;
                layerModel.onFolderInitCallbacks(() => layerModel.addOrUpdatePlanInFolders(plan));
            },
            persist: true
        });

        publish.await({
            changeType: 'modified',
            recordType: 'plan',
            test: plan => plan.status === 'complete',
            callback: plan => {
                store.plans[plan.planId] = plan;
                layerModel.onFolderInit(() => layerModel.addOrUpdatePlanInFolders(plan));
            },
            persist: true
        });

        publish.await({
            changeType: 'deleted',
            recordType: 'plan',
            callback: plan => {

                delete store.plans[plan.planId];

                delete store.tilesets[plan.tilesetId];

                const urn = urnify('plan', plan.planId);
                const planInFolders = layerModel.folders.getByUrn(urn);
                if (planInFolders) {
                    planInFolders.parent.remove(urn);
                }

                m.redraw();

                layerModel.removeSource(plan.tilesetId);

            },
            persist: true
        });

        publish.await({
            changeType: 'new',
            recordType: 'tileset',
            callback: tileset => {
                store.tilesets[tileset.tilesetId] = tileset;
                if (tileset.planId && layerModel.awaitingLayers[tileset.planId]) {
                    const plan = store.plans[tileset.planId];
                    const item = layerModel.folders.getByUrn(urnify('plan', plan.planId));
                    if (item) {
                        item.turnVisibilityOnFromChild(true);
                    }
                    layerModel.showPlan(plan);
                }
                delete layerModel.awaitingLayers[tileset.planId];

                m.redraw();

            },
            persist: true
        });

        publish.await({
            changeType: 'modified',
            recordType: 'tileset',
            callback: tileset => {
                const tilesetId = tileset.tilesetId;
                store.tilesets[tilesetId] = tileset;

                if (tileset.planId && layerModel.awaitingLayers[tileset.planId]) {
                    const plan = store.plans[tileset.planId];
                    const item = layerModel.folders.getByUrn(urnify('plan', plan.planId));
                    if (item) {
                        item.turnVisibilityOnFromChild(true);
                    }
                    layerModel.showPlan(plan);
                }

                if (layerModel.state.planTilesetIds.has(tilesetId)) {
                    if (tileset.planId) {
                        layerModel.hidePlan(store.plans[tileset.planId]);
                        layerModel.showPlan(store.plans[tileset.planId]);
                    } else {
                        layerModel.showTileset(tileset);
                    }

                }

                // Check that we're 1) On layer view and 2) on the right plan
                if (router.params.view === 'layer' && router.params.step === 'review' && router.params.planId === tileset.planId) {
                    layerColorModel.handleUpdatedLayer();
                }

                m.redraw();

            },
            persist: true
        });

    }

    addFilter(layer, filter) {

        if (layer.filter) {

            if (layer.filter[0] === 'all') {

                layer.filter.push(filter);

            } else {

                layer.filter = ['all', layer.filter, filter];

            }

        } else {

            layer.filter = filter;

        }

    }

    focusOnAssetFeatures(assetIds = []) {

        const map = siteModel.map,
            style = map.getStyle();

        style.layers.forEach(layer => {

            if (layer.type === 'raster' || layer.id === placesTabModel.placeLayer) {

                return;

            }

            layerModel.addFilter(layer, ['!in', 'assetId', ...assetIds]);

            layer.paint = layer.paint || {};

            layer.layout = layer.layout || {};

        });

        Object.keys(appModel.toolbox.tools).forEach(toolId => {

            const tool = appModel.toolbox.tools[toolId];

            if (tool.featureStyles) {

                tool.featureStyles.forEach(featureStyle => {

                    if (featureStyle.style) {

                        const layer = Object.create(featureStyle.style);

                        layerModel.addFilter(layer, ['in', 'assetId', ...assetIds]);

                        layer.id = '_tmp_' + layer.id;

                        const index = style.layers.findIndex((l) => l.id === layer.id);

                        if (index !== -1) {
                            style.layers.splice(index, 1);
                        }

                        style.layers.push(layer);

                    }

                });

            }

        });

        map.setStyle(style);

    }

    resetToolLayers() {

        const map = siteModel.map;

        if (map && map.style && map.isStyleLoaded()) {

            const style = map.getStyle(),
                layers = [],
                specialLayerStyles = {
                    places: 'line-opacity',
                    searchArea: 'line-opacity',
                    'places-border': 'line-opacity'
                };

            if (style) {

                style.layers.forEach(layer => {

                    if (!layer.id.startsWith('_tmp_')) {

                        if (specialLayerStyles[layer.id]) {

                            delete layer.paint[specialLayerStyles[layer.id]];

                            layers.push(layer);

                        } else {

                            const featureStyle = appModel.toolbox.featureStyles[layer.id];

                            layers.push(featureStyle ? featureStyle.style : layer);

                        }

                    }

                });

                style.layers = layers;

                map.setStyle(style);

            }

        }

    }

    onFolderInit(callback) {
        if (this.folders && this.folders.state.subFoldersLoaded) {
            return callback();
        }
        this.onFolderInitCallbacks.push(callback);
    }

    async initFolders() {
        const project = await appModel.project.getEditingProjectData();
        try {
            const {projects} = gqlResponse(await graphql.getRootFolder(project.projectId));
            const layerFolders = projects[0].layerFolders;
            if (layerFolders && layerFolders.length) {
                this.folders = new RootFolderModel(layerFolders[0]);
                await this.folders.fetchFolders(true);                
            } else {
                this.folders.state.subFoldersLoaded = true;
                m.redraw();
            }
        } catch {
            m.redraw();
        }

        this.addPlansToFolders();
        this.onFolderInitCallbacks.forEach(callback => callback());
    }

    addPlansToFolders() {
        const plans = Object.values(store.plans);
        plans.forEach(plan => this.addOrUpdatePlanInFolders(plan));
        if (plans.length) {
            this.folders.root.state.isExpanded = true;
        }
    }

    initFoldersForMetaProject() {
        this.folders = new RootFolderModel();
        this.folders.state.subFoldersLoaded = true;
        m.redraw();
    }

    createFolder() {
        // Adding folder to the root, make sure top level is expanded
        if (!layerModel.folders.state.isExpanded) {
            layerModel.folders.toggleState('isExpanded');
        }
        this.folders.createSubfolder();

        m.redraw();
    }

}

const layerModel = new LayerModel();

export default layerModel;

import StakeTarget from './stake-target';
import Stake from './stake';
import stakeableModel from 'models/stakeable-model';
import {LngLat} from 'maplibre-gl';

const LAT_INDEX_API = 0;   // Unearth's API stores: lat, lng
const LNG_INDEX_API = 1;   //        Maplibre stores: lng, lat
const ELEV_INDEX = 2;

/**
 * Class representing a pair of stakes (one for the left map, one for the right map),
 * with actions and states that are bound together.
 */
class StakePair {

    /**
     * Generates a new stake pair, each with a reference to this parent object.
     */
    constructor(data, position) {
        this.position = null; // Will assign initially in renderPair method.

        this.targetStake = new StakeTarget({
            coords: new LngLat(data.target[LNG_INDEX_API], data.target[LAT_INDEX_API]),
            elevation: data.target[ELEV_INDEX] ? data.target[ELEV_INDEX] : null
        });
        this.originStake = new Stake({
            coords: new LngLat(data.origin[LNG_INDEX_API], data.origin[LAT_INDEX_API])
        });

        this.addEventHandlers();
        this.renderPair(position);
    }

    /**
     * Given an index position, checks if the position is different than what's currently assigned, and
     * if so, assigns it to that position and re-renders the stakes (with the new position number).
     */
    renderPair(position) {
        if (this.position !== position) {
            this.position = position;
            this.targetStake.render(this);
            this.originStake.render(this);
        }
    }

    /**
     * Adds appropriate event handlers to both the 'target' (left map) and 'origin' (right map) stakes.
     */
    addEventHandlers() {
        this.targetStake.getElement().addEventListener('click', (e)  => this.pickupStake(e, this.targetStake));
        this.originStake.getElement().addEventListener('click', (e) => this.pickupStake(e, this.originStake));
        this.targetStake.on('dragstart', () => this.startMagnify(this.targetStake));
        this.originStake.on('dragstart', () => this.startMagnify(this.originStake));
        this.targetStake.on('drag', () => {
            if (stakeableModel.state.isTranslating) {
                const delta = {
                    lng: this.targetStake.originCoords.lng - this.targetStake._lngLat.lng, 
                    lat: this.targetStake.originCoords.lat - this.targetStake._lngLat.lat
                };
                stakeableModel.stakes.updateAllPositions('targetStake', delta, this.position);
            }
            this.targetStake.originCoords = this.targetStake._lngLat;
            this.targetStake._map.magnify(this.targetStake._pos);
        });
        this.originStake.on('drag', () => {
            if (stakeableModel.state.isTranslating) {

                const delta = {
                    lng: this.originStake.originCoords.lng - this.originStake._lngLat.lng, 
                    lat: this.originStake.originCoords.lat - this.originStake._lngLat.lat
                };
   
                stakeableModel.stakes.updateAllPositions('originStake', delta, this.position);
            }
            this.originStake.originCoords = this.originStake._lngLat;
            this.originStake._map.magnify(this.originStake._pos);
        });
        this.targetStake.on('dragend', () => this.stopMagnify(this.targetStake));
        this.originStake.on('dragend', () => this.stopMagnify(this.originStake));
    }


    /**
     * Activates (by highlighting and opening the popup on) the pair clicked.
     */
    pickupStake(e) {
        e.stopPropagation();
        stakeableModel.stakes.activatePair(this);
    }

    /**
     * Given a stake, highlights its parent pair and initializes magnifying reticule on the stake.
     */
    startMagnify(activeStake) {
        // Close starter message early if starter open.
        if (stakeableModel.state.starterMessageOpen) {
            stakeableModel.hideStarterMessage();
        }
        stakeableModel.stakes.activatePair(this);
        this.doHighlightPair();
        this.hidePopupPair();
        activeStake.getElement().classList.add('stake-drag');
        activeStake._map.startMagnifying(activeStake);
    }

    /**
     * Given a stake, removes the magnifier reticule from it and resets the pair's states back to a highlighted pair.
     */
    stopMagnify(activeStake) {
        activeStake._map.tearDownMagnifier();
        activeStake.getElement().classList.remove('stake-drag');
        activeStake === this.targetStake ? this.targetStake.updateLatLngMenu() : null;
        activeStake.renderPopup();
        this.showPopupPair();
        stakeableModel.updateStakePair(this);
    }

    /**
     * Highlights both stakes.
     */
    doHighlightPair() {
        this.targetStake.doHighlight();
        this.originStake.doHighlight();
    }

    /**
     * Stops highlight both stakes.
     */
    stopHighlightPair() {
        this.targetStake.stopHighlight();
        this.originStake.stopHighlight();
    }

    /**
     * Removes both stakes.
     */
    removePair() {
        this.originStake.remove();
        this.targetStake.remove();
    }

    /**
     * Closes the popup for both stakes.
     */
    hidePopupPair() {
        this.originStake.hidePopup();
        this.targetStake.hidePopup();
    }

    /**
     * Opens the popup for both stakes.
     */
    showPopupPair() {
        this.originStake.showPopup();
        this.targetStake.showPopup();
    }

}

export default StakePair;

import Draw from './draw';
import nearestPoint from '@turf/nearest-point-on-line';
import maplibregl from 'maplibre-gl';
import centroid from '@turf/centroid';
import {translatePolygonCoordinates} from 'flows/create-layer/position-layer/utils/geometry-calculations';
import siteModel from 'models/site-model';
import {Marker} from 'maplibre-gl';

class LineString extends Draw {

    constructor(opts) {
        super(opts);

        this.centerVertex = null;
        this.addVertex = this._addVertex.bind(this);
        this.onMouseMove = this._onMouseMove.bind(this);
        this.checkLineClicked = this._checkLineClicked.bind(this);

        this.lastVertexOffset = 1;
        this.minVertexCount = 2;

        this.tally = undefined; // Current line length, displayed while drawing

        this.type = 'LineString';

        this.initGeometry = {
            type: 'LineString',
            coordinates: []
        };

        this.isComplete = false;
    }

    // Accessing coordinates through this function makes it easy to
    // re-use this class for polygons.
    getCoordinates() {
        return this.feature.geometry.coordinates;
    }

    render() {
        if (this.getCoordinates().length > 1) {
            this.source.setData(this.source._data);
            if (this.isNewFeature) {
                this.updateTally();
            }
            this.removePopup();
        }

        if (this.feature.updateMapFeature) {
            this.feature.updateMapFeature();
        }
    }

    _onMouseMove(e) {
        const coordinates = this.getCoordinates();
        coordinates[coordinates.length - this.lastVertexOffset] = (e.lngLat || e.target._lngLat).toArray();
        this.render();
    }

    _addVertex(e, index) {
        const lngLat = e.lngLat.toArray(),
            vertex = this.makeVertex(lngLat, index)
                .on('dragstart', () => this.removePopup())
                .on('dragend', () => {
                    if (this.onVertexChanged) {
                        this.onVertexChanged(this.feature);
                    }
                });

        this.updateFeatureOnVertexDrag(vertex);
        this.getCoordinates().push(lngLat);

        this.vertices.slice(1).forEach(v => {
            v._element.onclick = null;
            v._element.classList.remove('click-to-close');
        });

        if (vertex.index) {
            vertex._element.classList.add('click-to-close');
            vertex._element.onclick = this.lastVertexClick.bind(this);

        } else {
            this.getCoordinates().push(lngLat);
            this.source._data.features.push(this.feature);
            this.map.on('mousemove', this.onMouseMove);
        }

        if (this.onVertexAdded) {
            this.onVertexAdded(this.feature);
        }
    }

    _checkLineClicked(e) {
        if (this.justDragged) {
            return;
        }
        const bbox = [[e.point.x - 3, e.point.y - 3], [e.point.x + 3, e.point.y + 3]],
            clickedFeature = this.map.queryRenderedFeatures(bbox, {
                filter: ['==', '_id', this.feature.id],
                validate: false
            })[0];

        if (clickedFeature) {
            const point = nearestPoint(
                {
                    type: 'LineString',
                    coordinates: this.getCoordinates(true)
                },
                e.lngLat.toArray()
            );

            const button = document.createElement('i');
            button.classList.add('icon-plus');

            button.onclick = () => {
                this.getCoordinates(true).splice(
                    point.properties.index + 1,
                    0,
                    point.geometry.coordinates
                );

                this.removeVertices();
                this.editFeature();
                this.removePopup();
            };
            this.showPopup(point.geometry.coordinates, button);
        }
    }

    onVertexClick(vertex) {
        return e => {
            e.stopPropagation();
            if (this.popup) {
                return this.removePopup();
            }

            if (this.vertices.length <= this.minVertexCount) {
                return;
            }

            const button = document.createElement('i');
            button.classList.add('icon-trash');
            button.onclick = () => {
                this.removeVertex(vertex);
                this.render();
                this.removeVertices();
                this.edit(this.feature);

                if (this.onVertexChanged) {
                    this.onVertexChanged(this.feature);
                }
            };

            const container = this.map.getContainer();

            this.showPopup(
                this.map.unproject({
                    x: e.clientX - container.offsetLeft,
                    y: e.clientY - container.offsetTop
                }),
                button
            );

        };

    }

    lastVertexClick(e) {
        if (e) {
            e.stopPropagation();
        }

        const coordinates = this.getCoordinates();

        // Remove line segment that follows the mouse cursor
        if (coordinates.length > this.vertices.length) {
            coordinates.pop();
        }

        this.isComplete = true;
        this.render();
        this.stop();

        if (this.onComplete) {
            this.onComplete(this.feature);
        }
    }

    makeVerticesForEachCorner() {
        const coordinates = this.getCoordinates();
        coordinates.forEach(lngLat => {
            const vertex = this.makeVertex(lngLat)
                .on('dragstart', () => this.removePopup())
                .on('dragend', () => {

                    if (this.onVertexChanged) {
                        this.onVertexChanged(this.feature);
                    }
                });

            this.updateFeatureOnVertexDrag(vertex);
            vertex._element.onclick = this.onVertexClick(vertex);
        });
    }

    makeCenterVertex(lngLat) {
        const element = document.createElement('i');
        element.classList.add('translate-vertex');

        const vertex = new Marker(Object.assign({
            draggable: !this.isNewFeature,
            element,
            anchor: 'center'
        }))
            .setLngLat(lngLat)
            .addTo(this.map);    
        return vertex;
    }

    editFeature() {
        if (this.centerVertex) {
            this.centerVertex.remove();
            this.centerVertex = undefined;
        }
        this.isComplete = true;
        this.makeVerticesForEachCorner();

        // Eventually we could add in support for linestrings, it was cut from scope for the sake of time
        if (this.feature.geometry.type !== 'LineString' && this.feature.geometry.type !== 'Line') {
            this.addCenterVertexForMovingEntirePolygon();
        }
        this.map.on('click', this.checkLineClicked);
        
        return this;
    }

    updateFeatureOnVertexDrag(vertex) {
        if (!this.isNewFeature) {
            vertex.on('drag', e => {
                this.syncVertexCoordinates(vertex);

                if (this.isNewFeature) {
                    this.onMouseMove(e);
                } else {
                    this.render();
                }

                this.justDragged = true;

            }).on('dragend', () => {
                setTimeout(() => {
                    this.justDragged = false;
                }, 250);
            });
        }
    }

    addCenterVertexForMovingEntirePolygon() {
        const storeOriginalCenterPointAndCoords = () => {
            this.centerPointOriginal = Object.assign({}, this.centerVertex._lngLat);
            this.coordinatesOriginal = [...this.feature.geometry.coordinates];
        };

        const centroidPt = centroid(this.feature).geometry.coordinates;
        this.centerVertex = this.makeCenterVertex(centroidPt)
            .on('dragstart', () => {
                // Track the original center point and coordinates so we can calculate the translation
                storeOriginalCenterPointAndCoords();
                // Remove corner vertices, we'll add them back when the user stops dragging the center point
                this.removeVertices();
            }).on('drag', () => {
                // While dragging, calculate the translated polygon based on how much the center point has moved
                const newCenterPoint = Object.assign({}, this.centerVertex._lngLat);
                const translatedCoordinates = translatePolygonCoordinates(this.coordinatesOriginal, this.centerPointOriginal, newCenterPoint);

                // Find the feature in the map source to update
                const source = siteModel.map.getSource(this.feature.properties.featureTypeId);
                const featureIndex = source._data.features.findIndex(_geoJsonFeature => _geoJsonFeature.id === this.feature.featureId);
                const updatedFeature = source._data.features[featureIndex];
                updatedFeature.geometry.coordinates = translatedCoordinates;
                
                // Update the map source & any feature data with the translated polygon
                source._data.features[featureIndex] = updatedFeature;
                if (this.onVertexChanged) {
                    this.onVertexChanged(this.feature);
                    this.render();
                }

                // Update the original center point and coordinates so we can calculate the translation next time the user drags the center point
                storeOriginalCenterPointAndCoords();
            })
            .on('dragend', () => {
                // Re-create the corner vertices that we removed on dragstart
                this.makeVerticesForEachCorner();
            });
    }

    showPopup(point, button) {
        this.popup = new maplibregl.Popup({
            closeOnClick: true,
            closeButton: false,
            className: 'draw-btn',
            anchor: 'bottom',
            offset: [0, 0]
        }).setLngLat(point)
            .setDOMContent(button)
            .addTo(this.map)
            .on('close', () => {
                this.popup = null;
            });
    }

    syncVertexCoordinates(vertex) {
        this.getCoordinates()[vertex.index] = vertex.getLngLat().toArray();
    }

    removeVertex(vertex) {
        this.getCoordinates().splice(vertex.index, 1);
    }

    completeFeature() {
        if (this.isNewFeature && this.getCoordinates().length > 2) {
            this.lastVertexClick();
        }
    }

    removeEventListeners() {
        this.map.off('mousemove', this.onMouseMove);
        this.map.off('click', this.checkLineClicked);
        this.removeTally();
        this.removePopup();

        super.removeEventListeners();
    }

    /* --- Keeping track of line length ---- */

    updateTally() {
        const coordinates = this.getCoordinates();
        if (coordinates.length > 1) {
            const displayLength = this.length(coordinates);
            if (this.tally) {
                this.tally.setLngLat(coordinates[coordinates.length - this.lastVertexOffset])
                    .setHTML(displayLength);
            } else {
                this.tally = new maplibregl.Popup({
                    closeOnClick: false,
                    closeButton: false,
                    className: 'tally',
                    offset: [0, -10]
                }).setLngLat(coordinates[coordinates.length - this.lastVertexOffset])
                    .setText(displayLength)
                    .addTo(this.map)
                    .on('close', () => {
                        this.tally = null;
                    });
            }
        }
    }

    removeTally() {
        if (this.tally) {
            this.tally.remove();
        }
    }


}

export default LineString;

import maplibregl from 'maplibre-gl';
import latLngsToLngLats from 'util/geo/latlngs-to-lnglats';
import lngLatsToBounds from 'util/geo/lnglats-to-bounds';
import turfLength from '@turf/length';
import turfLineString from 'turf-linestring';
import round from 'util/numbers/round';
import isNumber from 'util/numbers/is-number';
import lngLatsToLatLngs from 'util/geo/lnglats-to-latlngs';
import queryFeatures from 'util/geo/query-features';
import layerModel from 'models/layer-model';
import store from 'util/data/store';
import api from 'legacy/util/api';
import area from '@turf/area';
import measure from 'legacy/util/numbers/measure';
import bbox from '@turf/bbox';
import hasElevationData from 'legacy/util/data/has-elevation-data';
import {LngLat, LngLatBounds} from 'maplibre-gl';
import transformRotate from '@turf/transform-rotate';
import siteModel from 'models/site-model';
import addCommas from 'util/numbers/add-commas';
import isMetric from 'util/numbers/is-metric';

const units = isMetric() ? 'feet' : 'meters';

function length(coordinates, _units) {

    return turfLength(turfLineString(coordinates), {
        units: _units || units
    });

}

function metersPerPixel(latitude, zoom) {

    return Math.abs(40075016.686 * Math.cos(latitude * Math.PI / 180) / Math.pow(2, zoom + 9));

}

function metersToPixels(meters, latitude, zoom) {

    return meters / metersPerPixel(latitude, zoom);

}

function pixelsToMeters(pixels, latitude, zoom) {

    return pixels * metersPerPixel(latitude, zoom);

}

function handleMulti(feature, handler, precision) {

    const results = feature.geometry.coordinates.map(coordinates => handler({
        type: 'Feature',
        geometry: {
            type: feature.geometry.type.replace('Multi', ''),
            coordinates
        }
    }, precision));

    const result = results.reduce((a, b) => {

        a.float += b.float;

        return a;

    });

    result.string = addCommas(result.float);

    return result;

}

function getDisplayArea(feature, precision) {

    if (feature.geometry.type.startsWith('Multi')) {

        return handleMulti(feature, getDisplayArea, precision);

    }

    const useMetric = isMetric();

    const metric = useMetric ? 'm²' : 'ft²';

    let float = area(feature);

    if (!useMetric) {

        float = measure.squareMetersToSquareFeet(float);

    }

    float = round(float, precision);

    return {
        float,
        string: addCommas(float),
        metric
    };

}

function getDisplayLength(feature, precision) {

    if (feature.geometry.type.startsWith('Multi')) {

        return handleMulti(feature, getDisplayLength, precision);

    }

    const useMetric = isMetric();

    const metric = useMetric ? 'm' : 'ft';

    const float = round(turfLength(feature, {
        units: useMetric ? 'meters' : 'feet'
    }), precision);

    return {
        float,
        string: addCommas(float),
        metric
    };

}

function getDisplayPerimeter(feature, precision) {

    if (feature.geometry.type.startsWith('Multi')) {
        return handleMulti(feature, getDisplayPerimeter, precision);
    }

    return getDisplayLength(turfLineString(feature.geometry.coordinates[0]), precision);

}

function getDisplayVolumeDiff(feature, surveyId, comparison, precision) {

    const coordinates = lngLatsToLatLngs(feature.geometry.coordinates[0]);

    return api.get.volumeDiff(coordinates, surveyId, comparison)
        .then(volume => volumeToDisplayVolume(volume, precision));

}

function getVolume(feature) {

    const survey = layerModel.state.surveyId
        ? store.surveys[layerModel.state.surveyId]
        : Object.values(store.surveys).find(s => hasElevationData(s, feature));

    if (survey && survey.hasElevationData) {

        let boundaries = feature.geometry.type === 'MultiPolygon'
            ? feature.geometry.coordinates.map(coordinates => coordinates[0])
            : [feature.geometry.coordinates[0]];

        boundaries = boundaries.filter(coordinates => coordinates && coordinates.length > 3);

        if (boundaries.length) {
            return Promise.all(boundaries
                .map(coordinates =>
                    api.rpc.request([['getSurveyVolume', {
                        surveyId: survey.surveyId,
                        boundary: lngLatsToLatLngs(coordinates)
                    }]], true)
                        // resolve to an empty object if the 
                        // survey doesn't contain data for the boundary
                        .catch(() => ({}))
                )).then(volumes =>
                // Add up the volumes of each Polygon
                volumes.reduce((a, b) => ({
                    fill: a.fill + b.fill,
                    cut: a.cut + b.cut
                }))
            );
        }
    }

    return Promise.resolve({});

}

function volumeToDisplayVolume(volume, precision) {

    const keys = Object.keys(volume);

    if (keys.length && !volume.hasOwnProperty('error')) {

        const useMetric = isMetric();

        const metric = useMetric ? 'm³' : 'yd³';

        keys.forEach(key => {

            let float = volume[key];

            if (isNumber(float)) {

                if (!useMetric) {

                    float = measure.cubicMetersToCubicYards(volume[key]);

                }

                float = round(float, precision);

                volume[key] = {
                    float,
                    metric,
                    string: addCommas(float)
                };
            }

        });

    } else {

        const NA = {
            metric: '',
            string: 'N/A'
        };

        volume = {
            cut: NA,
            fill: NA,
            total: NA
        };

    }

    return volume;
}

function getDisplayVolume(feature, precision) {

    if (feature.geometry.type.startsWith('Multi')) {

        return Promise.all(feature.geometry.coordinates.map(coordinates => getDisplayVolume({
            type: 'Feature',
            geometry: {
                type: feature.geometry.type.replace('Multi', ''),
                coordinates
            }
        }, precision))).then(volumes => {

            const volume = volumes.reduce((a, b) => {

                a.float += b.float;

                return a;

            });

            volume.string = addCommas(volume.float);

            return volume;

        });

    }

    return getVolume(feature).then(volume => volumeToDisplayVolume(volume, precision));

}

let canvas,
    ctx;

function getTextRect(feature) {

    const textSizeMeters = feature.properties._textSizeMeters,
        textField = feature.properties._textField || '',
        textRows = textField.split(/\n/g),
        coordinates = feature.geometry.coordinates,
        latitude = coordinates[1],
        zoom = siteModel.map.getZoom(),
        textSizePixels = metersToPixels(textSizeMeters, latitude, zoom);

    canvas = canvas || document.createElement('canvas');
    ctx = ctx || canvas.getContext('2d');

    ctx.font = `300 ${textSizePixels}px 'museo sans rounded'`;

    let widthPixels = 0;

    textRows.forEach(row => {

        widthPixels = Math.max(widthPixels, ctx.measureText(row).width);

    });

    const lngLat = new LngLat(...coordinates),
        heightMeters = textSizeMeters * textRows.length,
        widthMeters = pixelsToMeters(widthPixels, latitude, zoom), 
        boundsY = LngLatBounds.fromLngLat(lngLat, heightMeters / 2),
        boundsX = LngLatBounds.fromLngLat(lngLat, widthMeters / 2),
        northLat = boundsY.getNorth(),
        eastLng = boundsX.getEast(),
        southLat = boundsY.getSouth(),
        westLng = boundsX.getWest(),
        rotation = feature.properties._rotation;

    let polygon = {
        type: 'Feature',
        geometry: {
            type: 'Polygon',
            coordinates: [[
                [westLng, northLat],
                [eastLng, northLat],
                [eastLng, southLat],
                [westLng, southLat],
                [westLng, northLat]
            ]]
        },
        properties: feature.properties
    };

    if (rotation) {

        polygon = transformRotate(polygon, rotation, {
            pivot: coordinates
        });

    }

    return polygon;

}

function bounds(feature) {

    if (Array.isArray(feature)) {

        feature = turfLineString(feature);

    }

    const [minX, minY, maxX, maxY] = bbox(feature);

    function contains(otherFeature) {
        const b = bounds(otherFeature);
        return b.minX >= minX && b.minY >= minY
            && b.maxX <= maxX && b.maxY <= maxY;
    }

    function getCenter() {
        return new maplibregl.LngLat(
            (maxX - minX) / 2 + minX,
            (maxY - minY) / 2 + minY
        );
    }

    return {minX, minY, maxX, maxY, contains, getCenter};

}


export {
    bounds,
    length,
    pixelsToMeters,
    metersPerPixel,
    metersToPixels,
    latLngsToLngLats,
    lngLatsToBounds,
    lngLatsToLatLngs,
    queryFeatures,
    getDisplayArea,
    getDisplayLength,
    getDisplayPerimeter,
    getVolume,
    getDisplayVolumeDiff,
    getDisplayVolume,
    getTextRect,
    volumeToDisplayVolume
};

import maplibregl, {ScaleControl} from 'maplibre-gl';
import store from 'util/data/store';
import router from 'uav-router';
import constants from 'util/data/constants';
import styleModel from 'models/style-model';
import LayerControl from 'views/layer-control';
import layerModel from 'models/layer-model';
import {latLngsToLngLats, lngLatsToBounds}  from 'util/geo';
import api from 'legacy/util/api';
import MagnifierModel from 'models/magnifier-model';
import helpers from 'legacy/util/api/helpers';
import popup from 'util/popup';
import siteModel from './site-model';
import tableModel from './table/table-model';
import screenHelper from 'legacy/util/device/screen-helper';
import {metersToPixels} from 'util/geo';
import {LngLat, LngLatBounds} from 'maplibre-gl';
import AssetForm from 'views/asset-form';
import isMetric from 'util/numbers/is-metric';
import batchSelectModel from 'models/batch-select-model';
import centroid from '@turf/centroid';
import featureListManager from 'managers/feature-list-manager';
import convertMithril from 'util/dom/convert-mithril';
import BoundaryPopup from 'views/boundary-menu/boundary-popup';
import accountManager from 'managers/account-manager';

const SIDEBAR_OFFSET = [181, 0];
const TABLE_OFFSET = [0, -180];
const MIN_ZOOM = 16; // A reasonable minimum zoom when panning the map to a lng/lat

export const SIDEBAR_WIDTH = 360,
    TABLE_HEIGHT = 383;


/**
 * Handles all logic and state for maplibre objects.
 */
class MapModel extends maplibregl.Map {
    constructor(opts, settings) {

        opts = Object.assign({}, {
            style: styleModel.reset(),
            attributionControl: false
        }, opts);

        if (router.params.view === 'pdf') {
            opts.preserveDrawingBuffer = true;
            opts.fadeDuration = 0;
        }

        super(opts);
        this.siteId = router.params.siteId;
        this.settings = settings ? settings : {};
        this.setControls();
        this.mapLoaded().then(() => {
            !this.settings.basemapOff ? this.setBasemap() : null;
        });
        this.getPermissions();
        const zoomIn = this.zoomIn.bind(this);

        this.zoomIn = (animationOpts) => {
            const pop = popup.isOpen();
            if (pop && !animationOpts.offset) {
                const popXY = this.project(pop[0].getLngLat());
                const centerXY = this.project(this.getCenter());
                zoomIn({
                    offset: [popXY.x - centerXY.x, popXY.y - centerXY.y]
                });
            } else {
                zoomIn(animationOpts);
            }
        };
        this.on('styleimagemissing', (e) => featureListManager.addImage(e.id, constants.staticMediaURL + e.id));
    }

    /**
     * Given a camera, sets the center and zoom of the map to its attributes.
     * @param camera
     */
    setCamera(camera) {
        // Set zoom before setting center, and make sure it's not set to 0. 
        // If zoom === 0 before setting center, resulting lat will be dynamic (based on viewport height) https://github.com/maplibre/maplibre-gl-js/issues/2864
        this.setZoom(camera.zoom);
        this.setCenter(camera.center);
    }
    
    getPermissions() {
        if (navigator.permissions) {
            navigator.permissions && navigator.permissions.query({name: 'geolocation'}).then((PermissionStatus) => {
                if (PermissionStatus.state === 'granted') {
                    this.settings.geolocateOff = false;
                } else if (PermissionStatus.state === 'prompt') {
                    this.settings.geolocateOff = false;
                } else {
                    this.settings.geolocateOff = true;
                }
            });
        } else {
            navigator.geolocation.watchPosition(() => {
                this.settings.geolocateOff = false;
            }, (error) => {
                if (error.code === error.PERMISSION_DENIED) {
                    this.settings.geolocateOff = true;
                }
            });
        }
    }

    /**
     * Returns the center and zoom of the map as it is currently positioned.
     */
    getCamera() {
        return {
            center: this.getCenter(),
            zoom: this.getZoom()
        };
    }
    /**
     * Set Map controls based on passed params.
     *
     * By default, map will have zoom navigation, geolocate, and
     * standard LayerControl menu in the bottom-right corner.
     */
    setControls() {
        const location = this.settings.controlsLocation
            ? this.settings.controlsLocation
            : 'bottom-right';
        this.geolocate = new maplibregl.GeolocateControl({positionOptions: {enableHighAccuracy: true}, trackUserLocation: true});
        if (!this.settings.scaleOff) {
            const scale = this.scaleControl = new ScaleControl({unit: isMetric() ? 'metric' : 'imperial'});
            this.addControl(scale, location);
        }
        if (!this.settings.navcontrolOff) {
            const showCompass = this.settings.hideCompass ? false : true;
            this.addControl(new maplibregl.NavigationControl({showCompass}), location);
        }
        if (!this.settings.geolocateOff) {
            this.addControl(this.geolocate, location);
        }
        if (!this.settings.layercontrolOff) {
            layerModel.layerControl = new LayerControl();
            this.addControl(layerModel.layerControl, location);
        }
    }
    // TODO: un-comment,this func will be used to use the browsers location to create a project, not being used yet
    //     setProjectGeolocation() {
    //         this.projectGeolocate = new maplibregl.GeolocateControl({positionOptions: {enableHighAccuracy: true}, trackUserLocation: true});
    //         if (!this.settings.geolocateOff) {
    //             this.addControl(this.projectGeolocate);
    //             this.projectGeolocate.on('geolocate', (e) => {
    //                 const lon = e.coords.longitude;
    //                 const lat = e.coords.latitude;
    //                 const position = [lon, lat];
    //                 const latlong = new maplibregl.LngLat(position[0], position[1]);
    //                 const coords =  LngLatBounds.fromLngLat(latlong, 500).toArray();
    //                 const geoJSON = this.getProjectBoundsData([[coords]]);
    //                 appModel.toolbox.toolInterface.setGeoJSONData(geoJSON,  appModel.toolbox.toolInterface.tool.name);
    //             });
    //         }
    //     }

    getCurrentLocationBoundCoords(coords) {
        const boundsCoords = [];
        coords.forEach(coord => {
            if (coord.length !== 1) {
                coord.forEach(geoCoords => {
                    boundsCoords.push(geoCoords);
                });
            } else {
                boundsCoords.push(coord);
            }
        });
        this.addBoundingBox([boundsCoords]);
    }

    /**
     * Returns promise re: style loaded on maplibre object.
     */
    mapLoaded() {
        return new Promise(resolve => {
            if (this.isStyleLoaded()) {
                resolve();
            } else {
                this.once('styledata', () => {
                    resolve();
                });
            }
        });
    }

    getBoundsPolygon(bounds) {
        if (!bounds) {
            bounds = this.getBounds();
        }
        
        const nw = bounds.getNorthWest().toArray();
        return {
            type: 'Polygon',
            coordinates: [
                [
                    nw,
                    bounds.getNorthEast().toArray(),
                    bounds.getSouthEast().toArray(),
                    bounds.getSouthWest().toArray(),
                    nw
                ]
            ]
        };
    }

    /**
     * Adds a color circle as the second map layer (on top of the basemap).
     * Required params: id for source/layer, [lngLat] for center of circle
     * Optional params: opts = {radius, hex, opacity}
     */
    addColorCircle(id, lngLats, opts = {}) {
        const beforeLayer = this.getStyle().layers.find(layer => layer.type !== 'raster');
        this.addSource(id, {
            'type': 'geojson',
            'data': {
                'type': 'FeatureCollection',
                'features': lngLats.map((lngLat) => {
                    return {
                        'type': 'Feature',
                        'geometry': {
                            'type': 'Point',
                            'coordinates': lngLat
                        }
                    };
                })
            }
        });


        this.addLayer({
            'id': id,
            'type': 'circle',
            'source': id,
            'paint': {
                'circle-radius': opts.radius || 200,
                'circle-color': opts.hex || '#109494', // defaults to $teal1
                'circle-opacity': opts.opacity || 0.2
            }
        }, beforeLayer && beforeLayer.id);
    }

    getBoundCoords() {
        const boundsCoords = [];
        if (Object.keys(batchSelectModel.selectedAssetsFeatures).length === 0) {
            return this.safeRemoveSource('selectedBoundingBox');  
        } 
        Object.values(batchSelectModel.selectedAssetsFeatures).forEach(coord => {
            if (coord.length !== 1) {
                coord.forEach(geoCoords => {
                    boundsCoords.push(geoCoords);
                });
            } else {
                boundsCoords.push(coord);
            }
        });
        this.addBoundingBox(boundsCoords);
    }

    selectPointAsset(id, lngLats) {
        lngLats.forEach((lngLat) => {
            const latlong = new LngLat(lngLat[0], lngLat[1]);
            const bounds = LngLatBounds.fromLngLat(latlong, 10).toArray();
            batchSelectModel.selectedAssetsFeatures[id] = [bounds];
        });
        this.getBoundCoords();
        // remove source with this ID to prevent accidental dupes
        this.safeRemoveSource(id);
        this.addSource(id, {
            'type': 'geojson',
            'data': {
                'type': 'FeatureCollection',
                'features': lngLats.map((lngLat) => {
                    return {
                        'type': 'Feature',
                        'geometry': {
                            'type': 'Point',
                            'coordinates': lngLat
                        }
                    };
                })
            }
        });
        this.addLayer({
            'id': id,
            'type': 'circle',
            'source': id,
            'paint': {
                'circle-radius': 8,
                'circle-color': '#FF5ADB',
                'circle-stroke-color': '#FF5ADB',
                'circle-stroke-width': 1, // defaults to $teal1
                'circle-opacity': 1
            }
        });
    }

    selectPolygonAsset(id, lngLats) {
        this.getBoundCoords();
        // remove source with this ID to prevent accidental dupes
        if (this.getLayer(id + '_line')) {
            this.removeLayer(id + '_line');
        }
        this.safeRemoveSource(id);
        this.addSource(id, {
            'type': 'geojson',
            'data': {
                'type': 'Feature',
                'geometry': {
                    'type': 'Polygon',
                    'coordinates': [
                        lngLats[0]
                    ]
                }
            }
        });
        
        this.addLayer({
            'id': id,
            'type': 'fill',
            'source': id,
            'layout': {},
            'paint': {
                'fill-color': '#FF5ADB',
                'fill-opacity': 0.4
            }
        });
        this.addLayer({
            'id': id + '_line',
            'type': 'line',
            'source': id,
            'layout': {},
            'paint': {
                'line-color': '#FF5ADB',
                'line-width': 2
            }
        });
    }

    getProjectBoundsData(coordinates) {
        return {
            type: 'Polygon',
            coordinates: [
                [
                    coordinates.getNorthWest().toArray(),
                    coordinates.getNorthEast().toArray(),
                    coordinates.getSouthEast().toArray(),
                    coordinates.getSouthWest().toArray(),
                    coordinates.getNorthWest().toArray()
                ]
            ]
        };
    }

    getBoundsData(coordinates) {
        const coords = coordinates[0];
        const selectedBounds = Array.isArray(coords[0][0]) ? new maplibregl.LngLatBounds( coords[0][0], coords[0][0] ) : new maplibregl.LngLatBounds( coords[0], coords[0]);
        coordinates.forEach(coord => {
            if (Array.isArray(coord[0][0])) {
                coord[0].forEach(data => {
                    selectedBounds.extend(data);
                });
            } else {
                selectedBounds.extend(coord[0]);
            }
        });
        const nw =  selectedBounds.getNorthWest().toArray();
        return {
            type: 'Polygon',
            coordinates: [
                [
                    nw,
                    selectedBounds.getNorthEast().toArray(),
                    selectedBounds.getSouthEast().toArray(),
                    selectedBounds.getSouthWest().toArray(),
                    nw
                ]
            ]
        };
    }

    selectLineAsset(id, coordinates) {
        // remove source with this ID to prevent accidental dupes
        this.safeRemoveSource(id);
        this.addSource(id, {
            'type': 'geojson',
            'data': {
                'type': 'Feature',
                'geometry': {
                    'type': 'LineString',
                    'coordinates': coordinates[0]
                }
            }
        });
        this.addLayer({
            'id': id,
            'type': 'line',
            'source': id,
            'layout': {},
            'paint': {
                'line-color': '#FF5ADB',
                'line-width': 2
            }
        });
        this.getBoundCoords();
    }
    //Adding the bounding box around all features selected, remove and readd layer on every asset select
    addBoundingBox(coordinates) {
        this.safeRemoveSource('selectedBoundingBox');
        this.addSource('selectedBoundingBox', {
            'type': 'geojson',
            'data': this.getBoundsData(coordinates)
        });
        this.addLayer({
            'id': 'selectedBoundingBox',
            'type': 'line',
            'source': 'selectedBoundingBox',
            'layout': {},
            'paint': {
                'line-color': '#FF5ADB',
                'line-width': 1
            }
        });
    }

    // this can be used if we want to preview the bounds before site creation 
    
    // addProjectBoundingBox(boundingBox) {
    //     this.safeRemoveSource('selectedProjectBoundingBox');
    //     this.addSource('selectedProjectBoundingBox', {
    //         'type': 'geojson',
    //         'data': this.getProjectBoundsData(boundingBox)
    //     });
    //     this.addLayer({
    //         'id': 'selectedProjectBoundingBoxFill',
    //         'type': 'fill',
    //         'source': 'selectedProjectBoundingBox',
    //         'layout': {},
    //         'paint': {
    //             'fill-color': '#FF5ADB',
    //             'fill-opacity': 0.4
    //         }
    //     });
    //     this.addLayer({
    //         'id': 'selectedProjectBoundingBox',
    //         'type': 'line',
    //         'source': 'selectedProjectBoundingBox',
    //         'layout': {},
    //         'paint': {
    //             'line-color': '#FF5ADB',
    //             'line-width': 1
    //         }
    //     });
    // }

    /**
     * Pans to the passed coords and displays a popup at the center with the provided text.
     * Required params: lngLat for center of popup, maplibre opts for panning, popupText (1 or 2 strings in array)
     */
    panToAndHighlight(lngLat, opts = {}, popupText) {
        if (this.searchPopup) {
            this.searchPopup.remove(); // Remove left over search popup and circle if it exists
        }
        const id = 'circle-search-result';
        this.addColorCircle(id, [lngLat]);
        this.searchPopup = new maplibregl.Popup({
            offset: 0,
            maxWidth: '300px',
            className: 'search-result-popup',
            closeButton: false,
            closeOnClick: true
        }) .setDOMContent(convertMithril.toDom(<BoundaryPopup popupText={popupText}/>))
            .setLngLat(lngLat)
            .addTo(this);
        this.searchPopup.once('close', () => this.safeRemoveSource(id));
        if (this.getZoom() < MIN_ZOOM) {
            this.setZoom(MIN_ZOOM);
        }
        this.panTo(lngLat, opts);
    }

    panToFeatures(features, offset) {
        const centroidTarget = features.map((feature) => centroid(feature).geometry.coordinates);
        if (offset) {
            // If an offset was passed, be precise about it:
            this.panTo(centroidTarget[0], {offset});
        } else {
            // Otherwise, just pan if not visible in viewport:
            this.panIfNotInView(centroidTarget[0]);
        }
    }


    // ------------ Magnifying Glass Setup and Event Handling ------------

    /**
     * Creates the necessary elements for the map magnifier, which is activated via dragging a Stake.
     * Called from stakeableModel.
     */
    addMagnifier(useLightBg) {
        this.canvas2d = document.createElement('canvas');
        this.ctx2d = this.canvas2d.getContext('2d');
        this.mapLoaded().then(() => {
            this.magnifier = new MagnifierModel(this, useLightBg);
            this.getCanvasContainer().appendChild(this.magnifier.reticule);
            this.canvas2d.width = this.getCanvas().width;
            this.canvas2d.height = this.getCanvas().height;
        });
        this.on('resize', () => this.updateMagnifierSizes());
    }

    /**
     * Called on resize of map: Updates dimensions for magnifier. Also updates the magnifier's
     * pixel density ratio in case map was moved between retina and non-retina.
     */
    updateMagnifierSizes() {
        if (this.magnifier) {
            this.magnifier.reticule.width = this.getCanvas().width;
            this.magnifier.reticule.height = this.getCanvas().height;
            this.canvas2d.width = this.getCanvas().width;
            this.canvas2d.height = this.getCanvas().height;
            this.magnifier.devicePixelRatio = window.devicePixelRatio || window.screen.deviceXDPI / window.screen.logicalXDPI;
        }
    }

    /**
     * Runs on dragstart of Stake - Capture 2d snapshot of map for magnifying.
     */
    startMagnifying(stake) {
        this.magnifier.reticule.classList.remove('hidden');
        this.magnifier.stake = stake;
        this.on('zoom', this.zoomMagnifier);

        // Capture the 2d canvas copy once on render (and then force a rerender),
        // So we have an updated snapshot of the current map for zooming.
        // Otherwise webgl will haved dumped the buffer, leaving us with nothing to copy.
        this.once('render', this.copyGlTo2d);
        this.triggerRepaint();
    }

    /**
     * Runs on drag of Stake - get location of stake, pass into magnifier to render.
     */
    magnify(point) {
        this.magnifier.render(point);
    }

    /**
     * Runs on dragend of Stake - cleanup event listeners and resources from active magnifier.
     */
    tearDownMagnifier() {
        this.off('zoom', this.zoomMagnifier);
        this.magnifier.cleanup();
    }

    /*
     * Runs when both magnifier is active and mouse wheelzoom event occurs.
     */
    zoomMagnifier() {
        this.once('render', this.copyGlTo2d);
        this.magnifier.renderZoom();
    }

    /**
     * Copies the webgl map data to a 2d ctx canvas, saved to the maplibre object.
     */
    copyGlTo2d() {
        this.ctx2d.clearRect(0, 0, this.canvas2d.width, this.canvas2d.height);
        this.ctx2d.drawImage(this.getCanvas(), 0, 0);
    }

    // ------------ maplibre helper methods (some code copied from layerModel) ------------

    /**
     * Sets the basemap using the passed ID. If none provided, sets default basemap.
     */
    setBasemap(Id) {
        const defaultMap = accountManager.isFieldTrial ? Object.keys(constants.basemaps)[3] : Object.keys(constants.basemaps)[0];
        const basemapId = Id ? Id : defaultMap;
        if (basemapId === '0') {
            this.safeRemoveSource('basemap');
        } else {
            const basemap = constants.basemaps[basemapId];
            this.createSource('basemap', basemap);
            if (!this.getLayer('basemap')) {
                const beforeLayer = this.getStyle().layers[0];
                this.addLayer({
                    id: 'basemap',
                    source: 'basemap',
                    type: basemap.type
                }, beforeLayer && beforeLayer.id);
            }
        }
    }

    /**
     * Shows the survey of the ID provided and sets map bounds to it.
     */
    showSurveyForStaking(Id) {
        const surveyId = Id ? Id : router.params.survey;
        return api.get.tileset(store.surveys[surveyId].baseTilesetId).then(tileset => {
            this.tilesetBounds = lngLatsToBounds(latLngsToLngLats(tileset.bounds));
            this.showTileset(tileset);
            this.fitBounds(this.tilesetBounds, {
                animate: false,
                padding: {top: 20, bottom: 20, left: 20, right: 20}
            });
        });
    }

    /**
     * Readies a tileset for editing (staking, cropping, etc).
     * Using the tileset passed, creates a source and layer and fits the map to the asset.
     */
    setUpEditingLayer(tileset, color, opts = {}) {
        const tilesetId = tileset.tilesetId;
        let urlTemplate = helpers.URLTemplateFMTToPNG(tileset.urlTemplate);
        urlTemplate = `${urlTemplate}${color ? '&color=' + color : ''}`;
        this.createRasterSource(tilesetId, urlTemplate, {maxzoom: 24});
        const beforeLayerId = opts.beforeLayerId;
        this.addLayer({
            id: tilesetId,
            url: urlTemplate,
            source: tilesetId,
            type: 'raster',
            paint: {
                'raster-fade-duration': 0
            }
        }, beforeLayerId);        
    }

    centerOnTileset(tileset, padding = {top: 20, bottom: 20, left: 20, right: 20}, animate = false) {
        const bounds = lngLatsToBounds(latLngsToLngLats(tileset.bounds));
        this.fitBounds(bounds, {
            animate,
            padding
        });
    }

    /**
     * Creates a layer using the tileset passed, and adds it before the beforeLayer passed.
     */
    showTileset(tileset, beforeLayer) {
        const tilesetId = tileset.tilesetId;
        const urlTemplate = helpers.URLTemplateFMTToPNG(tileset.urlTemplate);

        this.createRasterSource(tilesetId, urlTemplate, {
            maxzoom: 24,
            bounds: tileset.bounds
        });
        if (!beforeLayer) {
            const firstNonRasterLayer = this.getStyle().layers.find(layer => layer.type !== 'raster');
            if (firstNonRasterLayer) {
                beforeLayer = firstNonRasterLayer.id;
            }
        }
        this.addLayer({
            id: tilesetId,
            source: tilesetId,
            type: 'raster'
        }, beforeLayer);
    }

    /**
     * Checks first that the id doesnt already exist and adds it if not.
     * @param {*} id - Unique ID for the image to add.
     * @param {*} img - img object to add.
     */
    safeAddImage(id, img) {
        if (!this.hasImage(id)) {
            this.addImage(id, img);
        }
    }

    /**
     * Checks first that the source exists, removes any layers using it,
     * and finally removes the source.
     * @param {*} id - Unique ID for the source to remove.
     */
    safeRemoveSource(id) {
        if (this.getSource(id)) {
            if (this.getLayer(id)) {
                this.removeLayer(id);
            }
            this.removeSource(id);
        }
    }

    /**
     * Adds a new source to the maplibre object.
     * @param {*} id - Unique ID for the new source
     * @param {*} source - Source to add to maplibre object
     */
    createSource(id, source) {
        this.safeRemoveSource(id);
        return this.addSource(id, source);
    }

    /**
     * Creates a new geojson source and adds to the maplibre object.
     * @param {*} id - Unique ID for the new source
     * @param {*} lineMetrics - Boolean, defaults to false
     */
    createGeoJSONSource(id, lineMetrics = false) {
        return this.createSource(id, Object.assign({
            type: 'geojson',
            lineMetrics: lineMetrics,
            data: {
                type: 'FeatureCollection',
                features: []
            }
        }));
    }

    /**
     * Creates a new raster source on the maplibre object.
     * @param {*} id - Unique ID for the new source
     * @param {*} url - URL of the raster source tiles
     * @param {*} opts - maplibre options to include for the source
     */
    createRasterSource(id, url, opts) {
        if (opts.bounds) {
            if (!Array.isArray(opts.bounds)) {
                opts.bounds = [
                    [opts.bounds.getNorthEast().lat, opts.bounds.getNorthEast().lng],
                    [opts.bounds.getSouthWest().lat, opts.bounds.getSouthWest().lng]
                ];
            } 
            opts.bounds = layerModel.flattenBounds(opts.bounds);
        }
        this.createSource(id, Object.assign({
            type: 'raster',
            tiles: [url],
            maxzoom: 24,
            tileSize: 256
        }, opts));
    }

    /**
     * When centering the  map on a point, use an offset parameter to account for the sidebar or table,
     * depending on the current state of the UI
    */
    get tableOffset() {
        if (!siteModel.isTableActive() || tableModel.tableMode === 'list-left') {
            return SIDEBAR_OFFSET;
        }
        return TABLE_OFFSET;
    }

    // Zooms all the way in before fitting to bounds (for a more accurate presentation
    safeFitBounds(bounds, opts) {
        this.zoomTo(20); 
        this.fitBounds(bounds, opts);
    }

    // Only pan if the target is not currently within viewport bounds
    panIfNotInView(targetLngLat) {
        if (!this.getBounds().contains(targetLngLat)) {
            this.panTo(targetLngLat);
        }
    }

    /**
     * focusOnBounds: Fits the targetBounds in the viewport.
     *      FocusOnBounds also zooms on the center of the targetBounds while the bounds are centered.
     *
     * @param targetBounds: The bounds of the feature you are zooming to.
     * @param centroidTarget: Optional parameter if we are centering on a centroid instead of the bounds of the targetBound. targetBounds is still required.
     */
    focusOnBounds(targetBounds, centroidTarget) {

        const panOptions = {duration: 1500, padding: {}},
            map = this;
    
        let currentBounds = map.getBounds();
    
        const swPx = map.project(currentBounds.getSouthWest());
    
        if (screenHelper.large()) {
            if (tableModel.tableMode === 'list-left' || siteModel.sidebar === AssetForm) {
                panOptions.padding = {left: SIDEBAR_WIDTH};
                swPx.x += SIDEBAR_WIDTH;
            } else if (tableModel.tableMode === 'table-bottom') {
                panOptions.padding = {bottom: TABLE_HEIGHT};
                swPx.y -= TABLE_HEIGHT;
            }
            currentBounds = new maplibregl.LngLatBounds(
                map.unproject(swPx).wrap(),
                currentBounds.getNorthEast()
            );
        }
    
        const currentCenter = currentBounds.getCenter(),
            newCenter = centroidTarget ? new LngLat(centroidTarget[0], centroidTarget[1]) : targetBounds.getCenter(),
            currentZoom = map.getZoom(),
            distanceMeters = newCenter.distanceTo(currentCenter),
            distancePx = metersToPixels(distanceMeters, currentCenter.lat, currentZoom);

        if (distancePx > 5) {
    
            const {minX, maxX, minY, maxY} = targetBounds;
            let offsetWidth, offsetHeight;
    
            if (minX === maxX && minY === maxY) {
                // defining a 0.0009 here as a default zoom level;
                // value set by referencing calculatedWidth + calculatedHeight values defined in the zoom function for polygons/lines.
                // we can't calculate it according to the same formula because minX === minY for points
                offsetWidth = offsetHeight = 0.0009;
            } else {
                // fitBounds on the center or centroid while still zooming to the correct zoom level.
                offsetWidth = Math.abs(maxX - minX) / 0.4;
                offsetHeight = Math.abs(maxY - minY) / 0.4;
            }
    
            const ne = {
                lng: newCenter.lng - offsetWidth,
                lat: newCenter.lat - offsetHeight
            };
            const sw = {
                lng: newCenter.lng + offsetWidth,
                lat: newCenter.lat + offsetHeight
            };
            map.fitBounds(new maplibregl.LngLatBounds(
                ne,
                sw
            ), panOptions);
    
        }
    }

}

export default MapModel;

import constants from 'util/data/constants';
import appModel from 'models/app-model';
import featureListManager from 'managers/feature-list-manager';

const TRIMBLE_DATA_KEY = constants.trimbleDataKey;

class FeatureModel {

    constructor(_featureData, origin, skipRedraw = false) {
        this._featureReadyPromises = [];
        this.asset = {};
    
        Object.assign(this, _featureData);
        this._normalizeFeatureData(_featureData);

        this.setupFeatureProperties().then(() => {
            if (origin !== 'drawTool') {
                this.syncAllFeatureAssetProperties();
                this.addToMap(skipRedraw);
            }
            this._onFeatureReady();
        });
    }

    get featureType() {
        return appModel.toolbox.featureTypes[this.featureTypeId];
    }

    addToMap(skipRedraw = false) {
        const geoJsonFeature = this.getGeoJsonFeature();
        const source = this.featureType.source;

        // First check if this has already been added to the map: 
        const featureIndex = source._data.features.findIndex(_geoJsonFeature => _geoJsonFeature.id === this.featureId);
        if (featureIndex > -1) {
            // If found, just update it
            source._data.features[featureIndex] = geoJsonFeature;
        } else { 
            // Otherwise, add it
            source._data.features.push(geoJsonFeature);
        }

        if (!skipRedraw) {
            this.featureType.redrawMapFeatures();
        }
    }

    updateMapFeature(skipRedraw = false) {
        const updatedGeoJsonFeature = this.getGeoJsonFeature();
        const source = this.featureType.source;
        const featureIndex = source._data.features.findIndex(_geoJsonFeature => _geoJsonFeature.id === this.featureId);
        if (featureIndex > -1) {
            source._data.features[featureIndex] = updatedGeoJsonFeature;
            if (!skipRedraw) {    
                this.featureType.redrawMapFeatures();
            }
        }
    }

    getGeoJsonFeature() {
        const feature = {
            type: this.type,
            id: this.featureId,
            properties: Object.assign({}, this.properties),
            geometry: this.geometry,
            [TRIMBLE_DATA_KEY]: this.properties[TRIMBLE_DATA_KEY]
        };

        return feature;
    }

    syncAllFeatureAssetProperties(assetProperties = this.asset.properties) {
        this.asset.properties = assetProperties;
        const featureType = this.featureType;
        const styledControls = featureType.attributes.styledControls || [];
        styledControls.forEach(controlLabel => this.syncFeatureToAssetControlProperty(controlLabel));
    }

    syncFeatureToAssetControlProperty(controlLabel, assetProperties = this.asset.properties) {
        const assetPropertyValue = this._convertUnits(controlLabel, assetProperties[controlLabel]);
        const styledControls = this.featureType.attributes.styledControls;

        if (styledControls && styledControls.indexOf(controlLabel) !== -1) {
            if (assetProperties.hasOwnProperty(controlLabel)) {
                this._setValueToFeatureProperties(controlLabel, assetPropertyValue);
                this._resetDimensionsOfIcon(controlLabel, assetPropertyValue);
            } else {
                delete this.properties[controlLabel];
            }
            this.updateMapFeature();
            return true;
        }
    }

    _normalizeFeatureData(_featureData) {
        this.type = 'Feature';
        this.featureId = this.id = _featureData.featureId || _featureData.id || _featureData.properties._id;
        this.featureTypeId = _featureData.featureTypeId || _featureData.properties.featureTypeId;
        this.asset.properties = this.asset.properties || {};

        this.properties = Object.assign(_featureData.properties || {}, this.properties);

        this.properties._id = this.featureId;
        this.properties.featureTypeId = this.featureTypeId;
        this.properties.assetId = _featureData.properties.assetId || this.assetId;

        // Trimble location data is stored at properties[TRIMBLE_DATA_KEY].
        // It's a large blob, so we can trim it down to what we need:
        if (this.properties[TRIMBLE_DATA_KEY]) {
            const _properties = Object.assign({}, this.properties[TRIMBLE_DATA_KEY]);
            this.properties[TRIMBLE_DATA_KEY] = {};
            Object.keys(_properties).forEach(key => {
                this.properties[TRIMBLE_DATA_KEY][key] = {
                    hPrecision: _properties[key].hPrecision,
                    longitude: _properties[key].longitude,
                    latitude: _properties[key].latitude
                };
            });
        }
    }

    _convertUnits(controlLabel, newValue) {
        const unitConversions = appModel.toolbox.featureTypeUnitConversion[this.featureTypeId];
        if (unitConversions && unitConversions[controlLabel]) {
            const conversionFunction = unitConversions[controlLabel];
            newValue = conversionFunction(newValue);
            this.properties[controlLabel] = newValue;
        }
        return newValue;
    }

    _setValueToFeatureProperties(controlLabel, newValue) {
        if (Array.isArray(newValue)) {
            newValue = newValue[newValue.length - 1];
        }
        this.properties[controlLabel] = newValue;
    }

    _resetDimensionsOfIcon(controlLabel, newValue) {
        // If this property change might result in a new icon for the feature,
        // then we need to reset the _sourceWidthPx property
        const featureStyles = appModel.toolbox.featureTypes[this.featureTypeId].featureStyles;

        Object.values(featureStyles).forEach(featureStyle => {

            const style = featureStyle.style,
                iconImage = style.layout && style.layout['icon-image'];

            if (iconImage) {
                if (iconImage[0] === 'match' && iconImage[1][1] === controlLabel) {

                    const currentStopIndex = iconImage.slice(2).findIndex(item => item === newValue),
                        mediaId = currentStopIndex === -1
                            ? iconImage[iconImage.length - 1]
                            : iconImage[currentStopIndex + 3];
                    featureListManager.addImage(mediaId, constants.staticMediaURL + mediaId, this);

                } else if (iconImage.stops && iconImage.property === controlLabel) {
                    const currentStop = iconImage.stops.find(s => s[0] === newValue),
                        mediaId = currentStop ? currentStop[1] : iconImage.default;
                    featureListManager.addImage(mediaId, constants.staticMediaURL + mediaId, this);

                } else if (iconImage[0] === 'get') {
                    let mediaId = this.properties[iconImage[1]];
                    if (mediaId) {
                        if (Array.isArray(mediaId)) {
                            mediaId = mediaId[0];
                        }

                        featureListManager.addImage(mediaId, constants.staticMediaURL + mediaId, this).then(needsRedraw => {
                            if (needsRedraw) {
                                featureListManager.redrawMapFeatures(this.featureTypeId);
                            }
                        });
                    }
                }
            }
        });
    }

    async setupFeatureProperties(feature = this) {
        const featureTypeId = feature.featureTypeId;
        const featureType = appModel.toolbox.featureTypes[featureTypeId];
        const featureStyles = featureType.featureStyles;
        const promises = [];

        feature = featureListManager.addLatitudeToFeature(feature);
        feature.properties = Object.assign({}, featureType.getDefaultProperties(), feature.properties);

        Object.values(featureStyles).forEach(featureStyle => {
            const style = featureStyle.style;
            const iconImage = style && (feature.properties._iconImage || style.layout && style.layout['icon-image']);
            if (iconImage) {
                if (typeof iconImage === 'string') {
                    promises.push(featureListManager.addImage(iconImage, constants.staticMediaURL + iconImage, this));
                } else if (iconImage[0] === 'match') {
                    const propertyName = iconImage[1][1],
                        value = feature.properties[propertyName],
                        currentStopIndex = iconImage.slice(2).findIndex(item => item === value),
                        mediaId = currentStopIndex === -1
                            ? iconImage[iconImage.length - 1]
                            : iconImage[currentStopIndex + 3];

                    promises.push(featureListManager.addImage(mediaId, constants.staticMediaURL + mediaId, this));
                } else if (iconImage.stops) {
                    let mediaId;
                    iconImage.stops.find(stop => {
                        if (feature.properties[iconImage.property] === stop[0]) {
                            mediaId = stop[1];
                            promises.push(featureListManager.addImage(mediaId, constants.staticMediaURL + mediaId, this));
                        }
                        return mediaId;
                    });
                    if (!mediaId && iconImage.default) {
                        mediaId = iconImage.default;
                        promises.push(featureListManager.addImage(mediaId, constants.staticMediaURL + mediaId, this));
                    }
                }
            }
        });

        return Promise.all(promises);
    }

    _onFeatureReady() {
        this._isReady = true;
        this._featureReadyPromises.forEach(resolvePromise => resolvePromise());
        this._featureReadyPromises = [];
    }

    waitUntilFeatureReady() {
        return new Promise(resolve => {
            if (this._isReady) {
                return resolve();
            }
            this._featureReadyPromises.push(resolve);
        });
    }

}

export default FeatureModel;

import store from 'util/data/store';
import constants from 'util/data/constants';
import HeaderHideMessage from 'views/table/header-hide-message';
import message from 'views/toast-message/toast-message';
import api from 'legacy/util/api/api';
import featureListManager from 'managers/feature-list-manager';
import publish from 'legacy/util/api/publish';
import formModel from 'models/form-model';
import deepMerge from 'util/data/deep-merge';
import initializer from 'util/initializer';
import siteModel from 'models/site-model';
import debounce from 'util/events/debounce';
import screenHelper from 'legacy/util/device/screen-helper';
import tween from 'util/animation/tween';
import elementScroll from 'util/dom/element-scroll';
import assetListManager from 'managers/asset-list-manager';
import popupModel from 'models/popover-model';
import activeCell from 'models/table/active-cell-model';
import appModel from 'models/app-model';
import router from 'uav-router';
import { assetIdToProjectId } from 'util/data/asset-id-to-project-id';
import panelModel from 'models/modal-model';
import batchModifyModel from 'models/batch-modify-model';
import SharedColumnModel from 'models/table/shared-column-model';
import Cached from 'util/data/cached';
import TableStylesModel from 'models/table/table-styles-model';
import timeCache from 'util/data/time-cache';
import requestBatches from 'util/network/request-batches/request-batches';
import tableConstants from 'constants/models/table/table-constants';
import filterUtil from 'util/table/filter-util';
import ProjectViewModel from 'models/table/project-view-model';
import savedViewsModel from 'models/saved-views-model';
import popup from 'util/popup';
import peopleModel from 'models/people/people-model';
import { toolIsImageType } from 'util/data/helpers';
import batchSelectModel from 'models/batch-select-model';
import formatDate from 'legacy/util/date/format-date';
import layerModel from 'models/layer-model';
import AssetModel from 'models/asset/asset-model';
import logManager from 'managers/log-manager';

const {
    UNSUPPORTED_CONTROL_TYPES,
    COLUMN_GROUP_HEADERS,
    COMMON_HEADERS,
    PROPERTY_LIST,
    X_SCROLL,
    Y_SCROLL,
    COL_WIDTH,
    WIDTH_CATEGORY,
    WIDTH_LINKS,
    WIDTH_PRIMARY,
    LOAD_PAGE_AT,
    ROW_HEIGHT,
    BUFFER_ROW_COUNT
} = tableConstants;

/**
 * Hide, Show and manage table headers
 */

class TableModel {

    constructor() {
        this.commonHeaders = COMMON_HEADERS;
        this.assetIds = [];
        this.cache = new Cached();
        this.styles = new TableStylesModel();
        this.projectView = new ProjectViewModel();
        this._sharedColumns = {};
        this.hideArrows = true;
        this.setupDebounceEvents();
    }

    /* ----- Set up ----- */

    init() {
        this.reset();
        this.projectView.init();
        if (appModel.project.isMetaProject) {
            this.metaProjectInit();
        }
        this.fetchCounts().then(() => {
            return this.fetchLastViewedProjectView().then(lastViewed => {
                this.initTableHeaders();
                this.projectView.setData(lastViewed);
                if (Object.keys(this.projectView.layerState).length) {
                    layerModel.applySavedViewLayers(Object.assign({}, this.projectView.layerState));
                    // layerModel.onFolderInit(() => layerModel.applySavedViewLayers(Object.assign({}, this.projectView.layerState)));
                }
                if (this.projectView.tableState.tableMode && !screenHelper.small()) {
                    tableModel.setMode(this.projectView.tableState.tableMode, 600, false);
                }
                if (router.params && router.params.projectViewId) {
                    if (router.params.projectViewId === 'RESET') {
                        this.projectView.resetAllFilters();
                        this.initDefaultVisibility();
                    } else {
                        return this.checkProjectViewId(router.params.projectViewId).then(_projectViewData => {
                            if (_projectViewData && _projectViewData.projectViewId) {
                                const projectView = new ProjectViewModel(_projectViewData);
                                savedViewsModel._projectViews[_projectViewData.projectViewId] = projectView;
                                return this.applySavedView(projectView);
                            }
                            router.url.remove('projectViewId');
                        });
                    }
                }
            }).then(() => this.onProjectViewInit(true));
        });
    }

    async onProjectViewInit(firstLoad = false) {
        const tableState = this.projectView.tableState;
        if (this.projectView.tableState.tableMode && !screenHelper.small()) {
            tableModel.setMode(this.projectView.tableState.tableMode, 600, false);
        }

        if (tableState.editModeOn) {
            tableModel.toggleEditMode(true, false);
        }
        appModel.setProjectView(this.projectView);

        await this.fetch(0, false, firstLoad);

        this.checkScrollArrows();
        this.resetCache();
    }

    initTableMode() {
        if (this.projectView && this.projectView.tableState && this.projectView.tableState.tableMode) {
            tableModel.setMode(this.projectView.tableState.tableMode, 0, false);
        }
    }

    get lastViewedProjectViewId() {
        return tableModel.projectView.projectViewId;
    }

    metaProjectInit() {
        // Exclude places header from meta projects.
        this.commonHeaders = this.commonHeaders.filter(id => id !== 'places');
    }

    highlightSidebarButton() {
        this.sidebarButtonClass = 'highlighted';
        setTimeout(() => {
            this.sidebarButtonClass = '';
            m.redraw();
        }, 4000);

    }

    openActiveProjectView() {
        if (this.projectView.isSaved) {
            return savedViewsModel.openSavedViewDetails(this.projectView.savedProjectViewId);
        }
        return savedViewsModel.openSavedViewDetails(this.projectView.projectViewId);
    }

    applySavedView(projectView) {
        const attributes = projectView.getAttributes();
        this.projectView.initFiltersFromAttributes(attributes);
        this.projectView.savedProjectViewId = projectView.projectViewId;
        layerModel.applySavedViewLayers(this.projectView.layerState);
        router.url.mergeReplace({ 'projectViewId': projectView.projectViewId });
        this.projectView.autosave({ retainSavedProjectViewId: true });
        this.onProjectViewInit();
    }

    /**
     * If there is a router-supplied projectViewId, fetches and checks for compatibility.
     * If compatible, returns raw response obj from api. If not, returns undefined.
     */
    checkProjectViewId(projectViewId) {
        return savedViewsModel.fetchById(projectViewId).then(requestedProjectView => {
            if (!requestedProjectView || !requestedProjectView.isVisible) {
                message.show('The requested saved view was not found.', 'warning');
                return;
            }
            const containsProjectId = requestedProjectView.projectIds.items.find(id => id === appModel.project.projectId);
            if (!containsProjectId && requestedProjectView.toolboxId !== store.toolboxId) {
                const siteTerm = appModel.project.isMetaProject ? 'portfolio' : appModel.toolbox.siteTermSingular;
                message.show(`The requested saved view is not compatible with this ${siteTerm}.`, 'warning');
                return;
            }
            if (requestedProjectView.creatorId !== appModel.user.userId) {
                // Its compatible but belongs to another user, apply the saved config like a last-viewed
                this.projectView.initFiltersFromAttributes(requestedProjectView.attributes);
                layerModel.applySavedViewLayers(requestedProjectView.attributes.layerState);
                this.onProjectViewInit();
                return;
            }
            return requestedProjectView;
        });

    }

    fetchLastViewedProjectView() {
        return api.rpc.request([['listProjectViews', {
            creatorId: appModel.user.userId,
            projectId: router.params.projectId,
            platform: 'web',
            type: 'last-viewed',
            limit: 1,
            isVisible: true
        }]]).then((results) => {
            if (results.length === 0) {
                return this.projectView._createLastViewed();
            }
            return results[0];
        });
    }

    fetchCounts() {
        return Promise.all([
            this.fetchAddedByCounts(),
            this.fetchUpdatedByCounts(),
            this.fetchAssetTypeCounts(),
            this.fetchAllAssetsCount()
        ]).then(() => this.calculateCategoryCounts());
    }

    fetchAssetTypeCounts(args = {}) {
        filterUtil.configureProjectAuthFilters(args);

        return api.rpc.request([['countContent', {
            projectId: router.params.projectId,
            siteId: router.params.siteId,
            isVisible: true,
            groupBy: 'assetTypeId',
            filters: [{ assetTypeIdIn: filterUtil.getAssetTypeIdInDefault() }],
            ...args
        }]]).then((results) => {
            this.assetTypeCounts = results;
            m.redraw();
        });
    }

    calculateCategoryCounts() {
        const categoryCounts = {};
        Object.values(appModel.toolbox.toolGroups).forEach(toolGroup => {
            categoryCounts[toolGroup.toolGroupId] = 0;
            const assetTypes = this.getToolGroupAssetTypes(toolGroup.toolGroupId);
            assetTypes.forEach(assetType => {
                const addition = tableModel.assetTypeCounts[assetType.assetTypeId] ? tableModel.assetTypeCounts[assetType.assetTypeId] : 0;
                categoryCounts[toolGroup.toolGroupId] += addition;
            });
        });
        this.categoryCounts = categoryCounts;
        m.redraw();
    }

    getToolGroupAssetTypes(toolGroupId) {
        const toolGroup = appModel.toolbox.toolGroups[toolGroupId];
        let tools = toolGroup.tools;
        // Special case for file asset types that should show on links tab of meta project but not in other tool lists
        if (appModel.project.isMetaProject && appModel.isOnTab('Links') && toolGroup.name === 'Files') {
            tools = Object.values(appModel.toolbox.linkTools);
        }
        return tools.map((tool) => tool.assetForm.assetType);
    }

    fetchAddedByCounts(args = {}) {
        filterUtil.configureProjectAuthFilters(args);

        return api.rpc.request([['countContent', {
            projectId: router.params.projectId,
            siteId: router.params.siteId,
            isVisible: true,
            groupBy: 'authorId',
            filters: [{ assetTypeIdIn: filterUtil.getAssetTypeIdInDefault() }],
            ...args
        }]]).then((resultCounts) => {
            const userIds = Object.keys(resultCounts);
            this.addedByCounts = {};
            for (let i = 0; i < userIds.length; i++) {
                const userId = userIds[i];
                const user = peopleModel.getPerson(userId);
                if (user) {
                    this.addedByCounts[user.userId] = {
                        name: peopleModel.displayName(user),
                        value: user.userId,
                        count: resultCounts[userIds[i]]
                    };
                } else {
                    this.addedByCounts[userId] = {
                        name: 'Unknown User',
                        value: userId,
                        count: resultCounts[userIds[i]]
                    };
                }
                if (!this.projectView.common.checkedUsers.hasOwnProperty(userId)) {
                    this.projectView.common.check('checkedUsers', userId, true);
                }
            }
            return m.redraw();
        });
    }

    fetchUpdatedByCounts(args = {}) {
        filterUtil.configureProjectAuthFilters(args);

        return api.rpc.request([['countContent', {
            projectId: router.params.projectId,
            siteId: router.params.siteId,
            isVisible: true,
            groupBy: 'modifierId',
            filters: [{ assetTypeIdIn: filterUtil.getAssetTypeIdInDefault() }],
            ...args
        }]]).then((resultCounts) => {
            const userIds = Object.keys(resultCounts);
            this.updatedByCounts = {};
            for (let i = 0; i < userIds.length; i++) {
                const userId = userIds[i];
                const user = peopleModel.getPerson(userId);
                if (user) {
                    this.updatedByCounts[user.userId] = {
                        name: peopleModel.displayName(user),
                        value: user.userId,
                        count: resultCounts[userIds[i]]
                    };
                } else {
                    this.updatedByCounts[userId] = {
                        name: 'Unknown User',
                        value: userId,
                        count: resultCounts[userIds[i]]
                    };
                }
                if (!this.projectView.common.checkedUsersUpdatedBy.hasOwnProperty(userId)) {
                    this.projectView.common.check('checkedUsersUpdatedBy', userId, true);
                }
            }
            return m.redraw();
        });
    }

    initTableHeaders() {
        this.tableHeaders = [];
        const tools = Object.values(appModel.toolbox.tools);
        tools.forEach((tool) => {
            const assetForm = tool.assetForm;
            const assetType = assetForm.assetType;
            if (!tool.attributes.hidden && assetType.assetTypeId !== constants.commentAssetTypeId) {
                this.projectView.tableState._visibleHeaders.list[assetType.assetTypeId] = {};
                this.projectView.tableState._visibleHeaders.table[assetType.assetTypeId] = {};
                assetForm.controls.forEach((controlType) => {
                    if (!controlType.attributes.hidden) {
                        // If tag exists, memo it in sharedColumns
                        if (controlType.attributes.sharedColumn) {
                            const sharedColumnName = controlType.attributes.sharedColumn;
                            if (this._sharedColumns[sharedColumnName]) {
                                this._sharedColumns[sharedColumnName].addAssetTypeId(assetType.assetTypeId);
                            } else {
                                this._sharedColumns[sharedColumnName] = new SharedColumnModel(sharedColumnName, assetType.assetTypeId, controlType);
                            }
                            popupModel.disableCloseFor();
                        }

                        if (!UNSUPPORTED_CONTROL_TYPES[controlType.controlTypeId]) {
                            this.tableHeaders.push({
                                controlType,
                                assetType
                            });
                        }
                    }
                });

                if (toolIsImageType({ toolObject: tool })) {
                    const imageCaptureDateTableHeader = {
                        controlType: {
                            name: 'Capture Date',
                            label: 'Capture Date',
                            fieldName: 'Capture Date',
                            attributes: {},
                            controlTypeId: constants.controlTypeNameToId.date
                        },
                        assetType
                    };
                    this.tableHeaders.push(imageCaptureDateTableHeader);
                }
            }
        });
        // Hide category if there is only one toolGroup.
        if (!appModel.toolbox.hasMultipleGroups) {
            this.projectView.tableState._visibleHeaders.list.category = false;
            this.projectView.tableState._visibleHeaders.table.category = false;
        }
    }
    //when returning from the data center, we want to be sure to update the table headers so all columns can be visibile
    //we at this time do not need to worry about shared columns and we also do not want to reinit the entire table/or all table headers since that
    //clears out any current table states
    reinitTableHeaders() {
        this.tableHeaders = [];
        const tools = Object.values(appModel.toolbox.tools);
        tools.forEach((tool) => {
            const assetForm = tool.assetForm;
            const assetType = assetForm.assetType;
            if (!tool.attributes.hidden && assetType.assetTypeId !== constants.commentAssetTypeId) {
                assetForm.controls.forEach((controlType) => {
                    if (!controlType.attributes.hidden) {
                        if (!UNSUPPORTED_CONTROL_TYPES[controlType.controlTypeId]) {
                            this.tableHeaders.push({
                                controlType,
                                assetType
                            });
                        }
                    }
                });
            }
        });
        this.resetCache();
    }
    // Set default common filter visibility, providing set defaults even if new common columns are added after a project view is created
    initDefaultVisibility() {
        this.projectView.tableState._visibleHeaders = {};
        Object.assign(this.projectView.tableState._visibleHeaders, tableConstants.defaultVisibility);
    }

    setupDebounceEvents() {
        this.debounceRedraw = debounce(() => m.redraw(), 40);
        this.debounceHorizontalScroll = debounce(() => {
            if (this.tableMode === 'list-left') {
                return;
            }

            if (this.loadingFresh || isNaN(this.horizontalScrollPercent)) {
                if (!this.hideArrows) {
                    this.hideArrows = true;
                    this.resetTransform();
                    m.redraw();
                }
                return;
            } else if (this.hideArrows === true) {
                m.redraw();
            }

            this.hideArrows = false;
            this.columnGroupHeader = document.querySelector(`.${COLUMN_GROUP_HEADERS}`);
            this.propertyList = document.querySelector(`.${PROPERTY_LIST}`);
        }, 20);

        this.grabNextPage = debounce((e) => {
            if (!this.allContentLoaded && this.verticalScrollRemaining < LOAD_PAGE_AT && e.target && e.target.scrollTop !== 0) {
                this.fetch(this.offset);
            }
        }, 300);

        window.addEventListener('resize', debounce(() => {
            if (screenHelper.small()) {
                this.cache.clear('screen-small');
                this.setMode('list-left', 600, false);
            } else {
                this.cache.clear('screen-small');
                this.updateVisibleCellRange();
            }
        }));
    }

    // Force sync will update current assets in store with asset data retrieved even if they already exist
    async fetch(offset = 0, forceSync = false, firstLoad = false) {
        this.loadingTable = true;
        const args = this.projectView.getArgs(offset);
        if (offset === 0) {
            featureListManager.search();
            this.assetIds = [];
            this.offset = 0;
            this.loadingFresh = true;
            this.allContentLoaded = false;
        } else {
            this.loadingPage = true;
        }

        if (this.cancelFetchRequest) {
            this.cancelFetchRequest();
        }

        if (this.cancelCountRequest) {
            this.cancelCountRequest();
        }

        this.cancelCountRequest = api.rpc.cancelableRequest([['countContent', Object.assign({}, args, {
            limit: undefined,
            offset: undefined,
            order: undefined
        })]], ([results]) => {
            this.cancelCountRequest = undefined;
            this.assetCount = results.count;
            m.redraw();
        });

        // Pause until we receive a full page of results
        requestBatches.pauseTimer('listContent');

        this.cancelFetchRequest = api.rpc.cancelableRequest([['listContent', args]], async ([results]) => {
            this.cancelFetchRequest = undefined;
            this.loadingFresh = this.loadingPage = false;

            results.forEach(asset => {
                assetListManager.addToStore(asset, forceSync);
                this.assetIds.push(asset.contentId);
                requestBatches.clearFromBatch('listContent', asset.contentId, asset);
                return asset.contentId;
            });
            if (results.length < tableConstants.ITEMS_PER_PAGE) {
                this.allContentLoaded = true;
            }
            this.offset = this.assetIds.length;

            // Now send batched requests that piled up while we were getting the page
            await requestBatches.send('listContent');

            this.checkScrollArrows();
            this.resetCache();
            batchSelectModel.removeFilteredOutAssets();
            this.loadingTable = false;

            if (firstLoad) {
                logManager.trackFirstLoad();
            }
            m.redraw();
        });
    }

    fetchAllAssetsCount() {
        const args = {
            isVisible: true,
            projectId: appModel.project.projectId,
            siteId: siteModel.siteId,
            assetTypeIdIn: filterUtil.getAssetTypeIdInDefault()
        };
        filterUtil.configureProjectAuthFilters(args);
        return api.rpc.count('Content', args, false, false).then((results) => {
            this.totalAssetCount = results.count;
            m.redraw();
        });
    }

    reload() {
        activeCell.deactivateCell();
        tableModel.showBatchModifyNotice = false;
        tableModel.fetch(0, true);
    }

    filtersChanged(skipFetch = false) {
        popupModel.disableCloseFor();
        if (tableModel.inEditMode) {
            tableModel.updateVisibleCellRange();
            activeCell.resetState();
        }
        tableModel.projectView.common.removeSearchArea();

        tableModel.projectView.filters.sharedFilterStates = {};
        if (!skipFetch) {
            tableModel.fetch();
        }
        tableModel.projectView.autosave();
        if (skipFetch) {
            this.checkScrollArrows();
            this.resetCache();
        }
        popup.remove();
    }

    /* ----- Resets ----- */

    reset() {
        this.assetIds = [];
        this.modifiedAssetIds = [];

        this.addedByCounts = {}; // aka authors, aka users
        this.updatedByCounts = {}; // aka modifiers, aka users
        this.assetTypeCounts = {};

        this.transition = '';
        this.dismissedClass = '';

        // All available table headers (or columns)
        this.tableHeaders = []; // List of all custom headers related to assetType controls
        this.commonHeaders = COMMON_HEADERS; // List of all common headers (reset this again in case we were routed from meta to nonmeta project)
        this._sharedColumns = {}; // Mapping of combined column names and the assetTypes comprising it.
        this.horizontalScrollPercent = 0;
        this.scrollTop = undefined;
        this.offset = 0;
        this.loadingFresh = true;
        this.loadingTable = true;
        this.showBatchModifyNotice = false;
        this.isCollapsed = false;
        this.loadRange = {
            startRow: 0,
            endRow: BUFFER_ROW_COUNT,
            startCol: 0,
            endCol: document.body.offsetWidth / COL_WIDTH + 1
        };
        if (screenHelper.small()) {
            this.setMode('list-left', 600, false);
            this.sideBarToggle();
        } else {
            this.setMode('table-bottom', 600, false);
        }

        this.cache = new Cached();
        this.styles = new TableStylesModel();
        this.projectView = new ProjectViewModel();

        // Asset row and cell data
        this.assetRows = {};
        this.activeCell = activeCell;
        this.visibleRange = { startRow: 0, endRow: 0, startCol: 0, endCol: 0 };
    }

    resetCache(keepStyles = false) {
        this.cache = new Cached();
        if (!keepStyles) {
            this.resetStyles();
        }
        // Also reset any active cells in case cleared cache invalidates them
        if (this.inEditMode) {
            activeCell.resetState();
        }
    }

    resetStyles() {
        this.styles.resetCache();
        m.redraw();
    }

    async renderWithDefaultFilters() {
        this.loadingFresh = true;
        this.projectView.resetAllFilters();

        await this.fetch(0, true);
        this.showBatchModifyNotice = false;
        this.projectView.autosave();
    }

    /* ----- State ----- */

    get tableMode() {
        return this.cache.get('screen-small', () => screenHelper.small() || siteModel.isInfoPanelOpen()) ? 'list-left' : this.projectView.tableState.tableMode;
    }

    get editModeOn() {
        return !appModel.user.isViewOnly && this.projectView.tableState.editModeOn;
    }

    /**
     * Cleanup after a column visibility change: Keep the popup menu open and  clear the caches, save to projectView
     */
    onColumnChange() {
        this.checkScrollArrows();
        popupModel.disableCloseFor();
        this.onTableConfigChange();
    }

    onTableConfigChange(autosave = true) {
        this.resetCache();
        this.resetTransform();
        if (autosave) {
            this.projectView.autosave();
        }
        m.redraw();
    }

    checkThenRemoveFromTable(assetId) {
        const asset = store.assets[assetId];
        if (!assetId || !asset || !asset.authorId) {
            return;
        }

        if (!this.projectView.doesMatchFilters(assetId)) {
            const index = this.assetIds.indexOf(assetId);
            if (index !== -1) {
                this.assetIds.splice(index, 1);
                delete this.assetRows[assetId];
                this.assetCount--;
                m.redraw();
            }
        }
    }

    removeDeletedAsset(assetId) {
        const asset = store.assets[assetId];
        if (!asset || constants.commentAssetTypeId === asset.assetTypeId) {
            delete store.assets[assetId];
            return;
        }

        this.projectView.onDeletedContent(assetId);
        const index = this.assetIds.indexOf(assetId);

        if (index !== -1) {

            this.assetIds.splice(index, 1);

            delete this.assetRows[assetId];

            if (!appModel.project.isMetaProject || appModel.project.isMetaProject && assetListManager.isProjectAsset(asset)) {

                this.assetCount--;

            }

        }

        if (assetId !== formModel.assetId) {

            delete store.assets[assetId];

            if (!appModel.project.isMetaProject || appModel.project.isMetaProject && assetListManager.isProjectAsset(asset)) {

                this.totalAssetCount--;

            }
            m.redraw();
            this.deleteAssetFeatures(asset.featureIds);

        }

    }

    deleteAssetFeatures(featureIds) {
        const sourcesToRender = {};
        if (featureIds && featureIds.length > 0) {
            featureIds.forEach(featureId => {
                const feature = featureListManager.getById(featureId);
                if (feature) {
                    const featureTypeId = feature.properties.featureTypeId;
                    sourcesToRender[featureTypeId] = featureListManager.removeFeature(feature, sourcesToRender[featureTypeId]);
                }
            });
        }
        Object.values(sourcesToRender).forEach(source => {
            source.setData(source._data);
        });
    }

    addNewAsset(assetId) {
        const index = this.assetIds.findIndex(_assetId => _assetId === assetId);
        if (index === -1) {
            this.assetIds.unshift(assetId);
            if (store.assets[assetId].media) {
                this.assetIds.sort((a, b) =>
                    store.assets[b].createdDateTime.localeCompare(store.assets[a].createdDateTime)
                );
            }
            this.assetCount++;
        }
    }

    viewProject(assetId) {
        const projectId = assetIdToProjectId(assetId);
        if (projectId) {
            // Close open panels (eg the People panel)
            if (panelModel.isOpen) {
                panelModel.close();
            }
            appModel.changeProject(projectId);
        }
    }

    // Call back to execute upon publish of batch modify changes for current project.
    // Given the assetTypeId, check if any assets exist of this type, and if so, display notice to table popups:
    handleBatchModifyResponse(response) {
        batchModifyModel.handleApiResponse(response);

        // Only update if >0 records were updated
        if (response.numberOfRecordsModified) {
            // and if we have >0 assets of that type
            if (this.projectView.getAssetTypeCount(response.assetTypeId)) {
                this.showBatchModifyNotice = true;
                m.redraw();
            }
        }
    }

    /* ----- Handling User Layout/Visibility Updates ----- */

    toggleColumnVisibility(assetTypeId, fieldName) {
        if (this.projectView.tableState._visibleHeaders[this.tableOrList][assetTypeId] === undefined) {
            this.projectView.tableState._visibleHeaders[this.tableOrList][assetTypeId] = {};
        }
        this.projectView.tableState._visibleHeaders[this.tableOrList][assetTypeId][fieldName] = !this.projectView.tableState._visibleHeaders[this.tableOrList][assetTypeId][fieldName];
        this.onColumnChange();
    }

    toggleCommonHeaders(id) {
        this.projectView.tableState._visibleHeaders[this.tableOrList][id] = !this.projectView.tableState._visibleHeaders[this.tableOrList][id];
        this.onColumnChange();
    }

    editAllMode(toMode = !this.editModeOn) {
        this.editingAll = toMode;
    }

    toggleEditMode(toMode = !this.editModeOn, autosave = true) {
        toMode = this.editingAllowed ? toMode : false;
        if (this.editModeOn === toMode) {
            return; // Already in correct mode.
        }
        this.projectView.tableState.editModeOn = toMode;
        if (!this.inEditMode) {
            activeCell.closeCellPopup();
            this.modifiedAssetIds.forEach(assetId => this.checkThenRemoveFromTable(assetId));
            this.modifiedAssetIds = [];
        } else {
            this.resetCache();
            this.updateVisibleCellRange();
            activeCell.resetState();
        }
        if (autosave) {
            this.projectView.autosave();
        }
        m.redraw();
    }

    setMode(mode, transitionTime = 600, autosave = true) {
        this.transition = ` ${this.tableMode}-to-${mode}`;
        this.projectView.tableState.tableMode = mode;
        // Can't edit unless in table view
        this.editingAllowed = !router.params.assetId && (mode === 'table-bottom' || mode === 'table-full');

        popupModel.close();
        this.propertyList = undefined;
        this.columnGroupHeader = undefined;
        if (autosave) {
            this.projectView.autosave();
        }

        const target = document.querySelector(`.${Y_SCROLL}`);
        if (target) {
            target.scrollTop = 0;
        }

        setTimeout(() => {
            this.transition = '';
            m.redraw();
        }, transitionTime);

        if (this.tableMode === 'list-left') {
            this.onTableConfigChange(autosave);
        } else {
            // If we're moving from table-bottom to table-full in edit mode, arrowing through rows/columns needs adjusting
            setTimeout(() => {
                this.updateVisibleCellRange();
                if (activeCell.row >= 0) {
                    this.scrollIfOutOfView(activeCell.row, activeCell.column);
                }
                this.resetCache();
                this.resetTransform();
            }, 100); // Enough time for new table size to take effect
        }
    }

    hideAssetType(assetType, showRevertMessage) {
        const filtersInUse = this.projectView.getInUseFilters(assetType.assetTypeId);
        const inUseHeaders = {};
        let foundInUse = false;
        let columnNameString = '';

        for (const filter of filtersInUse) {
            if (this.visibleHeaders[assetType.assetTypeId][filter.fieldName]) {
                inUseHeaders[filter.fieldName] = true;
                foundInUse = true;
                columnNameString += ` ${filter.fieldName},`;
            }
        }

        if (foundInUse) {
            columnNameString = columnNameString.substring(1, columnNameString.length - 1);
            message.show(`Columns with filters applied (${columnNameString}) cannot be hidden. Reset the filters before hiding.`);
        } else if (showRevertMessage) {
            const copy = Object.assign({}, this.visibleHeaders[assetType.assetTypeId]);
            message.show(<HeaderHideMessage 
                assetType={assetType}
                onClick={() => {
                    this.projectView.tableState._visibleHeaders[this.tableOrList][assetType.assetTypeId] = copy;
                    this.onColumnChange();
                    message.hide();
                }}
            />);
        }
        this.projectView.tableState._visibleHeaders[this.tableOrList][assetType.assetTypeId] = inUseHeaders;
        this.onColumnChange();
    }

    switchAllTableVisibility(controls, assetType, isOn) {
        if (isOn === false) {
            this.hideAssetType(assetType);
            return;
        }
        const assetTypeId = assetType.assetTypeId;
        if (!this.projectView.tableState._visibleHeaders[this.tableOrList][assetTypeId]) {
            this.projectView.tableState._visibleHeaders[this.tableOrList][assetTypeId] = {};
        }
        for (const control of controls) {
            this.projectView.tableState._visibleHeaders[this.tableOrList][assetTypeId][control.fieldName] = isOn;
        }

        // Hide category if there is only one toolGroup.
        if (!appModel.toolbox.hasMultipleGroups) {
            this.projectView.tableState._visibleHeaders.list.category = false;
            this.projectView.tableState._visibleHeaders.table.category = false;
        }
        this.onColumnChange();
    }

    /**
     * Set all columns (custom assetTypes, common, and combined) to the passed value.
     * toValue = boolean
     */
    switchEntireTableVisibility(toValue) {
        // If setting all in table to "on"
        if (toValue) {
            const headers = this.tableHeaders;
            for (const header of headers) {
                const assetTypeId = header.assetType.assetTypeId;
                this.projectView.tableState._visibleHeaders[this.tableOrList][assetTypeId] = this.projectView.tableState._visibleHeaders[this.tableOrList][assetTypeId] || {}; // Make sure this property exists before trying to set its nested property value
                this.projectView.tableState._visibleHeaders[this.tableOrList][header.assetType.assetTypeId][header.controlType.fieldName] = toValue;
            }

            // If setting all in table to "off"
        } else {
            // 1) Update all custom asset type columns
            const assetTypes = Object.values(store.assetTypes);
            assetTypes.forEach((assetType) => {
                this.hideAssetType(assetType);
            });
        }

        // 2) Update all common headers (UnearthID, Category, Type...)
        this.commonHeaders.forEach((name) => {
            this.projectView.tableState._visibleHeaders[this.tableOrList][name] = toValue;
        });
        // 3) Update all shared column headers (ie, "combined columns")
        const sharedColumns = this.getSharedColumnNames();
        sharedColumns.forEach((sharedColumnName) => {
            this.projectView.tableState._visibleHeaders[this.tableOrList][sharedColumnName] = toValue;
        });
        // Hide category if there is only one toolGroup.
        if (!appModel.toolbox.hasMultipleGroups) {
            this.projectView.tableState._visibleHeaders.list.category = false;
            this.projectView.tableState._visibleHeaders.table.category = false;
        }
        this.onColumnChange();
    }

    suggest(query) {
        if (tableModel.isCollapsed) {
            tableModel.sideBarToggle();
        }
        const args = this.projectView.getArgs();
        delete args.limit;
        delete args.order;
        return api.search({
            searchType: 'suggest',
            query,
            filter: args,
            limit: 10
        }).then(data => {
            const suggestions = {};
            // if this.searchString exists, then the user pressed enter
            // while the suggest request was in flight, so we do not
            // want to display the autosuggest results.
            if (data && data.results && !this.projectView.filters.searchString) {
                data.results.forEach(result => {
                    suggestions[result.suggestion] = 1;
                });
            }
            return Object.keys(suggestions);
        });
    }

    /* ----- Recalculating widths & responding to width changes ----- */

    updateVisibleCellRange() {
        if (this.inEditMode) {
            const target = document.querySelector('#app');
            this.visibleRange.middleX = Math.floor(target.clientWidth / 2);
            this.visibleRange.middleY = Math.floor(target.clientHeight / 2);
            this.updateVisibleColumnRange();
            this.updateVisibleRowRange();
        }
    }

    updateVisibleColumnRange() {
        const targetX = document.querySelector(`.${X_SCROLL}`);
        if (targetX) {
            const scrollLeft = targetX.scrollLeft;
            this.visibleRange.startCol = Math.ceil(scrollLeft / COL_WIDTH) + 1;
            this.visibleRange.endCol = Math.floor((targetX.clientWidth + scrollLeft) / COL_WIDTH) - 1;
        }
    }

    updateVisibleRowRange() {
        const targetY = document.querySelector(`.${Y_SCROLL}`);
        if (targetY) {
            // Note: Not all cells have a width of COL_WIDTH. Some are a bit smaller which leaves us with a buffer of extra real cells.
            const scrollTop = tableModel.scrollTop ? tableModel.scrollTop : 0;
            this.visibleRange.startRow = Math.ceil(scrollTop / ROW_HEIGHT);
            this.visibleRange.endRow = Math.floor((targetY.clientHeight + scrollTop) / ROW_HEIGHT) - 1;
        }
    }

    checkScrollArrows() {
        setTimeout(() => {
            const target = document.querySelector(`.${X_SCROLL}`);
            if (!target) {
                return;
            }
            this.onHorizontalScroll({ target });
        }, 15);
    }

    /**
     * Scroll events and scroll methods
     */
    onHorizontalScroll(e) {
        e.redraw = false;
        this.debounceHorizontalScroll(e);
        const target = e.target;
        const scrollLeft = target.scrollLeft;
        const screenWidthCols = document.body.offsetWidth / COL_WIDTH + 1;
        const startCol = Math.floor(target.scrollLeft / COL_WIDTH);
        const width = target.clientWidth;
        const scrollWidth = target.scrollWidth - width;

        this.horizontalScrollPercent = scrollLeft / scrollWidth * 100;
        this.loadRange.startCol = startCol - screenWidthCols;
        this.loadRange.endCol = startCol + screenWidthCols;

        if (this.columnGroupHeader) {
            this.columnGroupHeader.style.cssText = `transform: translateX(-${scrollLeft}px);`;
        }

        if (this.propertyList) {
            this.propertyList.style.cssText = `transform: translateX(-${scrollLeft}px);`;
        }

        this.updateVisibleColumnRange();
        this.debounceRedraw();
    }

    onVerticalScroll(e) {
        e.redraw = false;
        if (e.stopPropagation) {
            e.stopPropagation();
        }
        const target = e.target;
        this.scrollTop = target.scrollTop;
        const height = target.clientHeight;
        const scrollHeight = target.scrollHeight - height;
        this.verticalScrollRemaining = scrollHeight - this.scrollTop;
        const startRow = this.tableMode === 'list-left' ? elementScroll.findFirstVisibleIndex(e) : Math.floor(this.scrollTop / ROW_HEIGHT);
        this.loadRange.startRow = startRow - BUFFER_ROW_COUNT;
        this.loadRange.endRow = startRow + BUFFER_ROW_COUNT;
        this.grabNextPage(e);
        this.updateVisibleRowRange();
        this.debounceRedraw();
    }

    scrollIfOutOfView(row, column) {
        if (this.inEditMode) {
            let scrolled;
            // Determine if vertical scroll is required
            if (this.visibleRange.startRow > row) {
                scrolled = true;
                const targetY = document.querySelector(`.${Y_SCROLL}`);
                // Increment scroll to bring row into view
                targetY.scrollTop -= (this.visibleRange.startRow - row) * ROW_HEIGHT;
            } else if (this.visibleRange.endRow < row) {
                scrolled = true;
                // Reset scroll to make selected row the first visible
                const targetY = document.querySelector(`.${Y_SCROLL}`);
                targetY.scrollTop = row * ROW_HEIGHT;
                // Determine if horizontal scroll is required
            } else if (column > 0) { // First column is fixed, ignore it.
                if (this.visibleRange.startCol > column) {
                    scrolled = true;
                    this.scrollLeft(this.visibleRange.startCol - column);
                } else if (this.visibleRange.endCol < column) {
                    scrolled = true;
                    this.scrollRight(column - this.visibleRange.endCol);
                }
            }
            return scrolled;
        }
    }

    scrollRight(numColumns = 1) {
        const xScrollContainer = document.querySelector(`.${X_SCROLL}`);
        if (xScrollContainer) {
            tween(xScrollContainer, 'scrollLeft', xScrollContainer.scrollLeft, xScrollContainer.scrollLeft + COL_WIDTH * numColumns, 300);
        }
    }

    scrollLeft(numColumns = 1) {
        const xScrollContainer = document.querySelector(`.${X_SCROLL}`);
        if (xScrollContainer) {
            tween(xScrollContainer, 'scrollLeft', xScrollContainer.scrollLeft, xScrollContainer.scrollLeft - COL_WIDTH * numColumns, 300);
        }
    }

    rememberScroll() {
        if (this.scrollTop === undefined) {
            return;
        }
        const xScrollContainer = document.querySelector(`.${Y_SCROLL}`);
        if (xScrollContainer) {
            xScrollContainer.scrollTop = this.scrollTop;
        }
    }

    resetTransform() {
        this.columnGroupHeader = document.querySelector(`.${COLUMN_GROUP_HEADERS}`);
        this.propertyList = document.querySelector(`.${PROPERTY_LIST}`);
        const xScrollContainer = document.querySelector(`.${X_SCROLL}`);

        if (this.columnGroupHeader) {
            this.columnGroupHeader.style.cssText = 'transform: translateX(0);';
        }

        if (this.propertyList) {
            this.propertyList.style.cssText = 'transform: translateX(0);';
        }

        if (xScrollContainer) {
            xScrollContainer.scrollLeft = 0;
        }
    }

    /* -------- Editing Cell Data------------ */

    handleCellClick(e, row, column) {
        if (this.inEditMode) {
            if (e) {
                e.stopPropagation();
            }
            activeCell.onCellClick(row, column);
        }
    }

    /* -------- Helpers -------- */

    getWidthCommon() {
        return this.cache.get('widthCommon', () => {
            let total = WIDTH_PRIMARY + this.getVisibleSharedColumnHeaders().length * COL_WIDTH;
            tableModel.commonHeaders.forEach((header) => {
                if (this.visibleHeaders[header]) {
                    switch (header) {
                    case 'category':
                        total += WIDTH_CATEGORY;
                        break;
                    case 'links':
                        total += WIDTH_LINKS;
                        break;
                    default:
                        total += COL_WIDTH;
                    }

                }
            });
            return total;
        });
    }

    get width() {
        return this.cache.get('width', () => {
            const widthCommon = this.getWidthCommon();
            return this.getVisibleTableHeaders().length * COL_WIDTH + widthCommon;
        });
    }

    get visibleHeaders() {
        return this.cache.get('visibleHeaders', () => this.tableOrList === 'table' ? this.projectView.tableState._visibleHeaders.table : this.projectView.tableState._visibleHeaders.list);
    }

    getVisibleTableHeaders() {
        return this.cache.get('visibleTableHeaders', () => this.tableHeaders.filter(({ assetType, controlType }) => this.visibleHeaders[assetType.assetTypeId] && this.visibleHeaders[assetType.assetTypeId][controlType.fieldName]));
    }

    getVisibleSharedColumnHeaders() {
        return this.cache.get('visibleSharedColumnHeaders', () => Object.keys(this._sharedColumns).filter(sharedColumnName => this.visibleHeaders[sharedColumnName]));
    }

    getVisibleTableHeaderSections() {
        return this.cache.get('visibleTableHeaderSections', () => {
            const headerSections = {};
            this.getVisibleTableHeaders().forEach(({ assetType }) => {
                headerSections[assetType.assetTypeId] = {
                    assetType,
                    count: headerSections[assetType.assetTypeId] ? headerSections[assetType.assetTypeId].count + 1 : 1
                };
            });
            return Object.values(headerSections);
        });
    }

    getSharedColumnNames() {
        return Object.keys(this._sharedColumns);
    }

    getSharedColumnCount() {
        return this.cache.get('sharedColumnCount', () => this.getSharedColumnNames().length);
    }

    getSharedColumn(sharedColumnName) {
        return this._sharedColumns[sharedColumnName];
    }

    /* Used to determine positioning when navigating between cells in edit mode */
    getColumnIndexToCellMap() {
        return this.cache.get('columnIndexToCellMap', () => {
            const headersCommon = this.commonHeaders.filter(header => this.visibleHeaders[header]);
            const headersVisible = Object.keys(this.getVisibleTableHeaders()).map(index => `${this.getVisibleTableHeaders()[index].assetType.assetTypeId}${this.getVisibleTableHeaders()[index].controlType.fieldName}`);
            return ['name', ...headersCommon, ...this.getVisibleSharedColumnHeaders(), ...headersVisible];
        });
    }

    get lastRowIndex() {
        return this.assetCount - 1;
    }

    get lastColumnIndex() {
        return this.getColumnIndexToCellMap().length - 1;
    }

    get tableOrList() {
        return this.cache.get('tableOrList', () => this.tableMode === 'list-left' ? 'list' : 'table');
    }

    get inEditMode() {
        // Users may leave "editModeOn" as their projectView default, but still not be in edit mode
        // (because of screen size, for example)
        return this.editModeOn && this.editingAllowed;
    }

    shouldDisplayCSVImport() {
        return siteModel.canBatchModify;
    }

    sideBarToggle() {
        this.isCollapsed = !this.isCollapsed;
    }

    // Visually hides the table (keeping all data in state, simply hiding from sight)
    dismissTable() {
        tableModel.dismissedClass = ' table-dismissed ';
    }

    // Brings back table from dismissTable call
    recallTable() {
        tableModel.dismissedClass = '';
    }

    // Checks if the table is set as the sidebar but visually hidden (ie dismissed)
    isDismissed() {
        return !!tableModel.dismissedClass;
    }

    getControlTypes(toolId) {
        const tool = appModel.toolbox.tools[toolId];
        if (!tool) {
            return [];
        }
        const placeCount = timeCache(() => Object.values(store.places).length, 'placesLength', 1000);
        const result = tool.assetForm.controls.filter((control) =>
            !UNSUPPORTED_CONTROL_TYPES[control.controlTypeId]
            && !control.attributes.hidden
            && !(control.controlTypeId === constants.controlTypeNameToId.place && placeCount <= 1));

        if (toolIsImageType({ toolObject: tool })) {
            const imageCaptureDateControlType = {
                name: 'Capture Date',
                label: 'Capture Date',
                fieldName: 'Capture Date',
                attributes: {},
                controlTypeId: constants.controlTypeNameToId.date
            };
            result.push(imageCaptureDateControlType);
        }
        return result;
    }

    /* -------- Checking state -------- */

    isColumnVisible(opts) {
        const visibleHeaders = this.visibleHeaders;
        if (opts.sharedColumnName) {
            return visibleHeaders[opts.sharedColumnName];
        }
        return visibleHeaders[opts.assetTypeId] && visibleHeaders[opts.assetTypeId][opts.controlTypeName];
    }

    isEntireTableVisible() {
        let isAllVisible = true;
        let isAllNotVisible = true;
        const headers = this.tableHeaders;
        for (const header of headers) {
            if (this.visibleHeaders[header.assetType.assetTypeId] && this.visibleHeaders[header.assetType.assetTypeId][header.controlType.fieldName]) {
                isAllNotVisible = false;
            } else {
                isAllVisible = false;
            }
        }
        this.commonHeaders.forEach((name) => {
            if (this.visibleHeaders[name]) {
                isAllNotVisible = false;
            } else {
                isAllVisible = false;
            }
        });
        return { isAllVisible, isAllNotVisible };
    }

    isAllVisible(controls, assetType) {
        let isAllVisible = true;
        let isAllNotVisible = true;
        const headers = this.visibleHeaders[assetType.assetTypeId];
        if (!headers) {
            return { isAllVisible: false, isAllNotVisible: true };
        }
        for (const controlType of controls) {
            if (headers[controlType.fieldName]) {
                isAllNotVisible = false;
            } else {
                isAllVisible = false;
            }
        }
        return { isAllVisible, isAllNotVisible };
    }

    /* ----- Publish handlers ----- */

    awaitChanges() {
        publish.await({
            changeType: 'deleted',
            recordType: 'content',
            test: asset => store.assets[asset.contentId],
            callback: asset => this.removeDeletedAsset(asset.contentId),
            persist: true
        });
        publish.await({
            changeType: 'deleted',
            recordType: 'thread',
            callback: thread => {
                Object.values(store.assets).forEach(asset => {

                    const assetId = asset.contentId;

                    if (asset.threadId === thread.threadId && assetId !== formModel.assetId) {
                        this.removeDeletedAsset(assetId);

                        this.deleteAssetFeatures(asset.featureIds);

                    }

                });

                m.redraw();

            },
            persist: true
        });
        publish.await({
            changeType: 'modified',
            recordType: 'content',
            test: asset => store.assets[asset.contentId] && publish.isValidChangedBy(asset),
            callback: asset => {
                const changedByUser = asset.changedBy ? asset.changedBy.userId : null;
                if (this.inEditMode && changedByUser !== appModel.user.userId) {
                    asset.recentlyEdited = true;
                    store.assets[asset.contentId] = new AssetModel(asset);
                    setTimeout(() => {
                        asset.recentlyEdited = false;
                        store.assets[asset.contentId] = new AssetModel(asset);
                        m.redraw();
                    }, 10000);
                } else {
                    store.assets[asset.contentId].updatedDateTime = asset.updatedDateTime;
                    store.assets[asset.contentId].modifierId = asset.modifierId;
                    if (tableModel.assetRows[asset.contentId]) {
                        tableModel.assetRows[asset.contentId].modifier = peopleModel.getPerson(asset.modifierId) || { modifierId: asset.modifierId };
                        tableModel.assetRows[asset.contentId].updatedDate = formatDate.dateAndTime(new Date(asset.updatedDateTime));
                    }
                    m.redraw();
                }

                if (constants.commentAssetTypeId === asset.assetTypeId) {
                    return;
                }

                const assetId = asset.contentId;

                if (formModel.assetId === assetId) {
                    store.assets[assetId].linkIds = asset.linkIds ? [...asset.linkIds] : [];
                    const assetData =  deepMerge(store.assets[assetId], asset);
                    store.assets[assetId] = new AssetModel(assetData);
                } else {
                    assetListManager.addToStore(asset, true);
                }

                if (changedByUser !== appModel.user.userId || formModel.assetId !== assetId) {
                    // Sync any properties to the feature that control the feature's styling
                    asset.featureIds && asset.featureIds.forEach(featureId => {
                        const feature = featureListManager.getById(featureId);
                        if (feature) {
                            feature.syncAllFeatureAssetProperties(asset.properties);
                        }
                    });
                }

                // If this asset is currently being edited by another user, update to the new current value for undoing edits
                if (this.inEditMode && changedByUser !== appModel.user.userId) {
                    if (asset.contentId === activeCell.assetId) {
                        activeCell.saveCellValue();
                        m.redraw();
                    }
                }

                if (!this.inEditMode) {
                    this.checkThenRemoveFromTable(assetId);
                } else {
                    this.modifiedAssetIds.push(assetId);
                }

                m.redraw();
            },
            persist: true
        });
        publish.await({
            changeType: 'new',
            recordType: 'content',
            test: (change) => publish.isValidChangedBy(change),
            callback: asset => {

                if (this.loadingFresh) {
                    return;
                }

                const assetTypeId = asset.assetTypeId;
                const assetId = asset.contentId;

                if (constants.commentAssetTypeId === assetTypeId || this.assetIds.includes(assetId)) {
                    return;
                }

                if (appModel.user.permissions.hiddenAssetTypes[asset.assetTypeId]) {
                    return;
                }

                if (!appModel.project.isMetaProject || appModel.project.isMetaProject && assetListManager.isProjectAsset(asset)) {
                    this.totalAssetCount++;
                }

                if (formModel.assetId === assetId || assetListManager.hasUnsavedChanges[assetId]) {
                    // In this case the updatedDateTime stamp doesn't help us for LWW because the asset
                    // returned from the back end on a "new" content publish, and we have unsaved "modify" 
                    // changes in memory that were waiting for it to be created before sending.
                    // So, merge:
                    asset = deepMerge(store.assets[assetId], asset);
                }
                assetListManager.addToStore(asset, true);
                this.projectView.onNewContent(assetId);

                if (this.projectView.doesMatchFilters(assetId)) {
                    this.addNewAsset(assetId);
                }

                m.redraw();

            },
            persist: true
        });

        publish.await({
            changeType: 'batchModify',
            recordType: 'content',
            test: (change) => change.projectId === router.params.projectId,
            callback: (response) => this.handleBatchModifyResponse(response)
        });
    }


}

const tableModel = new TableModel();

initializer.add(() => tableModel.reset(), 'tableModel');
initializer.addSiteCallback(() => tableModel.init(), 'tableModel');

export default tableModel;

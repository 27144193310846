import router from 'uav-router';
import initializer from 'util/initializer';
import userManager from 'managers/user-manager';
import accountManager from 'managers/account-manager';
import projectManager from 'managers/project-manager';
import toolboxManager from 'managers/toolbox-manager';
import peopleModel from 'models/people/people-model';
import store from 'util/data/store';
import loaderModel from 'models/loader-model';
import siteModel from 'models/site-model';
import Table from 'views/table/table';
import formModel from 'models/form-model';
import AssetForm from 'views/asset-form';
import uploadHelp from 'flows/upload-help/upload-help-flow';
import {whiteLabelAccount} from 'util/data/env';
import { deUrnify } from 'util/network/urnify';
import layerModel from 'models/layer-model';
import mediaListManager from 'managers/media-list-manager';
import featureListManager from 'managers/feature-list-manager';
import MapPopup from 'views/popups/map-popup';
import mediaViewerModel from 'models/media-viewer-model';
import authManager from 'managers/auth-manager';
import Survey from 'views/survey';
import Plan from 'views/plan';
import logManager from 'managers/log-manager';

const IS_DRAWING = 'isDrawing';
const PAGE_TITLE = 'PAGE_TITLE';
const DOCUMENT_TITLE = 'DOCUMENT_TITLE';
const DISMISSED_CLASS = 'DISMISSED_CLASS';

/*
 * Globally accessible app-wide state & logic
 */
const appModel = {
    view: '',
    state: {
        [DOCUMENT_TITLE]: '',
        [PAGE_TITLE]: '',
        [DISMISSED_CLASS]: ''
    },
    user: userManager,
    account: accountManager,
    project: projectManager,
    toolbox: toolboxManager,
    commonFilterModel: undefined,
    projectView: undefined,
    _inPreviewMode: null, // Use inPreviewMode() getter to retrieve value
    afterInitPromises: [], // Stores promises for callers of appModel.waitUntilInit();
    flows: {uploadHelp},

    async init(userId = authManager.userId, opts = {}) {
        logManager.accountLoadStartTime = performance.now();
        if (userId && userManager.userId === userId && !opts.force) {
            // Already initted, quit early
            return Promise.resolve();
        }
        initializer.runAll();
        loaderModel.load();
        return userManager.init(userId).then(() =>
            accountManager.init().then(() => {
                userManager.loadAccountUser(); // Load logged in account user's data
                peopleModel.loadAccountPeople(); // Load other account users' data
                if (accountManager.isInvalid) {
                    loaderModel.hide();
                    return Promise.resolve();
                }
                projectManager.init()
                    .then(() => toolboxManager.init())  // Load project data
                    .then(() => siteModel.init())
                    .then(() => this.markAsInitted());
            })
        );
    },

    renderNoInit(view) {
        appModel.view = view;
        m.redraw();
    },

    render(view) {
        // Changing from site -> staking flow (or vice-versa), requires a forced re-init of the project
        const onStakingView = appModel.view === Survey || appModel.view === Plan;
        const goingToStakingView = view === Survey || view === Plan;
        const forceProjectInit = appModel.view ? (onStakingView || goingToStakingView) && view !== appModel.view : false;
        
        mediaViewerModel.close();

        this.conditionalInit({project: forceProjectInit});
        appModel.view = view;
        if (router.params.assetId) {
            // If assetId param is present, show the asset form
            formModel.viewAsset(router.params.assetId);
        } else if (siteModel.sidebar === AssetForm) {
            // If not present, but asset form is still showing, close it properly
            // This can happen if user presses browser "back" btn, for ex.
            formModel.close();
        } else {
            // If not present, make sure table.js is showing
            siteModel.sidebar = Table;
        }
        m.redraw();
    },

    // If edge case, full app init may be required, (ie, did a user arrive here by changing
    // the URL to that of a different account, without triggering a new session?)
    conditionalInit(force = {}) {
        // If no user was found, return (to log in).
        if (force.user || !userManager.userId) {
            return;
        }
        // If account was router-specified, check if it is currently loaded
        if (force.account || router.params.accountId && router.params.accountId !== accountManager.accountId) {
            return this.init(appModel.user.userId, {force: true});
        }
        // If project was router-specified, check if it is the project currently loaded. If not provided, need to init.
        const projectIdMismatch = router.params.projectId ? router.params.projectId !== projectManager.projectId : true;
        if (force.project || !projectManager.calledInit || projectIdMismatch) {
            let accountIsInitted = true;
            if (!store || !store.account) {
                accountIsInitted = false;
            } else {
                const accountProjectIds = store.account.projectIds || [];
                accountIsInitted = accountProjectIds.find(projectId =>
                    projectId === router.params.projectId);
            }
            if (!accountIsInitted) {
                return this.init(appModel.user.userId, {force: true});// Need to init the account and project
            }
            return this.initProject(router.params.projectId); // Just need to init the project
        }
    },

    initProject(projectId, initToolbox = true) {
        initializer.run();
        loaderModel.load();
        return projectManager.init(projectId)
            .then(() => initToolbox ? toolboxManager.init() : '')
            .then(() => siteModel.init())
            .then(() => appModel.markAsInitted());
    },

    resetAllState() {
        Object.assign(appModel.state, {
            isInitted: false,
            [IS_DRAWING]: false, // Notes if currently drawing on the map, to prevent other click events
            isActiveInOneUp: false, // Tracks if one up uploader is active (ie, css not set to hidden and connected)
            editingProjectId: '' // state.editingProjectId may be a meta project or a child project
        });
        Object.preventExtensions(appModel.state);
    },

    getState(key) {
        return appModel.state[key];
    },

    setState(key, value) {
        appModel.state[key] = value;
        m.redraw();
    },

    /**
     * Determines if user is currently viewing the tab of the name passed, ie "Links"
     */
    isOnTab(name) {
        return router.params.tab === name;
    },

    /* ----- App & Manager Initialization ----- */

    /**
     * Runs the initializations required to change accounts to the provided account object.
     * @param {*} account object - account object as selected from account-selector.js.
     */
    changeAccount(account) {
        initializer.initManager('account');
        appModel.project.setIsMetaProject(true);
        loaderModel.load();
        accountManager.select(account);
        peopleModel.loadAccountPeople(); // Load other account users' data
        projectManager.init()
            .then(() => toolboxManager.init())  // Load project data
            .then(() => siteModel.init())
            .then(() => this.markAsInitted());
    },

    /**
     * Runs the initializations required to change projects to the provided id. Assumes the projectId belongs to the current account.
     * @param {*} projectId string - xid of the project to change to.
     * @param {*} routerParams obj - additional (optional) parameters to include routing.
     */
    changeProject(projectId, opts = {}) {
        MapPopup.remove();
        const routerParams = opts.routerParams || {};
        router.set({projectId, ...routerParams});
    },

    /*
    * Called once all required inits have completed to release any async functions waiting.
    */
    markAsInitted() {
        appModel.state.isInitted = true;
        this.afterInitPromises.forEach(resolvePromise => resolvePromise());
        this.afterInitPromises = [];
        loaderModel.hide();
    },

    /*
    * A promise that will resolve after all required initializations
    */
    waitUntilInit() {
        return new Promise(resolve => {
            if (appModel.state.isInitted) {
                return resolve();
            }
            this.afterInitPromises.push(resolve);
        });
    },

    /* ----- Changing active project view ----- */

    // Primarily we are concerned with the projectView hooked to the table. However, filters and visibility configs may be in place elsewhere, such as on the links tab of the asset form.
    // For shared use cases (like reusing filter menu views), refer to this pointer to retrieve the one currently "active".
    setProjectView(projectView) {
        appModel.projectView = projectView;
    },

    /* ----- Drawing state ----- */

    startDrawing() {
        this.setState(IS_DRAWING, true);
    },

    stopDrawing() {
        this.setState(IS_DRAWING, false);
    },

    get isDrawing() {
        return this.getState(IS_DRAWING);
    },

    get inPreviewMode() {
        if (appModel._inPreviewMode === null) {
            appModel._inPreviewMode = !!router.params.preview;
        }
        return appModel._inPreviewMode;
    },

    setDocumentTitle(title = '') {
        if (!title) {
            const projectName = this.isMetaProject ? store.account.name : appModel.project.projectName;
            title = projectName ? `${projectName} | ${whiteLabelAccount || accountManager.isWhiteLabelAccount ? accountManager.whiteLabelData.companyName : 'Unearth'}` : `${whiteLabelAccount || accountManager.isWhiteLabelAccount ? accountManager.whiteLabelData.companyName : 'Unearth'}`;
        }
        this.setState(DOCUMENT_TITLE, title);
        document.title = title;
    },

    setPageTitle(title = '') {
        this.setState(PAGE_TITLE, title);
        const optionalPageTitle = this.state.PAGE_TITLE ? `${this.state.PAGE_TITLE} | ` : '';
        document.title = `${optionalPageTitle}${this.state.DOCUMENT_TITLE}`;
    },

    getByUrn(urn) {
        const {nid, nss} = deUrnify(urn);
        switch (nid) {
        case 'folder':
            return layerModel.folders.getByUrn(urn);
        case 'media':
            return mediaListManager.getById(nss);
        case 'feature':
            return featureListManager.getById(nss);
        default:
            return store[nid + 's'][nss];
        }
    }


};

window.state = appModel.state;
initializer.add(() => appModel.resetAllState(), 'appModel');

export default appModel;

import layerModel from 'models/layer-model';
import LayerPicker from 'views/layer-picker';
import stakeableModel from 'models/stakeable-model';
import planModel from 'models/plan-model';
import appModel from 'models/app-model';

// Navigate back to site bounds
const ReturnToSiteButton = {
    view: () =>  {
        return <div class="button-container-centered">
            <button class="btn btn-pill btn-small btn-not-primary btn-return-to-project" type="submit" onclick={() => stakeableModel.boundTargetByStakes()}><span class="btn-label">Return to Project</span></button>
        </div>;
    }
};

// Left maplibre container
const LeftMap = {
    view: () =>  <div class='splitview-left stake-view basemap'>
        {stakeableModel.state.isOutOfBounds ? <ReturnToSiteButton /> : null}
        {!planModel.isReviewing && layerModel.isOpen ? <LayerPicker hideLinks={true}/> : null}
        <div id="maplibre" class="maplibre">
        </div>
    </div>
};

// Right maplibre container
const RightMap = {
    view: () => <div class='splitview-right stake-view basemap'>
        {stakeableModel.stakesMenu && stakeableModel.stakesMenu.isOpen ? <StakesMenu /> : null}
        <div id="maplibreRight" class="maplibre">
        </div>
    </div>
};

// Control menu appearing on right maplibre object with list of previously pinned surveys/plans
const StakesMenu = {
    oninit: ({state}) => {
        state.headText = stakeableModel.recordType === 'plan' ? 'Map Layer' : 'Surveys';
        state.iconClass = stakeableModel.recordType === 'plan' ? 'icon-marker2' : 'icon-marker2';
        state.type = stakeableModel.recordType === 'plan' ? 'Map Layer' : 'Survey Layer';
    },
    view: ({state}) =>
        <div class="pins-picker">
            <div class="pins-picker-head">
                <i class={state.iconClass}></i>
                <span class="head-text-inner-wrap">
                    <span class="head-subtitle">Select an existing {state.type} to use its Match Points.</span>
                </span>
            </div>
            <div class="pins-picker-layers">
                <ul class="pins-picker-select">
                    { stakeableModel.stakesMenuList.map((menuOption, index) =>
                        <li class="option" key={index}><button type="submit" onclick={() => stakeableModel.handleAddingPreviousPins(index)} value={index}>{menuOption.title}</button></li>
                    )}
                </ul>
            </div>
        </div>

};


class Stakeable {

    onremove() {
        // Clear data from model on remove.
        stakeableModel.stakes = null;
        stakeableModel.stakesMenuList = [];
        appModel.setPageTitle();
    }

    view() {
        return <div className="splitview split">
            <LeftMap/>
            <RightMap/>
        </div>;
    }

}

export default Stakeable;

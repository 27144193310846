import router from 'uav-router';
import api from 'legacy/util/api';
import store from 'util/data/store';
import publish from 'legacy/util/api/publish';
import message from 'views/toast-message/toast-message';
import dialogModel from 'models/dialog-model';
import layerModel from 'models/layer-model';
import siteModel from 'models/site-model';
import layerColorModel from 'models/layer-color-model';
import stakeableModel from 'models/stakeable-model';
import StakeStatic from 'components/stake/stake-static';
import {LngLat} from 'maplibre-gl';
import lngLatsToBounds from '../util/geo/lnglats-to-bounds';
import latLngsToLngLats from '../util/geo/latlngs-to-lnglats';
import helpers from 'legacy/util/api/helpers';
import loaderModel from 'models/loader-model';
import appModel from 'models/app-model';
import featureToControl from 'util/interfaces/feature-to-control';
import {SIDEBAR_WIDTH, TABLE_HEIGHT} from 'models/map-model';
import tableModel from 'models/table/table-model';
import mediaListManager from 'managers/media-list-manager';
import { urnify } from 'util/network/urnify';
import StakeList from 'components/stake/stake-list';
import formModel from 'models/form-model';
import modalModel from 'models/modal-model';

const LAT_INDEX_API = 0;   // Unearth's API stores: lat, lng
const LNG_INDEX_API = 1;  //         maplibre stores: lng, lat
/**
 * Handles logic and state for plan layer editing view.
 * (/views/plan/plan.js
 */
class PlanModel {

    constructor() {
        this.reviewAnimateClass = 'fade-in';
        this.cropTileset = null;
        this.stakeTileset = null;
        this.pages = {};
        this.sortedPages = [];
        this.stakes = [];
    }

    init() {
        loaderModel.load('Loading Map Layer ...');
        this.recordId = router.params.planId;
        return stakeableModel.init({
            storeKey: 'plans',
            recordType: 'plan',
            recordId: this.recordId,
            withElevation: false,
            lightOriginBg: true
        }).then(() => {
            const plan = this.plan = store.plans[this.recordId];
            const tileset = plan && plan.tilesetId ? store.tilesets[plan.tilesetId] : undefined;
            if (!router.params || !router.params.planId || !this.supportsStaking(tileset)) {
                return stakeableModel.exit();
            }
            this.recordTitle = stakeableModel.recordTitle = plan ? plan.title : router.params.title || 'New Plan';
            appModel.setPageTitle(this.recordTitle);
            this.stakeInit().then(() => {
                if (router.params.step === 'review' && store.plans[this.recordId].stakes) {
                    this.reviewInit();
                }
            }).finally(() => loaderModel.hide());
        });
    }

    openLayerMenu(plan) {
        if (!layerModel.isOpen) {
            layerModel.togglePicker();
            m.redraw();
        }
        if (plan) {
            const urn = urnify('plan', plan.planId);
            const folderItem = layerModel.folders.getByUrn(urn);
            if (folderItem) {
                folderItem.bringIntoFocus();
            }
        }
    }

    // ------------------------- Initialize for the current step --------------------------

    /**
     * Initialize for "staking" step.
     * Sets up the staking view with the plan being edited, stakes, and initial map bounds.
     */
    stakeInit() {
        return stakeableModel.origin.mapLoaded().then(() => {
            return this.getTilesets().then(() => {
                stakeableModel.origin.setUpEditingLayer(this.stakeTileset, 'None');
                stakeableModel.origin.centerOnTileset(this.stakeTileset);
                this.preloadSavedStakes(this.getInitialBounds());
                stakeableModel.state.isLoading = false;
                layerModel.hideAllLayers();
                layerModel.showPlan(this.plan);
                stakeableModel.toggleTranslate();
                m.redraw();
            });
        });
    }

    preloadSavedStakes(initialBounds) {
        // Set stakes from store:
        if (this.plan.isRigid) {
            // Set starter stakes from corners
            stakeableModel.stakes = new StakeList();
            
            const polygonPoints = [];
            this.plan.corners.forEach(corner => polygonPoints.push(corner));
            const bounds = lngLatsToBounds(latLngsToLngLats(this.stakeTileset.bounds));
    
            const nw = bounds.getNorthWest();    
            const ne = bounds.getNorthEast();
            const se = bounds.getSouthEast();
            const sw = bounds.getSouthWest();

            const initialCoords = [
                stakeableModel.coordsToStakeApi({lng: polygonPoints[0][0], lat: polygonPoints[0][1]}, nw),
                stakeableModel.coordsToStakeApi({lng: polygonPoints[1][0], lat: polygonPoints[1][1]}, ne),
                stakeableModel.coordsToStakeApi({lng: polygonPoints[2][0], lat: polygonPoints[2][1]}, se),
                stakeableModel.coordsToStakeApi({lng: polygonPoints[3][0], lat: polygonPoints[3][1]}, sw)
            ];
    
            stakeableModel.stakes.initStakes(initialCoords);
            stakeableModel.stakes.saveStep();
            stakeableModel.updateButtonStates();
            stakeableModel.setInitialBounds(initialBounds);
            stakeableModel.loadStakesMenu();
        } else {
            return stakeableModel.preloadSavedStakes(initialBounds);
        }

    }


    /**
     * Initialize for "review" step.
     * Repurpose the layer control to work with our review maplibre object.
     */
    reviewInit() {
        this.isReviewing = true;
        layerModel.isOpen = false; // Make sure layer menu is closed in case it was open on prior screen.
        layerModel.layerControl._container.classList.remove('active');
        siteModel.map.removeControl(layerModel.layerControl);
        m.redraw();
    }

    /**
     * Initialize the layerColorModel for the "review" step and load static stakes on the map.
     */
    colorInit() {
        layerColorModel.init({
            planId: this.recordId,
            tilesetId: store.plans[this.recordId].tilesetId,
            container: 'maplibre-review',
            basemap: this.currentBasemap
        }).then(() => this.loadStaticStakes());
    }

    /**
     * Reassigns the layer control to the target map (left side) for staking.
     */
    stakeReinit() {
        layerModel.hideAllLayers();
        siteModel.map = stakeableModel.target;
        siteModel.map.addControl(layerModel.layerControl, 'bottom-right');
        this.currentBasemap ? layerModel.setBasemap(this.currentBasemap) : null; // Maintain the basemap in use on the review step, in case it was changed.
    }

    // ------------------------- Loading saved stakes --------------------------

    updateAllPositions(delta, position) {
        this.stakes.forEach(stake => {
            if (stake.position !== position) {
                const stakePairStake = stakeableModel.stakes.stakes[position].targetStake;
                const newPos = {lng: stake._lngLat.lng - delta.lng, lat: stake._lngLat.lat - delta.lat};
                stake.setLngLat(newPos);
                stakePairStake.setLngLat(newPos);
                stake.originCoords = stakePairStake.originCoords = newPos;
            } else {
                const stakePairStake = stakeableModel.stakes.stakes[position].targetStake;
                stakePairStake.setLngLat(stake._lngLat);
                stakePairStake.originCoords = stake._lngLat;
            }
        });
    }

    /**
     * Create the non-draggable stakes for the plan being reviewed.
     */
    loadStaticStakes() {
        const data = store.plans[this.recordId].stakes;
        const bounds = this.getInitialBounds();
        data.forEach((pair, index) => {
            this.stakes.push(new StakeStatic({
                coords: new LngLat(pair.target[LNG_INDEX_API], pair.target[LAT_INDEX_API]),
                stakeNumber: index + 1,
                map: layerColorModel.map
            }));
            // Extend site bounds to include stakes.
            bounds.extend(new LngLat(pair.target[LNG_INDEX_API], pair.target[LAT_INDEX_API]));
        });
        layerColorModel.map.fitBounds(bounds,  {
            animate: false,
            padding: {top: 50, bottom: 50, left: 50, right: 50}
        });
    }

    /**
     * If the plan is assembled, gets the initial bounds for it.
     * If not assembled or bounds don't exist, return siteModel map bounds.
     */
    getInitialBounds() {
        if (store.plans[this.recordId].status !== 'assembling') {
            const tilesetId = store.plans[this.recordId].tilesetId;
            const bounds = store.tilesets[tilesetId].bounds;
            if (bounds) {
                return lngLatsToBounds(latLngsToLngLats(bounds));
            }
        }
        return siteModel.map.getBounds();
    }

    // ------------------------- Navigating between steps (crop, stake, and review) --------------------------

    /**
     * Opens the cropping step map from the stake step.
     */
    goToCropStep() {
        this.isCropping = true;
        m.redraw();
    }

    /**
     * Opens the reviewing step map from the stake step.
     */
    goToReviewStep() {
        layerModel.hideAllLayers();
        router.url.mergeReplace({step: 'review'});
        this.currentBasemap = layerModel.state.basemapId;
        this.reviewInit();
    }

    /**
     * Returns to the staking step from the review step.
     */
    goToStakeStep() {
        this.reviewAnimateClass = 'fade-body-out';
        this.currentBasemap = layerModel.state.basemapId;
        this.stakeReinit();
        setTimeout(() => {
            this.isReviewing = false;
            this.reviewAnimateClass = 'fade-in';
            router.url.mergeReplace({step: 'stake'});
            m.redraw();
        }, 500);
    }


    /**
     * From the staking step, checks if there are changes to submit
     * (and if so, sends them) before routing to the review step.
     */
    nextFromStaking() {
        if (stakeableModel.state.isUndoable) { // Means there are staking changes to submit.
            dialogModel.open({
                headline: 'Ready to Save?',
                text: 'It may take a few minutes to align your layer; we’ll email you when it’s ready for review. ' +
                    'Leaving this page won’t affect processing.',
                yesText: 'Save',
                yesClass: 'btn btn-pill btn-primary',
                noText: 'Cancel',
                noClass: 'btn btn-pill btn-red',
                onYes: () => {
                    this.savePins(stakeableModel.stakes.getStakeData());
                }
            });
        } else {
            // No unsaved staking changes to submit, just move on to next step:
            this.goToReviewStep();
        }
    }

    /**
     * Returns to the site view from the review step.
     */
    exitFromReviewing() {
        this.isReviewing = false;
        stakeableModel.exit();
        const plan = store.plans[this.recordId];
        layerModel.onFolderInit(() => this.openLayerMenu(plan));
    }
    
    /**
     * Returns to the site view from the staking step.
     */
    exitFromStaking() {
        stakeableModel.exit();
    }

    /**
     * Sends the current stake/pin data to the API and routes to the review step.
     */
    savePins(data) {
        const planId = this.recordId;
        const plan = store.plans[planId];
        const tilesetId = plan.tilesetId;
        message.show(<span><i class="spinner spinning"></i><span>It may take a few minutes to align your layer; we'll email you when it's ready for review. Leaving this page won't affect processing.</span></span>, 'success', true);
        publish.clearCallbacks('modified', 'tileset');
        publish.await({
            changeType: 'modified',
            recordType: 'tileset',
            test: change => change.geoImageIds && change.tilesetId === tilesetId,
            callback: () => {
                message.hide();
                stakeableModel.stakes.clearHistory();
                this.goToReviewStep();
            }
        });
        api.post.planStakes(planId, data);
    }

    savePlanName(planId, newName) {
        return api.rpc.modify('Plan', {
            planId,
            title: newName
        }).then(() => store.updateImmutableProp('plans', planId, 'title', newName));
    }


    /**
     * Get (and set) the special plan tilesets for cropping and staking.
     */
    async getTilesets() {
        let plan = store.plans[this.recordId];
        // edge case for async/delays w/newly created plans 
        if (!plan) {
            await api.rpc.get('Plan', this.recordId).then((_plan) => {
                plan = _plan;
                store.plans[_plan.planId] = _plan;
            });
        }
        const planSections = helpers.list(plan.sectionIds);
        const planSectionId = planSections[0];
        const tilesetId = plan.tilesetId;
        return api.rpc.get('Tileset', tilesetId)
            .then(tileset => api.get.tileset(tileset.baseTilesetId)
                .then(baseTileset => {
                    this.stakeTileset = baseTileset;
                })
                .then(() => api.get.planSection(planSectionId))
                .then(planSection => api.get.tileset(planSection.cropBaseTilesetId))
                .then(cropBaseTileset => {
                    this.cropTileset = cropBaseTileset;
                })
                .catch(() => message.show('Something went wrong. Please try refreshing or contact support.', 'error')));
    }

    /**
     * Tear down maplibre resources on exit.
     */
    onRemove() {
        if (stakeableModel.origin) {
            stakeableModel.origin.remove();
        }
        this.isReviewing = this.isCropping = false;
        appModel.project.isInitted = false;
    }

    supportsColorPicker(tileset) {
        return tileset && (tileset.size || tileset.tilesetType === 'external_vector');
    }

    // If the tileset already exists and is an external vector, it's not stakeable via the api.
    // If the tileset doesnt exist yet, we can create one from the image via staking
    // If the tileset does exist but is of a different type (ie, 'base', or 'elevation'), we create another projection of it via staking
    supportsStaking(tileset) {
        return !tileset || tileset.size && tileset.tilesetType !== 'external_vector';
    }

    async centerOnMap(planId) {
        const plan = store.plans[planId];
        const tileset = store.tilesets[plan ? plan.tilesetId : undefined];
        if (!plan || !tileset) {
            return;
        }
        const isShowing = layerModel.state.planTilesetIds.has(plan.tilesetId);
        if (!isShowing) {
            layerModel.showPlan(plan);
        }
        const bounds = latLngsToLngLats(tileset.bounds);
        let padding = {top: 20, bottom: TABLE_HEIGHT + 20, left: 20, right: 20};
        if (!siteModel.isTableActive() || tableModel.tableMode === 'list-left') {
            padding = {top: 20, bottom: 20, left: SIDEBAR_WIDTH + 20, right: 20};
        }
        siteModel.map.safeFitBounds(bounds, {padding});
    }

    /**
     * Used for multi-layer kml files — the BE will generate multiple plan layers from a single file. No staking, these are already geolocated.
     */
    async createLayers(mediaId, assetId) {
        const asset = store.assets[assetId];
        const media = await mediaListManager.getMediaAsync(mediaId);
        let awaitingNewFolderId = undefined;
        let layersResponse = undefined;
        const assetTypeName = asset ? store.assetTypes[asset.assetTypeId].name : 'Layer';

        const promise = new Promise(resolve => {
            publish.await({
                changeType: 'modified',
                recordType: 'layerFolder',
                persist: false,
                test: _layerFolder => awaitingNewFolderId === _layerFolder.folderId,
                callback: () => {
                    formModel.viewAsset(assetId, 'Properties', true);
                    message.show(`New ${assetTypeName} added.`);
                    modalModel.close();
                    resolve();
                }
            });
        });
        
        layersResponse = await api.rpc.request([['createLayers', {projectId: router.params.projectId, mediaId}]]);
        awaitingNewFolderId = layersResponse.parent;   
        layerModel.awaitingLayers[awaitingNewFolderId] = true;   
        layersResponse.plans.forEach(id => {
            layerModel.awaitingLayers[id] = true;
        });
        const tool = appModel.toolbox.tools[asset.attributes.toolId],
            featureType = tool.featureTypes.find(f => f.attributes.interface === 'plan');
        if (layersResponse && layersResponse.plans) {
            // Make them the same format the syncing functions are expecting, as per the non-kml plan flows
            featureToControl.syncPlanLayersAndFolder(layersResponse.plans, media.label, asset.contentId, featureType);
        }
        return promise;
    }


    handleDeletePlan(planId) {
        return dialogModel.open({
            headline: 'Delete this Layer?',
            text: 'Please note that this operation cannot be undone.',
            yesText: 'Delete',
            yesClass: 'btn btn-pill btn-red',
            noText: 'Cancel',
            noClass: 'btn btn-pill btn-secondary',
            onYes: () => {
                this.deletePlan(planId);
            }
        });
    }
    
    deletePlan(planId) {
        const plan = store.plans[planId];
        const requests = [
            ['deletePlan', {planId}]
        ];
        if (plan && plan.tilesetId) {
            requests.push(['deleteTileset', {tilesetId: plan.tilesetId}]);
        }

        return api.rpc.requests(requests);
    }

    /**
     * If our stakeable plan tileset is cropped, we should reset it on the map with the new (cropped)
     * version and adjust the max bounds to account for the modified size.
     */
    awaitCropChanges() {
        publish.clearCallbacks('modified', ['tileset', 'planSection']);
        publish.await({
            changeType: 'modified',
            recordType: 'planSection',
            callback: tileset => {
                if (this.recordId === tileset.planId) {
                    api.get.tileset(tileset.baseTilesetId).then((baseTileset) => {
                        const originalLayer = stakeableModel.origin.getStyle().layers[0];
                        originalLayer ? stakeableModel.origin.removeLayer(originalLayer.id) : null;
                        const initialBounds = stakeableModel.origin.getBounds();
                        stakeableModel.origin.setUpEditingLayer(baseTileset);
                        stakeableModel.boundOriginByStakes(initialBounds);
                    });
                }
            }
        });
    }

} 


export default new PlanModel();

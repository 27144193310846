export default {
    password: {
        regex: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([\S]{12,})$/,
        errorMsg: 'Please use at least 12 characters, 1 number, 1 uppercase letter, and no spaces.'
    },
    email: {
        regex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        errorMsg: 'Please enter a valid email address.'
    },
    phone: {
        regex: /^[0-9()\-+\s]{10,}/,
        errorMsg: 'Please enter a valid phone number.'
    },
    name: {
        regex: /.+/,
        errorMsg: 'Please enter at least one character.'
    },
    match: {
        errorMsg: 'Passwords must match.'
    },
    terms: {
        test: checked => checked,
        errorMsg: 'Required to create an account.'
    },
    optional: {
        regex: /^.?|.+/
    }
};
